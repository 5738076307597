import { type ReactNode, useMemo, useRef, useState, type MouseEvent, useCallback } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  styled
} from "@mui/material";
import dayjs from "dayjs";

import * as ProjectImages from "@shared/branding/imagery/project";
import { type GameDefinitionDto } from "@shared/api-client";

import { useTranslation } from "@app/hooks";
import { ComposedImage } from "@app/components";

import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import CopyIcon from "@mui/icons-material/FileCopy";
import BannerIcon from "@mui/icons-material/VideoLabel";

const MENU_LIST_PROPS = {
  "aria-labelledby": "basic-button"
};

export const ProjectCardWrapper = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "240px",
  cursor: "pointer",
  transform: "scale(1)",
  transition: theme.transitions.create(["box-shadow", "transform", "filter"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shortest
  }),
  h6: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    lineClamp: "2",
    lineHeight: "1.2em"
  },
  ".badge": {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    boxShadow: theme.shadows[2],
    opacity: 0.8
  },
  "&:hover": {
    boxShadow: theme.shadows[4],
    transform: "scale(1.02)",
    filter: "brightness(1.1)",

    "& .icon-overlay": {
      display: "block"
    }
  },
  "&:active": {
    transform: "scale(1.01)",
    transition: "none"
  },
  "& .CardFooter": {
    display: "flex",
    flexDirection: "Row",
    alignItems: "flex-start",
    flex: "0 1 70px"
  },
  "& .CompoundImage, .MuiCardMedia-root": {
    flex: "1 0 0px"
  },
  "& .MuiCardContent-root": {
    flex: "0 1 auto",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2)
  },
  "& .MuiCardActions-root": {
    flex: "1 0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    whiteSpace: "nowrap",
    padding: theme.spacing(2)
  }
}));

const IconOverlay = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "100px",
  left: "50%",
  display: "none",
  color: theme.palette.common.white,
  transform: "scale(1.5) translate(-50%, -50%)",
  filter: "drop-shadow(0 0 1px #000)",
  opacity: 0.4
}));

const StyledChip = styled(Chip)({ ml: 2 });

export interface ProjectCardProps {
  game: GameDefinitionDto;
  selected?: boolean;
  onClick: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onClone?: () => void;
  onOpenBanner?: () => void;
  hoverIcon?: ReactNode;
}

export function ProjectCard({ game, onClick, onEdit, onDelete, onClone, onOpenBanner, hoverIcon }: ProjectCardProps) {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuAnchor = useRef<HTMLButtonElement>(null);

  const randomImage = useMemo(
    () =>
      Object.keys(ProjectImages)[
        Math.floor(Math.random() * Object.keys(ProjectImages).length)
      ] as keyof typeof ProjectImages,
    []
  );

  const handleToggleMenu = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    setMenuOpen(true);
  }, []);

  const handleMenuOption = (cb: () => void) => (event: MouseEvent) => {
    cb();
    setMenuOpen(false);
    event.preventDefault();
  };

  const handleStopPropagation = useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  const handleCloseMenu = useCallback(() => {
    setMenuOpen(false);
  }, []);

  return (
    <ProjectCardWrapper onClick={onClick}>
      <ComposedImage fg={game.fgImage} bg={game.bgImage || ProjectImages[randomImage]} title={game.name} />
      {hoverIcon && <IconOverlay className='icon-overlay'>{hoverIcon}</IconOverlay>}
      {game.isTemplate && (
        <StyledChip className='badge' label={t("projects.isTemplate")} variant='filled' color='primary' size='small' />
      )}
      <div className='CardFooter'>
        <CardContent>
          <Typography variant='h6'>{game.name || t("projects.noTitle")}</Typography>
        </CardContent>
        <CardActions>
          <Typography variant='subtitle2'>{game.category || "-"}</Typography>
          <Typography variant='caption'>{dayjs(game.updatedAt).format(t("common.dateFormat").toString())}</Typography>
          {(onEdit || onDelete || onClone) && (
            <>
              <IconButton
                size='small'
                ref={menuAnchor}
                aria-controls={menuOpen ? "basic-menu" : undefined}
                aria-haspopup='true'
                aria-expanded={menuOpen ? "true" : undefined}
                onClick={handleToggleMenu}
              >
                <MoreHorizOutlinedIcon />
              </IconButton>
              <Menu
                id='basic-menu'
                anchorEl={menuAnchor.current}
                open={menuOpen}
                onClick={handleStopPropagation}
                onClose={handleCloseMenu}
                MenuListProps={MENU_LIST_PROPS}
              >
                {onOpenBanner && (
                  <MenuItem onClick={handleMenuOption(onOpenBanner)}>
                    <ListItemIcon>
                      <BannerIcon />
                    </ListItemIcon>
                    <ListItemText>{t("projects.openBanner")}</ListItemText>
                  </MenuItem>
                )}
                {onEdit && (
                  <MenuItem onClick={handleMenuOption(onEdit)}>
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText>{t("projects.edit")}</ListItemText>
                  </MenuItem>
                )}
                {onClone && (
                  <MenuItem onClick={handleMenuOption(onClone)}>
                    <ListItemIcon>
                      <CopyIcon />
                    </ListItemIcon>
                    <ListItemText>{t("projects.clone")}</ListItemText>
                  </MenuItem>
                )}
                {onDelete && (
                  <MenuItem onClick={handleMenuOption(onDelete)}>
                    <ListItemIcon>
                      <DeleteForeverIcon color='error' />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography color='error'>{t("projects.delete")}</Typography>
                    </ListItemText>
                  </MenuItem>
                )}
              </Menu>
            </>
          )}
        </CardActions>
      </div>
    </ProjectCardWrapper>
  );
}
