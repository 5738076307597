import { useEffect, useState, useMemo, useCallback } from "react";
import { withJsonFormsControlProps } from "@jsonforms/react";
import {
  ControlProps,
  RankedTester,
  Resolve,
  isEnumControl,
  isOneOfControl,
  rankWith,
} from "@jsonforms/core";
import {
  Box,
  ImageList,
  ImageListItem,
  useTheme,
  Typography,
} from "@mui/material";
import { FieldWrapper } from "../common/FieldWrapper";
import { isCustomControl } from "../utils";

interface Option {
  title: string;
  const: string | undefined;
}

export function ImageSelectorRenderer({
  label,
  description,
  data,
  visible,
  schema,
  path,
  handleChange,
  uischema,
  required,
  config,
  enabled,
}: ControlProps) {
  const theme = useTheme();

  const [currentValue, setCurrentValue] = useState<string>();
  const [options, setOptions] = useState<Option[]>([]);

  const handleSelection = useCallback(
    (image: string) => {
      if (!enabled) return;
      setCurrentValue(image);
      handleChange(path, image);
    },
    [handleChange, path, enabled]
  );

  useEffect(() => {
    setCurrentValue(data);
  }, [data]);

  useEffect(() => {
    // Figure out the relative path in case of children of Array
    // data.2.children.0.enumSource -> data.2.children.0
    // data.2.child -> data.2
    // data.2.child.property -> data.2
    const lastNumericIndex = path
      .split(/\./g)
      .reverse()
      .findIndex((part) => part.match(/\d/));
    const dataRelativePath =
      lastNumericIndex > -1
        ? path.split(/\./g).slice(0, -lastNumericIndex).join(".")
        : "";

    //  Calculate the final path
    const dataSourcePath =
      uischema.options?.enumSource &&
      (dataRelativePath
        ? `${dataRelativePath}.${uischema.options.enumSource}`
        : uischema.options.enumSource);

    // The data source: either the oneOf or enum field of the schema or the enumSource field of the uischema
    const sourceData = dataSourcePath
      ? Resolve.data(config.context.data || {}, dataSourcePath)
      : schema.oneOf || schema.enum;

    if (!sourceData || !Array.isArray(sourceData)) {
      setOptions([]);
      return;
    }

    // Modifier to use the index as the value of the option
    const useIndexAsValue = uischema.options?.enumUseIndexAsValue || false;

    const options: Option[] = sourceData
      .map((option: unknown, index: number) => {
        if (typeof option === "string" || !option) {
          return {
            title: option || 1 + index,
            const: useIndexAsValue ? index : option,
          };
        }

        if (typeof option === "object" && !!option) {
          const sourceTitleField = (uischema.options?.enumTitleField ||
            "title") as keyof typeof option;
          const sourceValueField = (uischema.options?.enumValueField ||
            "const") as keyof typeof option;

          return {
            title:
              option[sourceTitleField] || option[sourceValueField] || 1 + index,
            const:
              option[sourceValueField] || (useIndexAsValue ? index : option),
          };
        }
      })
      .filter((option): option is Option => !!option);

    if (!required) {
      options.unshift({
        title: "Ninguno",
        const: undefined,
      });
    }

    setOptions(options);
  }, [schema, uischema, config.context.data, path, required]);

  const imageListBoxStyles = useMemo(
    () => ({
      display: "flex",
      justifyContent: "center",
      gap: 16,
      m: 0,
      pb: 1,
      overflowX: "auto",
    }),
    []
  );

  const imageListStyles = useMemo(
    () => ({
      display: "flex",
      flexWrap: "wrap",
      gap: 8,
      width: "100%",
      m: 0,
      pb: 1,
    }),
    []
  );

  const imageItemStyles = useMemo(
    () => ({
      width: "54px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      position: "relative",
      borderRadius: 1,
      overflow: "hidden",
      transition: "border 0.2s ease",
      "&:hover": {
        border: enabled ? `2px solid ${theme.palette.action.hover}` : "",
      },
    }),
    [theme, enabled]
  );

  const None = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50px"
      height="50px"
      viewBox="0 0 24 24"
      fill="none"
      stroke="gray"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0" />
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
          fill="gray"
        />{" "}
      </g>
    </svg>
  );

  if (!visible) return null;

  return (
    <FieldWrapper label={label} description={description}>
      {options.length === 0 ? (
        <Typography variant="body2" color="text.secondary">
          No hay opciones disponibles.
        </Typography>
      ) : (
        <Box sx={imageListBoxStyles}>
          <ImageList gap={16} sx={imageListStyles}>
            {options.map((asset) => (
              <ImageListItem
                key={asset.const}
                onClick={() => handleSelection(asset.const || "")}
                sx={{
                  ...imageItemStyles,
                  border:
                    enabled && currentValue === asset.const
                      ? `2px solid ${theme.palette.primary.main}`
                      : "2px solid transparent",
                }}
              >
                {asset.const ? (
                  <img
                    src={asset.const}
                    alt={asset.title || "Selectable image"}
                    style={{ width: "100%", height: 50, objectFit: "cover" }}
                  />
                ) : (
                  <None />
                )}
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      )}
    </FieldWrapper>
  );
}

const tester: RankedTester = rankWith(20, (uiSchema, schema, context) => {
  return (
    isEnumControl(uiSchema, schema, context) ||
    isOneOfControl(uiSchema, schema, context)
  ) &&
  isCustomControl(uiSchema, "ImageSelector")
  ? true
  : false;
});

export default {
  tester,
  renderer: withJsonFormsControlProps(ImageSelectorRenderer),
};
