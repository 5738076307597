import React, { type CSSProperties, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  TransformWrapper,
  TransformComponent,
  type ReactZoomPanPinchContentRef,
  type ReactZoomPanPinchRef
} from "react-zoom-pan-pinch";
import { useDropzone } from "react-dropzone";
import {
  Box,
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  alpha,
  styled
} from "@mui/material";
import { useDrop } from "react-dnd";
import { v4 as uuidv4 } from "uuid";
import domtoimage from "dom-to-image-more";

import { ComponentContext, GameCanvas } from "@shared/game-player";
import { type RenderingContext } from "@shared/game-player/types/canvas";
import {
  DEFAULT_SCREEN,
  DEFAULT_THEME,
  findElementById,
  GameElementCategory,
  type GameElementStageProps,
  type GameElement,
  replaceValue,
  ASSET_PROTOCOL,
  getDefaultElement
} from "@shared/game-engine";
import { GamePluginType, type PluginManifest } from "@shared/utils/plugins";
import { getAjv } from "@shared/form-builder/ajv";

import { useAppContext, useClipboard, useShortcuts, useTranslation } from "@app/hooks";
import { type EditorDropItem } from "@app/types";

import {
  ToolbarButton,
  EditorToolbar,
  ToolbarSpacer,
  HandlersContainer,
  Guidelines,
  useEditor,
  StageMDEditor,
  ComponentToolbarButtons,
  type EditorState,
  cleanupElement,
  type GameElementToAdd
} from "@app/editor";
import { useDevice } from "@app/hooks/useDevice";
import { getElementSchema } from "@app/schemas/element.schema";

import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import SaveIcon from "@mui/icons-material/Save";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import SettingsIcon from "@mui/icons-material/Settings";
import AnimationIcon from "@mui/icons-material/Animation";
import ActionsIcon from "@mui/icons-material/AdsClick";
import AddToLibraryIcon from "@mui/icons-material/AddToPhotos";

import { OverlayToolbarButtons } from "./EditorToolbar/OverlayToolbarButtons";
import { CommonToolbarButtons } from "./EditorToolbar/CommonToolbarButtons";
import { EditorButtonGroup } from "./EditorToolbar/EditorButtonGroup";
import { LevelToolbarButtons } from "./EditorToolbar/LevelToolbarButtons";
import { AddToLibraryModal } from "@app/library/AddToLibraryModal";
import { FeatureFlag } from "@app/components/FeatureFlag";

// Static props
const STYLE_CANVAS: CSSProperties = {
  pointerEvents: "none" // disable interactions
};
const STYLE_EDITOR_CONTENT: CSSProperties = {
  width: "100%",
  height: "100%"
};
const STYLE_CONTEXT_MENU: CSSProperties = {
  zoom: 0.85
};
const SX_TOOLBAR_BUTTON = { width: "60px" };
const TRANSFORM_WRAPPER_DISABLED = { disabled: true };
const HANDLERS_PAN = { x: 2000, y: 2000 };

const EditorViewBox = styled(Box)(({ theme }) => ({
  flex: "1 1 300px",
  position: "relative",
  overflow: "hidden",
  pointerEvents: "all",

  // Make sure the editor doesn't overflow the viewport
  width: "300px",
  height: "200px",
  minWidth: "100%",
  minHeight: "100%",

  // Focus outline
  "&.shortcut-focused::after": {
    content: `""`,
    position: "absolute",
    zindex: 3,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    outline: `2px solid ${alpha(theme.palette.secondary.main, 0.5)}`,
    outlineOffset: "-2px",
    pointerEvents: "none"
  }
}));

const Canvas = styled("div")({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
});

const StageArea = styled("div")({
  width: "100%",
  height: "100%",
  position: "relative"
});

interface Props {
  // advancedMode?: boolean;
  stagesOpen?: boolean;
  advancedDrawerOpen?: boolean;
  onToggleStages?: (open: boolean) => void;
  onToggleAdvancedDrawer?: (open: boolean) => void;
}

type DroppedElement = {
  item: EditorDropItem;
  x: number;
  y: number;
};

export function EditorView(props: Props) {
  const { notify, featureFlags } = useAppContext();
  const { stagesOpen, advancedDrawerOpen, onToggleStages, onToggleAdvancedDrawer } = props;

  const canvasEl = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const transformWrapperRef = useRef<ReactZoomPanPinchContentRef>(null);
  const viewportRef = useRef<HTMLDivElement>();
  const [viewportRect, setViewportRect] = useState<DOMRectReadOnly>();

  const {
    plugins: { getPluginManifest, getCachedPluginManifest, getCachedPluginComponent }
  } = useContext(ComponentContext);

  const {
    gameData,
    gameDataInfo,
    renderedLevelData,
    renderedOverlayData,
    setTool,
    select,
    unselectAll,
    getCurrentLayout,
    getSelectedElement,
    getSelectedElements,
    updateEditorState,
    level,
    overlay,
    currentLayer,
    isLoading,
    editor,
    canAutoSave,
    saveAll,
    uploadAssets,
    reloadAssets
  } = useEditor();

  const [copyToClipboard, getClipboard, hasData] = useClipboard({
    onImage: async (data, mimetype, name = `image-${Date.now()}.png`) => {
      // Create an asset from the image
      const asset = await uploadAssets([new File([data], name, { type: mimetype })]);
      reloadAssets();

      // Find any or null
      return asset.find(Boolean) || null;
    }
  });

  // Tools
  const [togglePan, setTogglePan] = useState(false);
  const [toggleScale, setToggleScale] = useState(false);
  const [fitScreen, setFitScreen] = useState(true);
  const [showIndexes, setShowIndexes] = useState(false);

  // Modal/Drawer states
  const [forceOpenProperties, setForceOpenProperties] = useState<string>();

  // Temporary state to add new elements. Kinda hacky but needed because `useDrop` doesn't
  // refresh the `addElement` function reference when the levelData changes
  // Might be useful for displaying a dialog though
  const [droppedElement, setDroppedElement] = useState<DroppedElement>();

  // Editor updates queue. For debouncing
  const [pendingEditorUpdates, setPendingEditorUpdates] = useState<Partial<EditorState> | null>(null);

  // Context menu
  const [contextMenu, setContextMenu] = useState<{ top: number; left: number }>();

  // Long touch for context menu
  useDevice({
    longTouchTimeout: 1000,
    onLongTouch: (touches, ev) => {
      if (touches === 1) {
        setContextMenu({ top: ev.touches[0].clientY, left: ev.touches[0].clientX });
      }
    }
  });

  // Library
  const [addToLibrary, setAddToLibrary] = useState<GameElement>();

  // Editing text
  const [textEditorSelection, setTextEditorSelection] = useState<{ uuid: string; field: string }>();
  const [textEditorValue, setTextEditorValue] = useState<string>();

  const textEditorElement = textEditorSelection
    ? findElementById(getCurrentLayout(), textEditorSelection.uuid)
    : undefined;

  const isEditingText = textEditorSelection !== undefined;
  const isEditorDirty = level?.isDirty || overlay?.isDirty;
  const isLevelLoaded = level?.data && level?.data !== undefined; // Double check for making TS happy

  // Useful vars
  const selectMode = !textEditorSelection && editor?.tool === "select" && !togglePan && !toggleScale;
  const scaleMode = !textEditorSelection && (editor?.tool === "scale" || (toggleScale && !togglePan));
  const panMode = editor?.tool === "pan" || togglePan;
  const hasSelection = editor.selection.length > 0;
  const isSingleSelection = editor.selection.length === 1;
  const selectedElement = getSelectedElement();
  const selectedComponentManifest = selectedElement && getCachedPluginManifest(selectedElement.component);
  const selectedComponent = selectedElement && getCachedPluginComponent(selectedElement.component);
  const selectionHasProperties = selectedComponentManifest?.uiSchema !== false;

  /**
   * Add a new element to the layer
   */
  const addElements = useCallback(
    (elementDatas: GameElementToAdd[]): GameElement[] | undefined => {
      if (!currentLayer?.data) {
        return;
      }

      const elements: GameElement[] = [];

      elementDatas.forEach((currentData) => {
        const data: GameElement = {
          ...getDefaultElement(),
          ...currentData,
          id: uuidv4()
        };

        // get cached manifest (hopefully it's cached)
        const manifest = getCachedPluginManifest(data.component);

        // Validate schema
        if (manifest) {
          try {
            const validate = getAjv().compile(getElementSchema(manifest, data, gameDataInfo, t));
            const valid = validate(data);

            if (!valid) {
              // Warn about issues, but let it pass
              console.warn("Errors found in the added element", validate.errors);
            }
          } catch (e) {
            console.error(e);
            notify(t("editor.invalidElementData"), "error");
            return;
          }
        }

        elements.push(data);
      });

      currentLayer.update({ ...currentLayer.data, layout: [...(currentLayer.data.layout || []), ...elements] });

      return elements;
    },
    [currentLayer, gameDataInfo, getCachedPluginManifest, notify, t]
  );

  /**
   * Fit the screen to the viewport.
   * TODO: Move to a hook?
   */
  const doFitScreen = useCallback(
    (persist = false) => {
      if (!viewportRef.current || !gameData) {
        return;
      }

      const spacing = 80;
      const viewportBounds = viewportRef.current.getBoundingClientRect();

      const zoom = Math.min(
        (viewportBounds.height - spacing) / (gameData.screen || DEFAULT_SCREEN).height,
        (viewportBounds.width - spacing) / (gameData.screen || DEFAULT_SCREEN).width
      );

      transformWrapperRef.current?.centerView(zoom, 0);

      if (persist === true) {
        setFitScreen(true);
      }
    },
    [gameData]
  );

  /**
   * Get the selected elements
   */
  const moveSelection = useCallback(
    (dx: number, dy: number) => {
      if (!currentLayer?.data || !editor.selection.length) {
        return;
      }

      if (isEditingText) {
        // Do not move elements if we are editing text
        return;
      }

      getSelectedElements().forEach((element) => {
        const stage = {
          ...element.stage,
          x: element.stage.x + dx / editor.zoom,
          y: element.stage.y + dy / editor.zoom
        };

        currentLayer?.updateElement(element.id, { stage });
      });
    },
    [currentLayer, editor.selection.length, editor.zoom, isEditingText, getSelectedElements]
  );

  /**
   * Order the selected elements
   */
  const orderSelection = useCallback(
    (direction: number) => {
      currentLayer?.orderElements(editor.selection, direction);
    },
    [currentLayer, editor.selection]
  );

  /**
   * Select all elements
   */
  const selectAll = useCallback(() => {
    select(currentLayer?.data?.layout.map((element) => element.id) || []);
    setTextEditorSelection(undefined);
    setTextEditorValue(undefined);
  }, [currentLayer?.data?.layout, select]);

  /**
   * Remove the selected elements
   */
  const removeSelection = useCallback(() => {
    currentLayer?.removeElements(editor.selection);
  }, [currentLayer, editor.selection]);

  /**
   * Consolidate the text editor value
   */
  const consolidateTextEditor = useCallback(
    (value?: string) => {
      if (!textEditorSelection) {
        return;
      }

      // onChange has been called, update the element
      if (value !== undefined) {
        currentLayer?.updateElement(textEditorSelection.uuid, {
          translations: { [editor.language]: { [textEditorSelection.field]: value } }
        });
      }

      // Remove selection
      setTextEditorSelection(undefined);
      setTextEditorValue(undefined);
    },
    [currentLayer, editor.language, textEditorSelection]
  );

  /**
   * Cancel the text editor
   */
  const cancelTextEditor = useCallback(() => {
    setTextEditorSelection(undefined);
    setTextEditorValue(undefined);
  }, []);

  /**
   * Copy the selected elements
   */
  const copySelection = useCallback(
    async (cut?: boolean) => {
      await copyToClipboard(getSelectedElements(), { cleanElements: !cut, sourceLevel: level }, { pasteOnce: cut });
      notify(t("editor.elementsCopied"), "info");
    },
    [copyToClipboard, getSelectedElements, level, notify, t]
  );

  /**
   * Paste the copied elements
   */
  const pasteSelection = useCallback(async () => {
    const clipboard = await getClipboard();

    if (!clipboard) {
      return;
    }

    const { content, meta /* pasteOnce, pasteCount */ } = clipboard;
    const offset = 0; // pasteOnce ? 0 : pasteCount * 10; (Disliked this feature)

    if (Array.isArray(content)) {
      const addedElements = addElements(
        content.map((element) => {
          const el: GameElement = {
            ...element,
            stage: { ...element.stage, x: element.stage.x + offset, y: element.stage.y + offset }
          };

          return meta?.cleanElements ? cleanupElement(el) : el;
        })
      );

      if (!addedElements) {
        return;
      }

      // Automatically select the pasted elements
      select(addedElements.map((element) => element.id));
      consolidateTextEditor(textEditorValue);
    }
  }, [addElements, consolidateTextEditor, getClipboard, select, textEditorValue]);

  /**
   * Cut the selected elements
   */
  const cutSelection = useCallback(() => {
    copySelection(true);
    removeSelection();
  }, [copySelection, removeSelection]);

  /**
   * Handle stage click
   */
  const handleStageClick = useCallback(() => {
    unselectAll();
    consolidateTextEditor(textEditorValue);
    setTextEditorSelection(undefined);
  }, [consolidateTextEditor, textEditorValue, unselectAll]);

  const handleHandlerUpdate = useCallback(
    (id: string, stage: GameElementStageProps) => {
      currentLayer?.updateElement(id, { stage });
    },
    [currentLayer]
  );

  const handleUpdateSelectedElement = useCallback(
    (updates: Partial<GameElement>) => {
      if (!selectedElement) {
        return;
      }

      currentLayer?.updateElement(selectedElement.id, updates);
    },
    [currentLayer, selectedElement]
  );

  const handleClosePropertiesModal = useCallback(() => {
    setForceOpenProperties(undefined);
  }, []);

  const handleToggleAdvancedDrawer = useCallback(() => {
    onToggleAdvancedDrawer?.(!advancedDrawerOpen);
  }, [advancedDrawerOpen, onToggleAdvancedDrawer]);

  // Context menu
  const handleContextMenu = useCallback(
    (ev: React.MouseEvent) => {
      ev.preventDefault();

      if (ev.target instanceof HTMLElement) {
        // Trigger the click on the target
        ev.target.click();
      }

      setContextMenu({ left: ev.clientX, top: ev.clientY });
    },
    [setContextMenu]
  );

  const handleCloseContextMenu = useCallback(
    (event: React.MouseEvent) => {
      if (contextMenu) {
        event.preventDefault();
        setContextMenu(undefined);
      }
    },
    [contextMenu]
  );

  // Drop files into the stage
  const { getRootProps: getFileDragProps, getInputProps: getFileDragInputProps } = useDropzone({
    onDrop: (files) => {
      const file = files[0];

      if (!file) {
        return;
      }

      uploadAssets([file]).then(async ([asset]) => {
        reloadAssets();
        notify(t("common.uploadSuccess"), "success");

        // Create the element into the stage
        // Try to center the element in the viewport
        const dropItemPosition = {
          x: -editor.pan.x + (viewportRef.current?.clientWidth || 0) / 2,
          y: -editor.pan.y + (viewportRef.current?.clientHeight || 0) / 2
        };

        const uuid = `<${asset.type}>`; // built-in expected component
        const manifest = await getPluginManifest(uuid);

        if (!manifest) {
          // not ready??
          throw new Error("Manifest not found");
        }

        const template = manifest.features.useAssetTemplates?.[asset.type];

        const dropItem: EditorDropItem = {
          ...template,
          component: uuid,
          properties: template ? replaceValue(template.properties || {}, ASSET_PROTOCOL, asset.path) : {}
        };

        setDroppedElement(() => ({
          item: dropItem,
          ...dropItemPosition
        }));
      });
    },
    noClick: true,
    noKeyboard: true,
    multiple: false
  });

  // Component drop handler
  const [, drop] = useDrop<EditorDropItem>(() => ({
    accept: GamePluginType.GAME_COMPONENT,
    drop: (item, monitor) => {
      const offset = monitor.getClientOffset();
      const bounds = canvasEl.current?.getBoundingClientRect();

      if (!offset || !bounds) {
        return;
      }

      setDroppedElement(() => ({
        item,
        x: offset.x - bounds.x,
        y: offset.y - bounds.y
      }));
    }
  }));

  // Keyboard shortcuts
  useShortcuts(
    {
      space: [
        (e) => {
          if (textEditorSelection) {
            return;
          }

          e.preventDefault();
          setTogglePan(true);
        },
        () => {
          if (textEditorSelection) {
            return;
          }

          setTogglePan(false);
        }
      ],
      escape: () => {
        if (textEditorSelection) {
          return;
        }

        unselectAll();
        setTextEditorSelection(undefined);
        setTextEditorValue(undefined);
      },
      "mouse-middle": [() => setTogglePan(true), () => setTogglePan(false)],
      "three-finger": [() => setTogglePan(true), () => setTogglePan(false)],
      ctrl: [
        () => {
          !textEditorSelection && setShowIndexes(true);
        },
        () => {
          setShowIndexes(false);
        }
      ],
      alt: [
        () => {
          !textEditorSelection && setToggleScale(true);
        },
        () => {
          setToggleScale(false);
        }
      ],
      backspace: () => {
        !textEditorSelection && currentLayer?.removeElements(editor.selection);
      },
      delete: () => {
        !textEditorSelection && currentLayer?.removeElements(editor.selection);
      },
      arrowleft: () => {
        moveSelection(-1, 0);
      },
      arrowright: () => {
        moveSelection(1, 0);
      },
      arrowup: () => {
        moveSelection(0, -1);
      },
      arrowdown: () => {
        moveSelection(0, 1);
      },
      "shift+arrowleft": () => {
        moveSelection(-10, 0);
      },
      "shift+arrowright": () => {
        moveSelection(10, 0);
      },
      "shift+arrowup": () => {
        moveSelection(0, -10);
      },
      "shift+arrowdown": () => {
        moveSelection(0, 10);
      },
      "ctrl+arrowup": (e) => {
        e.preventDefault();
        orderSelection(1);
      },
      "ctrl+arrowdown": () => orderSelection(-1),
      "ctrl+a": (e) => {
        if (textEditorSelection) {
          return;
        }

        e.preventDefault();
        selectAll();
      },
      "ctrl+s": (e) => {
        e.preventDefault();
        saveAll();
      },
      "ctrl+z": (e) => {
        if (textEditorSelection) {
          return;
        }

        e.preventDefault();
        currentLayer?.history.undo();
      },
      "ctrl+y": (e) => {
        if (textEditorSelection) {
          return;
        }

        e.preventDefault();
        currentLayer?.history.redo();
      },
      "ctrl+c": () => !textEditorSelection && copySelection(),
      "ctrl+x": () => !textEditorSelection && cutSelection(),
      "ctrl+v": () => !textEditorSelection && pasteSelection(),
      v: () => {
        !textEditorSelection && setTool("select");
      },
      s: () => {
        !textEditorSelection && setTool("scale");
      },
      p: () => {
        !textEditorSelection && setTool("pan");
      },
      f: () => {
        !textEditorSelection && doFitScreen(true);
      }
    },
    // Use the whole document
    document.body
  );

  /*
   * Effect handlers
   */

  // Handle adding the element
  useEffect(() => {
    if (droppedElement) {
      const { item, x, y } = droppedElement;

      if (!item.component) {
        return;
      }

      // Get the manifest so we can extract needed data
      getPluginManifest(item.component).then((manifest) => {
        if (!manifest || !manifest.id) {
          // what?
          return;
        }

        // Get the sent template or pick the first example
        const template = item || manifest.templates?.[0];

        if (!template) {
          // what?
          return;
        }

        // Try the template, then the asset width/height, then the defaults
        const width = manifest.features.autoWidth ? null : template.stage?.width || null;
        const height = manifest.features.autoHeight ? null : template.stage?.height || null;

        const elementData: GameElementToAdd = {
          type: manifest.category,
          component: manifest.id,

          ...template,

          stage: {
            ...getDefaultElement().stage,
            x: x / editor.zoom - (width || 0) / 2,
            y: y / editor.zoom - (height || 0) / 2,
            width,
            height
          }
        };

        // Clear the dropped element
        setDroppedElement(undefined);

        // Enforce calculated dimensions
        elementData.stage.width = width;
        elementData.stage.height = height;

        // Do the thing
        const newElements = addElements([elementData]);
        newElements?.[0] && select(newElements[0].id);
        setTextEditorSelection(undefined);
      });
    }
  }, [addElements, droppedElement, editor.zoom, getPluginManifest, notify, select, t]);

  // Handle text editor highight
  useEffect(() => {
    if (textEditorSelection) {
      const element = document.querySelector<HTMLElement>(`#el-${textEditorSelection.uuid} [data-text-editable]`);

      // Set content editable
      element?.classList.add("editor-text-editable"); // check index.css

      return () => {
        element?.classList.remove("editor-text-editable");
      };
    }
  }, [textEditorSelection, editor.language]);

  // Handle remove text editor selection on level change
  useEffect(() => {
    setTextEditorSelection(undefined);
    setTextEditorValue(undefined);
  }, [editor?.level]);

  // Handle updating the editor state
  useEffect(() => {
    if (pendingEditorUpdates) {
      updateEditorState(pendingEditorUpdates);
      setPendingEditorUpdates(null);
    }
  }, [pendingEditorUpdates, updateEditorState]);

  // Regenerate the thumbnail if view changes
  useEffect(() => {
    const refreshRequired = !level?.data?._thumbnail || level?.data?._thumbnailOutdated;

    // No data, or already has a thumbnail
    if (!isLevelLoaded || !canvasEl.current || !refreshRequired) {
      return;
    }

    let cancelled = false;
    const timeout = setTimeout(() => {
      // Optimum scale. 80px height
      const scale = 80 / (gameData?.screen?.height || DEFAULT_SCREEN.height);

      canvasEl.current &&
        domtoimage
          .toJpeg(canvasEl.current, {
            bgcolor: gameData?.theme?.background || DEFAULT_THEME.background,
            quality: 0.4,
            width: (gameData?.screen?.width || DEFAULT_SCREEN.width) * scale,
            height: (gameData?.screen?.height || DEFAULT_SCREEN.height) * scale,
            style: {
              background: "none",
              outline: "none",
              transform: `scale(${scale})`,
              transformOrigin: "top left"
            },
            imagePlaceholder:
              "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAHJJREFUKFOdkCEOwCAMRT8GBQ7DBbj/QTgBCgUGBwoD6RayLploVvXz+/qbVsUYl3MOWmt81ZwTrTWonPMiEUKAtfbF9t6RUgIFqVLKMsZcBocPRN4Y4wa99+ANiuWDtdYHpOaBSfP0f6BotegY8XukD9/UXoQ13hkK+gAAAABJRU5ErkJggg=="
          })
          .then((thumbnail: string) => {
            if (cancelled || !thumbnail) {
              return;
            }

            level?.update(
              {
                _thumbnail: thumbnail,
                _thumbnailOutdated: false
              },
              {
                // Do not register the update in the history
                silent: true,
                // Do not trigger a thumbnail update again
                refreshThumbnail: false
              }
            );
          })
          .catch((err: unknown) => {
            console.error("Error generating thumbnail", err);

            // Mark the thumbnail as done, so it doesn't try again
            level?.update(
              {
                _thumbnailOutdated: false
              },
              {
                silent: true,
                refreshThumbnail: false
              }
            );
          });
    }, 500);

    return () => {
      clearTimeout(timeout);
      cancelled = true;
    };
  }, [gameData?.screen?.width, gameData?.screen?.height, gameData?.theme?.background, isLevelLoaded, level]);

  // Listen for resize events to fit screen. ResizeObserver is not working, since
  // it's being called continuously
  useEffect(() => {
    if (!fitScreen || !transformWrapperRef.current) {
      return;
    }

    const resizeListener = () => {
      // Delay to wait for the resize to finish
      setViewportRect(viewportRef.current?.getBoundingClientRect());
      setTimeout(doFitScreen, 250);
    };

    window.addEventListener("resize", resizeListener);
    window.addEventListener("orientationchange", resizeListener);
    resizeListener();

    return () => {
      window.removeEventListener("resize", resizeListener);
      window.removeEventListener("orientationchange", resizeListener);
    };
  }, [doFitScreen, fitScreen, gameData?.screen?.height, gameData?.screen?.width, stagesOpen]);

  const overlayTheme = useMemo(
    () => ({ ...(gameData?.theme || DEFAULT_THEME), background: "transparent" }),
    [gameData?.theme]
  );

  const renderingContext = useMemo<RenderingContext>(
    () => ({
      viewportWidth: viewportRect?.width || 0,
      viewportHeight: viewportRect?.height || 0,
      canvasX: editor.pan.x,
      canvasY: editor.pan.y,
      canvasWidth: gameData?.screen?.width || DEFAULT_SCREEN.width,
      canvasHeight: gameData?.screen?.height || DEFAULT_SCREEN.height,
      canvasScale: editor.zoom
    }),
    [
      editor.pan.x,
      editor.pan.y,
      editor.zoom,
      gameData?.screen?.height,
      gameData?.screen?.width,
      viewportRect?.height,
      viewportRect?.width
    ]
  );

  const MemoizedGameLevelCanvas = useMemo(() => {
    return gameData && renderedLevelData ? (
      <GameCanvas
        editorMode={true}
        ref={canvasEl}
        screen={gameData.screen || DEFAULT_SCREEN}
        theme={gameData.theme || DEFAULT_THEME}
        levelId={level?.id}
        levelData={renderedLevelData}
        style={STYLE_CANVAS}
        show
        noTransitions
        renderingContext={renderingContext}
      />
    ) : null;
  }, [gameData, level?.id, renderedLevelData, renderingContext]);

  const MemoizedGameOverlayCanvas = useMemo(() => {
    return gameData && renderedOverlayData ? (
      <GameCanvas
        editorMode={true}
        screen={gameData.screen || DEFAULT_SCREEN}
        theme={overlayTheme}
        overlayId={overlay?.id}
        overlayData={renderedOverlayData}
        style={STYLE_CANVAS}
        show
        noTransitions
        renderingContext={renderingContext}
      />
    ) : null;
  }, [gameData, overlay?.id, overlayTheme, renderedOverlayData, renderingContext]);

  const handlersSx = useMemo(
    () => ({
      position: "absolute",
      width: "100%",
      height: "100%",
      top: -2000 / editor.zoom,
      left: -2000 / editor.zoom,
      transformOrigin: "top left",
      transform: `scale(${1 / editor.zoom})` // nullify the zoom
    }),
    [editor.zoom]
  );

  const transformPanning = useMemo(() => ({ disabled: !panMode, velocityDisabled: true }), [panMode]);
  const transformWrapperStyle = useMemo(
    () => ({
      width: "100%",
      height: "100%",
      background: editor.mode === "overlay" ? "rgba(100, 0, 0, 0.2)" : "transparent",
      transition: "background 0.2s"
    }),
    [editor.mode]
  );
  const levelCanvasSx = useMemo(
    () => ({
      cursor: panMode ? "grab" : "inherit",
      backgroundColor: gameData?.theme?.background || DEFAULT_THEME.background,
      opacity: editor.mode === "level" ? 1 : 0.25,
      filter: editor.mode === "level" ? "none" : "sepia(90%)",
      transition: "opacity 0.2s, filter 0.2s"
    }),
    [editor.mode, gameData?.theme?.background, panMode]
  );
  const overlayCanvasSx = useMemo(
    () => ({
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      pointerEvents: "none",
      opacity: editor.mode === "overlay" ? 1 : 0.25,
      filter: editor.mode === "overlay" ? "none" : "sepia(90%) hue-rotate(-40deg)",
      transition: "opacity 0.2s, filter 0.2s"
    }),
    [editor.mode]
  );

  const handleTransformUpdate = useCallback(
    (
      _ev: ReactZoomPanPinchRef,
      { scale, positionX, positionY }: { scale: number; positionX: number; positionY: number }
    ) => {
      // shortcut! if no changes in scale and zoom, don't update
      // For some reason, the TransformWrapper is calling this in every render
      if (scale === editor.zoom && positionX === editor.pan.x && positionY === editor.pan.y) {
        return;
      }

      setPendingEditorUpdates({
        ...pendingEditorUpdates,
        zoom: scale,
        pan: { x: positionX, y: positionY }
      });
    },
    [editor.pan.x, editor.pan.y, editor.zoom, pendingEditorUpdates]
  );

  const handleSetSelection = useCallback(
    (newSelection: string | string[]) => {
      select(newSelection);
      setTextEditorSelection(undefined);
    },
    [select]
  );

  const handleDoubleClick = useCallback(
    (id: string, manifest: PluginManifest | null) => {
      // Select the element and expand the properties
      select(id);

      if (manifest?.features?.allowTextEdit) {
        setTextEditorSelection({ uuid: id, field: manifest.features.allowTextEdit });
      } else if (
        // this condition is a bit manual, but it's fine for now
        manifest?.uiSchema !== false ||
        manifest?.category === GameElementCategory.LOCK ||
        manifest?.category === GameElementCategory.ITEM
      ) {
        setForceOpenProperties("properties");
      } else if (Object.keys(manifest?.actions || {}).length > 0) {
        setForceOpenProperties("actions");
      } else {
        setForceOpenProperties("transitions");
      }
    },
    [select]
  );

  const handleDoubleClickOnStageArea = useCallback(() => {
    if (!selectedElement && editor.tool !== "pan") {
      setForceOpenProperties("actions");
    }
  }, [editor.tool, selectedElement]);

  const handleEditorMouseDown = useCallback((_event: React.MouseEvent<HTMLDivElement>) => {
    // Force blur to trigger the blur behavior in the AdvancedEditor
    document.querySelector<HTMLInputElement | HTMLTextAreaElement>("input:focus,textarea:focus")?.blur();
    // document.body.blur();
    // event.currentTarget.focus();
  }, []);

  const handleDisableFitScreen = useCallback(() => setFitScreen(false), []);

  const handleToggleStages = useCallback(() => onToggleStages?.(!stagesOpen), [onToggleStages, stagesOpen]);

  const handleZoomOut = useCallback(() => {
    transformWrapperRef.current?.zoomOut(0.2, 0);
    setFitScreen(false);
  }, []);

  const handleCenterView = useCallback(() => {
    transformWrapperRef.current?.centerView(1, 0);
    setFitScreen(false);
  }, []);

  const handleZoomIn = useCallback(() => {
    transformWrapperRef.current?.zoomIn(0.2, 0);
    setFitScreen(false);
  }, []);

  const handleFitScreen = useCallback(() => setFitScreen(true), []);
  const handleCopySelection = useCallback(() => copySelection(), [copySelection]);
  const handleOpenPropertiesModal = useCallback(() => setForceOpenProperties("properties"), []);
  const handleOpenAnimationsModal = useCallback(() => setForceOpenProperties("transitions"), []);
  const handleOpenActionsModal = useCallback(() => setForceOpenProperties("actions"), []);
  const handleMoveBack = useCallback(() => orderSelection(-1), [orderSelection]);
  const handleMoveForward = useCallback(() => orderSelection(1), [orderSelection]);
  const handleSendtoBack = useCallback(() => orderSelection(Number.MIN_SAFE_INTEGER / 2), [orderSelection]);
  const handleBringToFront = useCallback(() => orderSelection(Number.MAX_SAFE_INTEGER / 2), [orderSelection]);

  const handleAddToLibrary = useCallback(() => {
    if (!selectedElement) {
      return;
    }

    setAddToLibrary(selectedElement);
  }, [selectedElement]);

  return (
    <EditorViewBox ref={viewportRef} onMouseDown={handleEditorMouseDown}>
      <TransformWrapper
        panning={transformPanning}
        wheel={TRANSFORM_WRAPPER_DISABLED}
        ref={transformWrapperRef}
        initialScale={editor.zoom}
        initialPositionX={editor.pan.x}
        initialPositionY={editor.pan.y}
        minScale={0.1}
        maxScale={4}
        limitToBounds={false}
        smooth={false}
        centerOnInit
        centerZoomedOut={true}
        doubleClick={TRANSFORM_WRAPPER_DISABLED}
        zoomAnimation={TRANSFORM_WRAPPER_DISABLED}
        alignmentAnimation={TRANSFORM_WRAPPER_DISABLED}
        velocityAnimation={TRANSFORM_WRAPPER_DISABLED}
        onPanning={handleDisableFitScreen}
        onZoom={handleDisableFitScreen}
        onPinching={handleDisableFitScreen}
        onTransformed={handleTransformUpdate}
      >
        <EditorToolbar position='top'>
          <EditorButtonGroup>
            <ToolbarButton
              icon={
                isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <SaveIcon color={!canAutoSave ? "error" : isEditorDirty ? "inherit" : "disabled"} />
                )
              }
              title={t(canAutoSave ? "editor.autoSave" : "editor.manualSave")}
              disabled={!isEditorDirty && canAutoSave}
              loading={isLoading}
              onClick={saveAll}
              sx={SX_TOOLBAR_BUTTON}
            />
          </EditorButtonGroup>

          <CommonToolbarButtons
            isTextEditing={!!textEditorSelection}
            togglePan={togglePan}
            toggleScale={toggleScale}
            onOrderSelection={orderSelection}
          />

          {!selectedElement && isLevelLoaded && (
            <LevelToolbarButtons forceOpen={forceOpenProperties} onCloseProperties={handleClosePropertiesModal} />
          )}

          {selectedElement && selectedComponent && selectedComponentManifest && (
            <ComponentToolbarButtons
              element={selectedElement}
              manifest={selectedComponentManifest}
              component={selectedComponent}
              onUpdate={handleUpdateSelectedElement}
              forceOpen={forceOpenProperties}
              onCloseProperties={handleClosePropertiesModal}
            />
          )}

          <ToolbarSpacer />

          {featureFlags["editor-stage-advanced"] && (
            <ToolbarButton
              icon={<SettingsIcon />}
              active={advancedDrawerOpen}
              title={t("editor.advanced")}
              onClick={handleToggleAdvancedDrawer}
            />
          )}
        </EditorToolbar>
        <div {...getFileDragProps()} style={STYLE_EDITOR_CONTENT}>
          <StageArea
            ref={drop}
            onClick={handleStageClick}
            onContextMenu={handleContextMenu}
            onDoubleClick={handleDoubleClickOnStageArea}
          >
            <TransformComponent wrapperStyle={transformWrapperStyle}>
              <Canvas sx={levelCanvasSx}>{MemoizedGameLevelCanvas}</Canvas>
              <Canvas sx={overlayCanvasSx}>{MemoizedGameOverlayCanvas}</Canvas>
              {gameData && isLevelLoaded && <Guidelines screen={gameData?.screen || DEFAULT_SCREEN} />}
              {gameData && isLevelLoaded && (selectMode || scaleMode) && (
                <HandlersContainer
                  screen={gameData.screen || DEFAULT_SCREEN}
                  showMeta={hasSelection && showIndexes}
                  layout={getCurrentLayout()}
                  zoom={editor.zoom}
                  pan={HANDLERS_PAN} // Hack: to extend guidelines outside the viewport (what is this??)
                  sx={handlersSx}
                  selection={editor.selection}
                  scaleModifier={scaleMode}
                  onSetSelection={handleSetSelection}
                  onDoubleClick={handleDoubleClick}
                  onElementUpdate={handleHandlerUpdate}
                  active
                />
              )}
              {textEditorElement && (
                <StageMDEditor
                  gameElement={textEditorElement}
                  language={editor.language}
                  renderingContext={renderingContext}
                  onChange={setTextEditorValue}
                  onCancel={cancelTextEditor}
                  onSubmit={consolidateTextEditor}
                />
              )}
            </TransformComponent>
          </StageArea>
        </div>

        <EditorToolbar position='bottom'>
          <EditorButtonGroup>
            <ToolbarButton
              icon={<VideoLabelIcon />}
              title={t("editor.stages")}
              active={stagesOpen}
              onClick={handleToggleStages}
            />
          </EditorButtonGroup>

          <OverlayToolbarButtons />

          <ToolbarSpacer />

          <EditorButtonGroup>
            <ToolbarButton
              icon={<CenterFocusStrongIcon />}
              title={t("editor.fitToScreen")}
              onClick={handleFitScreen}
              active={fitScreen}
            />
          </EditorButtonGroup>
          <EditorButtonGroup>
            <ToolbarButton icon={<ZoomOutIcon />} title={t("editor.zoomOut")} onClick={handleZoomOut} />
            <ToolbarButton title={`${Math.round(editor.zoom * 100)}%`} onClick={handleCenterView} />
            <ToolbarButton icon={<ZoomInIcon />} title={t("editor.zoomIn")} onClick={handleZoomIn} />
          </EditorButtonGroup>
        </EditorToolbar>
      </TransformWrapper>

      <Menu
        open={!!contextMenu}
        onClose={handleCloseContextMenu}
        anchorReference='anchorPosition'
        anchorPosition={contextMenu}
        onClick={handleCloseContextMenu}
        onContextMenu={handleCloseContextMenu}
      >
        <Box sx={STYLE_CONTEXT_MENU}>
          <MenuItem disabled>
            <strong>
              {hasSelection
                ? isSingleSelection
                  ? selectedComponentManifest?.name
                  : t("editor.multipleSelection")
                : t("editor.stage")}
            </strong>
          </MenuItem>
          <Divider />
          {!hasSelection && (
            <>
              <MenuItem onClick={handleOpenActionsModal}>
                <ListItemIcon>
                  <ActionsIcon color='error' />
                </ListItemIcon>
                <ListItemText>{t("editor.levelActions")}</ListItemText>
              </MenuItem>
              <MenuItem onClick={handleOpenAnimationsModal}>
                <ListItemIcon>
                  <AnimationIcon color='success' />
                </ListItemIcon>
                <ListItemText>{t("editor.levelTransitions")}</ListItemText>
              </MenuItem>
              <Divider />
            </>
          )}
          {isSingleSelection && (
            <>
              <MenuItem onClick={handleOpenPropertiesModal} disabled={!selectionHasProperties}>
                <ListItemIcon>
                  <SettingsIcon color='action' />
                </ListItemIcon>
                <ListItemText>{t("editor.properties")}</ListItemText>
              </MenuItem>
              <MenuItem onClick={handleOpenActionsModal}>
                <ListItemIcon>
                  <ActionsIcon color='error' />
                </ListItemIcon>
                <ListItemText>{t("editor.actions")}</ListItemText>
              </MenuItem>
              <MenuItem onClick={handleOpenAnimationsModal}>
                <ListItemIcon>
                  <AnimationIcon color='success' />
                </ListItemIcon>
                <ListItemText>{t("editor.elementEffects")}</ListItemText>
              </MenuItem>
              <Divider />
            </>
          )}
          {isSingleSelection && (
            <>
              <MenuItem onClick={handleMoveBack}>{t("editor.moveBackward")}</MenuItem>
              <MenuItem onClick={handleMoveForward}>{t("editor.moveForward")}</MenuItem>
              <MenuItem onClick={handleSendtoBack}>{t("editor.sendToBack")}</MenuItem>
              <MenuItem onClick={handleBringToFront}>{t("editor.bringToFront")}</MenuItem>
              <Divider />
            </>
          )}
          <MenuItem onClick={selectAll}>{t("editor.selectAll")}</MenuItem>
          <MenuItem onClick={handleCopySelection} disabled={!hasSelection}>
            {t("editor.copy")}
          </MenuItem>
          <MenuItem onClick={cutSelection} disabled={!hasSelection}>
            {t("editor.cut")}
          </MenuItem>
          <MenuItem onClick={pasteSelection} disabled={!hasData()}>
            {t("editor.paste")}
          </MenuItem>
          <MenuItem onClick={removeSelection} disabled={!hasSelection}>
            {t("editor.delete")}
          </MenuItem>
          <FeatureFlag feature='library-management'>
            {isSingleSelection && (
              <>
                <Divider />
                <MenuItem onClick={handleAddToLibrary}>
                  <ListItemIcon>
                    <AddToLibraryIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>{t("library.addToLibrary")}</ListItemText>
                </MenuItem>
              </>
            )}
          </FeatureFlag>
        </Box>
      </Menu>

      <AddToLibraryModal
        elementData={addToLibrary}
        onCancel={() => setAddToLibrary(undefined)}
        onSuccess={() => setAddToLibrary(undefined)}
      />

      <input {...getFileDragInputProps()} />
    </EditorViewBox>
  );
}
