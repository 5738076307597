import { useEffect, type DetailedHTMLProps, useState, useRef, useCallback, type AudioHTMLAttributes } from "react";
import { Box, CircularProgress, type SxProps } from "@mui/material";

import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import PlayIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { useViewportTools } from "@app/hooks";

interface CustomProps {
  boxSx?: SxProps;
}

type Props = Pick<DetailedHTMLProps<AudioHTMLAttributes<HTMLAudioElement>, HTMLAudioElement>, "src"> & CustomProps;

export function Audio(props: Props) {
  const { boxSx } = props;

  const { ref, isVisible } = useViewportTools<HTMLImageElement>();
  const audioRef = useRef<HTMLAudioElement>();
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!props.src) {
      setError(true);
      return;
    }
  }, [props.src, isVisible]);

  // unload audio when component unmounts
  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        audioRef.current = undefined;
      }
    };
  }, []);

  useEffect(() => {
    if (playing) {
      audioRef.current?.play();
    } else {
      audioRef.current?.pause();
    }
  }, [playing]);

  const play = useCallback(() => {
    if (!audioRef.current) {
      setLoading(true);
      audioRef.current = new window.Audio(props.src);
      audioRef.current.addEventListener("loadedmetadata", () => {
        setLoading(false);
      });
      audioRef.current.addEventListener("ended", () => {
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
          setPlaying(false);
        }
      });
    }

    audioRef.current.play();
    setPlaying(true);
  }, [props.src]);

  const pause = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      setPlaying(false);
    }
  }, [audioRef]);

  return (
    <Box ref={ref} sx={boxSx}>
      {loading && <CircularProgress />}
      {!loading && error && <BrokenImageIcon fontSize='large' sx={{ opacity: 0.3 }} />}
      {!loading && !error && playing && <PauseIcon fontSize='large' onClick={pause} />}
      {!loading && !error && !playing && <PlayIcon fontSize='large' onClick={play} />}
    </Box>
  );
}
