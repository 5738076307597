import { lazy, Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useMatches } from "react-router-dom";
import { Box, CircularProgress, LinearProgress, styled } from "@mui/material";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Resizable } from "re-resizable";
import { ErrorBoundary } from "react-error-boundary";

import { ComponentProvider } from "@shared/game-player";

import { CollapsableDrawer, EditorView, LevelPicker, useEditor } from "@app/editor";
import { useAppContext, usePersistedState } from "@app/hooks";
import { type GameDataInterface, type GameElement, type GameLayerContent } from "@shared/game-engine";
import { useDevice } from "@app/hooks/useDevice";
import { FeatureFlag } from "@app/components/FeatureFlag";

const DND_PROVIDER_OPTIONS = {};
const RESIZABLE_LEFT = { left: true };
const RESIZABLE_RIGHT = { right: true };

// This is a big ass
const AdvancedPropertiesForm = lazy(() => import("@app/advanced/AdvancedPropertiesForm/AdvancedPropertiesForm"));

const EditorPage = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
  justifyContent: "stretch"
}));

const EditorWrapper = styled(Box)(() => ({
  flex: 1
}));

const ContentArea = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  justifyContent: "stretch",
  flex: 1,
  overflow: "hidden"
}));

const ErrorBox = styled(Box)({
  color: "error",
  width: "100%",
  height: "100%",
  alignContent: "center",
  textAlign: "center"
});

const StyledLinearProgress = styled(LinearProgress)({
  // Make it float
  position: "absolute",
  zIndex: 10,
  width: "100%",
  height: "5px"
});

const ErrorComponent = function ({ error }: { error: Error }) {
  return (
    <ErrorBox>
      <h1>Something went wrong</h1>
      <pre>{error.message}</pre>
    </ErrorBox>
  );
};

export default function ProjectEditorPageLayout() {
  const matchedRoutes = useMatches();
  const { expandView } = useAppContext();
  const { isTouch } = useDevice();

  // Instead of display a 404 page, we just hide the left drawer
  // TODO: This is hacky. There must be a better way to check if the route is matched
  const isIndexRoute = matchedRoutes[matchedRoutes.length - 1].id.endsWith("/index");
  const isNonMatchedRoute = matchedRoutes[matchedRoutes.length - 1].id.endsWith("/[...all]");

  const {
    gameData,
    updateGameData,
    updateEditorState,
    editor: { selection, leftDrawerWidth, rightDrawerWidth },
    getSelectedElements,
    uploadProgress,
    uploadTotal,
    currentLayer
  } = useEditor();

  const [stagesDrawerOpen, setStagesDrawerOpen] = usePersistedState("editor-stages-drawer", true);
  const [advancedDrawerOpen, setAdvancedDrawerOpen] = useState(false);

  const leftDrawerSizeSettings = useMemo(() => ({ width: leftDrawerWidth }), [leftDrawerWidth]);
  const rightDrawerSizeSettings = useMemo(() => ({ width: rightDrawerWidth }), [rightDrawerWidth]);

  const firstSelectedElement = useMemo(
    () => (selection.length === 1 ? getSelectedElements()[0] : undefined),
    [selection, getSelectedElements]
  );

  const handleLeftResizableStop = useCallback(
    (_ev: unknown, _dir: unknown, element: HTMLElement) => {
      window.dispatchEvent(new Event("resize"));
      updateEditorState({ leftDrawerWidth: element.clientWidth });
    },
    [updateEditorState]
  );

  const handleAdvancedResizableStop = useCallback(
    (_ev: unknown, _dir: unknown, element: HTMLElement) => {
      window.dispatchEvent(new Event("resize"));
      updateEditorState({ rightDrawerWidth: element.clientWidth });
    },
    [updateEditorState]
  );

  const handleGameDataUpdate = useCallback(
    (data: Partial<GameDataInterface>) => {
      updateGameData(data, { replace: true });
      return true;
    },
    [updateGameData]
  );

  const handleLayerDataUpdate = useCallback(
    (data: Partial<GameLayerContent>) => {
      currentLayer?.update(data, { replace: true });
      return true;
    },
    [currentLayer]
  );

  const handleElementDataUpdate = useCallback(
    (data: Partial<GameElement> & Pick<GameElement, "id">) => {
      currentLayer?.updateElement(data.id, data, { replace: true });
      return true;
    },
    [currentLayer]
  );

  // Remove page margins
  useEffect(() => {
    expandView(true);
    return () => expandView(false);
  }, [expandView]);

  return (
    <>
      {uploadTotal > 0 && <StyledLinearProgress variant='determinate' value={(uploadProgress / uploadTotal) * 100} />}
      <ComponentProvider
        builtinPluginsBaseUrl={import.meta.env.VITE_PLUGIN_BUILTIN_BASE_URL}
        pluginsBaseUrl={import.meta.env.VITE_PLUGIN_BASE_URL}
        indexFile={import.meta.env.VITE_PLUGIN_INDEX_FILE}
      >
        <DndProvider
          key={isTouch ? "touch" : "mouse"} // Force re-render on touch/mouse change
          backend={isTouch ? TouchBackend : HTML5Backend}
          options={DND_PROVIDER_OPTIONS}
        >
          <EditorPage>
            {!isNonMatchedRoute && !isIndexRoute && (
              <Resizable
                defaultSize={leftDrawerSizeSettings}
                minWidth={200}
                maxWidth={800}
                enable={RESIZABLE_RIGHT}
                onResizeStop={handleLeftResizableStop}
              >
                <CollapsableDrawer size='100%' open>
                  <Outlet />
                </CollapsableDrawer>
              </Resizable>
            )}
            <ContentArea>
              <EditorWrapper>
                <ErrorBoundary FallbackComponent={ErrorComponent}>
                  <EditorView
                    stagesOpen={stagesDrawerOpen}
                    advancedDrawerOpen={advancedDrawerOpen}
                    onToggleStages={setStagesDrawerOpen}
                    onToggleAdvancedDrawer={setAdvancedDrawerOpen}
                  />
                </ErrorBoundary>
              </EditorWrapper>
              <CollapsableDrawer open={stagesDrawerOpen} horizontal maximized size='130px'>
                <LevelPicker mode='level' />
              </CollapsableDrawer>
            </ContentArea>
            <FeatureFlag feature='editor-stage-advanced'>
              {advancedDrawerOpen && (
                <Resizable
                  defaultSize={rightDrawerSizeSettings}
                  minWidth={300}
                  maxWidth={800}
                  enable={RESIZABLE_LEFT}
                  onResizeStop={handleAdvancedResizableStop}
                >
                  <CollapsableDrawer size='100%' open>
                    <Suspense fallback={<CircularProgress />}>
                      <AdvancedPropertiesForm
                        gameData={gameData}
                        layerId={currentLayer?.id}
                        layerData={currentLayer?.data}
                        element={firstSelectedElement}
                        onGameUpdate={handleGameDataUpdate}
                        onLayerUpdate={handleLayerDataUpdate}
                        onElementUpdate={handleElementDataUpdate}
                      />
                    </Suspense>
                  </CollapsableDrawer>
                </Resizable>
              )}
            </FeatureFlag>
          </EditorPage>
        </DndProvider>
      </ComponentProvider>
    </>
  );
}
