import React, {
  ReactNode,
  useEffect,
  useMemo,
  useState,
  type ReactElement,
} from "react";

import {
  Modal,
  Box,
  styled,
  IconButton,
  Button,
  Tabs,
  Tab,
  SxProps,
  Fade,
} from "@mui/material";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PremiumBadge from "@shared/branding/theufolab/premium.svg";

export const ModalBox = styled(Box)(({ theme }) => ({
  fontSize: "1rem",
  position: "absolute",
  top: "5%",
  left: "50%",
  transform: "translate(-50%, 0)",
  maxWidth: "min(90vw, 980px)",
  maxHeight: "90%",
  width: "100%",
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  outline: "none",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
}));

export const TitleBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  alignContent: "center",
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(0, 3),

  h6: {
    background: theme.palette.divider,
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,

    "&.selected": {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));

export const TitleButton = styled(Button)(({ theme }) => ({
  fontSize: "1.25rem",
  textTransform: "none",
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: "none",

  "&.selected": {
    background: theme.palette.divider,
  },
}));

export const ModalContentBox = styled(Box)(({ theme }) => ({
  flexDirection: "column",
  flexGrow: 1,
  overflow: "auto",
  padding: theme.spacing(4),

  "&.with-tabs": {
    padding: 0,
  },
}));

export const ModalButtonBar = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: theme.spacing(2),
  padding: theme.spacing(4),
  borderTop: `1px solid ${theme.palette.divider}`,
  width: "100%",
}));

interface ModalSectionProps {
  id: string;
  title: ReactNode;
  icon?: ReactElement;
  children: ReactNode;
  withSideTabs?: boolean;
  feature?: string;
  hidden?: boolean;
}

interface MultiSectionModalProps {
  open?: boolean;
  children:
    | (ReactElement<typeof ModalSection> | null)
    | (ReactElement<typeof ModalSection> | null)[];
  openSection?: string;
  onClose?: () => void;
  footer?: ReactNode;
  alwaysRender?: boolean;
  sx?: SxProps;
  className?: string;
}

export function ModalSection(props: ModalSectionProps) {
  return <>{props.children}</>;
}

export function MultiSectionModal(props: MultiSectionModalProps) {
  const [section, setSection] = useState<string>();

  const titles = useMemo(
    () =>
      React.Children.map(props.children, (child) => {
        if (
          React.isValidElement<ModalSectionProps>(child) &&
          child.props.id &&
          !child.props.hidden
        ) {
          return (
            <Tab
              key={child.props.id}
              value={child.props.id}
              label={
                <>
                  {child.props.title}
                  {child.props.feature ? (
                    <img
                      src={PremiumBadge}
                      height={15}
                      style={{ marginLeft: 8, marginTop: -1 }}
                      alt="Premium feature"
                    />
                  ) : undefined}
                </>
              }
              icon={child.props.icon}
              iconPosition="start"
            />
          );
        }
      }),
    [props.children]
  );

  const sections = useMemo(
    () =>
      React.Children.map(props.children, (child) => {
        if (
          React.isValidElement<ModalSectionProps>(child) &&
          child.props.id &&
          !child.props.hidden
        ) {
          return {
            id: child.props.id,
            withSideTabs: child.props.withSideTabs,
            content: child,
          };
        }
      }),
    [props.children]
  );

  useEffect(() => {
    if (props.openSection && props.open) {
      setSection(props.openSection);
    }
  }, [props.openSection, props.open]);

  // Auto select first section
  useEffect(() => {
    if (!section && sections) {
      setSection(sections[0].id);
    }
  }, [section, sections]);

  const currentSection =
    section && sections ? sections?.find((s) => s.id === section) : undefined;

  const classes = [props.className, props.open ? "MuiModal-open" : ""].filter(
    Boolean
  );

  return (
    <Modal open={props.open || false} onClose={props.onClose}>
      <Fade in={props.open}>
        <ModalBox className={classes.join(" ")} sx={props.sx}>
          <TitleBox>
            <Tabs
              value={section}
              onChange={(_ev, tabId) => setSection(tabId)}
              sx={{ flexGrow: 1 }}
              centered
            >
              {titles}
            </Tabs>
            <IconButton onClick={props.onClose}>
              <HighlightOffIcon />
            </IconButton>
          </TitleBox>
          {sections?.map(
            (s) =>
              props.alwaysRender && (
                <ModalContentBox
                  key={s.id}
                  className={s.withSideTabs ? "with-tabs" : ""}
                  display={s.id === section ? "flex" : "none"}
                >
                  {s.content}
                </ModalContentBox>
              )
          )}
          {!props.alwaysRender && currentSection && (
            <ModalContentBox
              className={currentSection.withSideTabs ? "with-tabs" : ""}
            >
              {currentSection.content}
            </ModalContentBox>
          )}
          {props.footer && <ModalButtonBar>{props.footer}</ModalButtonBar>}
        </ModalBox>
      </Fade>
    </Modal>
  );
}
