import { forwardRef, type CSSProperties, type ReactNode } from "react";
import { type GameTheme, type GameScreenSettings } from "@shared/game-engine";

export interface StageProps {
  id?: string;
  screen: GameScreenSettings;
  theme: GameTheme;
  style?: CSSProperties;
  children: ReactNode;
}

export const Stage = forwardRef<HTMLDivElement, StageProps>(function (
  { id, screen, style = {}, theme, children },
  ref
) {
  const finalStyle: CSSProperties = {
    width: screen.width,
    height: screen.height,
    position: "relative",

    backgroundColor: theme.background,
    color: theme.text,

    ...style,

    overflow: screen.drawOutside ? "visible" : "hidden",
  };

  return (
    <div id={id} style={finalStyle} ref={ref}>
      {children}
    </div>
  );
});

export const StageLayer = ({
  children,
  style,
}: {
  children: ReactNode;
  style?: CSSProperties;
}) => {
  const finalStyle: CSSProperties = {
    ...style,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "absolute",
  };

  return <div style={finalStyle}>{children}</div>;
};
