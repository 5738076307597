import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  type AutocompleteRenderInputParams,
  TextField,
} from "@mui/material";
import { withJsonFormsControlProps } from "@jsonforms/react";
import {
  ControlProps,
  RankedTester,
  isStringControl,
  rankWith,
} from "@jsonforms/core";

import { FieldWrapper } from "../common/FieldWrapper";

interface Props extends ControlProps {
  data: string;
  path: string;
  handleChange: (path: string, value: string | undefined) => void;
}

type AutocompleteOption = { label: string; value: string } | string | null;

export function TextFieldRenderer(props: Props) {
  const {
    label,
    description,
    data,
    path,
    visible,
    uischema,
    enabled,
    errors,
    handleChange,
  } = props;

  const [currentValue, setCurrentValue] = useState<string | undefined>();
  const [hasFocus, setHasFocus] = useState(false);

  useEffect(() => {
    if (!hasFocus) {
      setCurrentValue(data ? String(data) : "");
    }
  }, [data, hasFocus]);

  const handleOptionEqualToValue = useCallback(
    (
      option: { label: string; value: string },
      value: { label: string; value: string }
    ) => option.value === value.value,
    []
  );

  const renderInput = useCallback(
    (props: AutocompleteRenderInputParams) => (
      <TextField
        multiline={uischema.options?.multi ? true : false}
        minRows={uischema.options?.multi ? 3 : undefined}
        {...props}
      />
    ),
    [uischema.options?.multi]
  );

  const handleInputChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = ev.target.value;
      setCurrentValue(newValue);
      handleChange(path, newValue);
    },
    [handleChange, path]
  );

  const handleInputChangeFromAutocomplete = useCallback(
    (_: unknown, newValue: string) => {
      setCurrentValue(newValue);
      handleChange(path, newValue);
    },
    [handleChange, path]
  );

  const handleAutocompleteChange = useCallback(
    (_: unknown, value: AutocompleteOption) => {
      const newValue = typeof value === "string" ? value : value?.value;
      setCurrentValue(newValue);
      handleChange(path, newValue);
    },
    [handleChange, path]
  );

  const handleFocus = useCallback(() => {
    setHasFocus(true);
  }, []);

  const handleBlur = useCallback(() => {
    handleChange(path, currentValue);
    setHasFocus(false);
  }, [handleChange, path, currentValue]);

  const currentValueOption = useMemo(
    () =>
      uischema.options?.autocomplete?.find((option: AutocompleteOption) =>
        typeof option === "string"
          ? option === currentValue
          : option?.value === currentValue
      ),
    [currentValue, uischema.options?.autocomplete]
  );

  if (!visible) {
    return null;
  }

  const options: AutocompleteOption[] = uischema.options?.autocomplete || [];

  return (
    <FieldWrapper
      label={label}
      description={description}
      uiSchema={uischema}
      errors={errors}
    >
      {options.length ? (
        <Autocomplete
          disablePortal
          fullWidth
          freeSolo
          isOptionEqualToValue={handleOptionEqualToValue}
          options={options as { label: string; value: string }[]}
          disabled={!enabled}
          renderInput={renderInput}
          inputValue={currentValue ?? ""}
          onInputChange={handleInputChangeFromAutocomplete}
          value={currentValueOption ?? null}
          onChange={handleAutocompleteChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      ) : (
        <TextField
          fullWidth
          disabled={!enabled}
          value={currentValue ?? ""}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          multiline={uischema.options?.multi ? true : false}
          minRows={uischema.options?.multi ? 3 : undefined}
          placeholder={uischema.options?.placeholder}
        />
      )}
    </FieldWrapper>
  );
}

const tester: RankedTester = rankWith(10, (uiSchema, schema, context) => {
  return isStringControl(uiSchema, schema, context) ? true : false;
});

export default {
  tester,
  renderer: withJsonFormsControlProps(TextFieldRenderer),
};
