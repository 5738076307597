import {
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  type CSSProperties,
} from "react";

import {
  type GameTheme,
  type GameLevelRendered,
  type GameScreenSettings,
  type GameOverlayRendered,
  type GameStateRendered,
  type GameActionTrigger,
} from "@shared/game-engine";

import { StageElement } from "./components/StageElement";
import { Stage, StageLayer } from "./components/Stage";
import { transitionObjectToCSSAnimation } from "./utils/styles";
import { type RenderingContext } from "./types/canvas";

export interface GameCanvasProps {
  id?: string;
  editorMode?: boolean;
  screen: GameScreenSettings;
  theme: GameTheme;
  levelId?: string;
  levelData?: GameLevelRendered;
  overlayId?: string;
  overlayData?: GameOverlayRendered;
  gameState?: GameStateRendered;
  style?: CSSProperties;
  show?: boolean;
  globalVolume?: number;
  noTransitions?: boolean;
  onAction?: (
    id: string,
    trigger?: GameActionTrigger,
    payload?: string
  ) => void;
  onTransitionEnd?: () => void;
  debug?: boolean;
  renderingContext?: Partial<RenderingContext>;
}

interface EnqueuedAction {
  id: string;
  trigger: GameActionTrigger;
  payload?: string;
}

function mergeRefs(...refs: unknown[]) {
  return (instance: unknown) => {
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(instance);
      } else if (typeof ref === "object" && ref !== null && "current" in ref) {
        ref.current = instance;
      }
    });
  };
}

export const GameCanvas = forwardRef<HTMLDivElement, GameCanvasProps>(
  function (props, ref) {
    const {
      id,
      screen,
      levelId,
      levelData,
      overlayId,
      overlayData,
      gameState,
      style,
      theme,
      editorMode,
      show,
      noTransitions,
      onAction,
      onTransitionEnd,
      renderingContext = {},
      globalVolume = 1,
    } = props;

    const [actionQueue, setActionQueue] = useState<EnqueuedAction[]>([]);
    const stageRef = useRef<HTMLDivElement>(null);

    // Transitions styles
    const animationType = show ? "in" : "out";
    const transition = levelData?.transitions?.[animationType];

    const levelStyle: CSSProperties = useMemo(() => {
      const transitionsStyle =
        !noTransitions && transition
          ? transitionObjectToCSSAnimation(transition, animationType)
          : {};

      return {
        ...transitionsStyle,
        ...levelData?.style,
      };
    }, [animationType, levelData?.style, noTransitions, transition]);

    const overlayStyle: CSSProperties = useMemo(
      () => ({
        ...overlayData?.style,
        pointerEvents: "none",
      }),
      [overlayData]
    );

    // Enqueue actions coming from stage elements
    const handleAction = useCallback(
      (id: string, trigger: GameActionTrigger, payload?: string) => {
        setActionQueue((prev) => [...prev, { id, trigger, payload }]);
      },
      []
    );

    // Handle transitions
    const stageEl = stageRef.current;
    useEffect(() => {
      if (stageEl && transition && onTransitionEnd) {
        const checkTransitionEnd = (e: TransitionEvent) => {
          // Check only for stage transitions
          if (e.target === e.currentTarget) {
            onTransitionEnd();
          }
        };

        stageEl.addEventListener("transitionend", checkTransitionEnd);
        const timeout = setTimeout(checkTransitionEnd, 5000); // MAX 5 seconds

        return () => {
          stageEl.removeEventListener("transitionend", checkTransitionEnd);
          clearTimeout(timeout);
        };
      }
    }, [transition, onTransitionEnd, stageEl]);

    // Process action queue, one by one. useLayoutEffect makes sure this is
    // executed before the render. This is important to avoid blank screens
    // between scenes
    useLayoutEffect(() => {
      if (actionQueue.length > 0) {
        const action = actionQueue[0];
        if (onAction) {
          onAction(action.id, action.trigger, action.payload);
        }

        setActionQueue((prev) => prev.slice(1));
      }
    }, [actionQueue, onAction]);

    const memoizedRenderingContext = useMemo<RenderingContext>(
      () => ({
        canvasScale: renderingContext.canvasScale ?? 1,
        canvasWidth: screen.width,
        canvasHeight: screen.height,
        canvasX: renderingContext.canvasX ?? 0,
        canvasY: renderingContext.canvasY ?? 0,
        viewportWidth: renderingContext.viewportWidth ?? screen.width,
        viewportHeight: renderingContext.viewportHeight ?? screen.height,
      }),
      [renderingContext, screen]
    );

    return (
      <Stage
        id={id}
        screen={screen}
        theme={theme}
        ref={mergeRefs(ref, stageRef)}
        style={style}
      >
        <StageLayer style={levelStyle}>
          {levelData?.layout.map((element) => (
            <StageElement
              key={`${levelId || ""}-${element.id}`}
              renderingContext={memoizedRenderingContext}
              element={element}
              gameState={gameState}
              onAction={handleAction}
              noTransitions={noTransitions}
              editorMode={editorMode}
              globalVolume={globalVolume}
            />
          ))}
        </StageLayer>
        <StageLayer style={overlayStyle}>
          {overlayData?.layout.map((element) => (
            <StageElement
              key={`${overlayId || ""}-${element.id}`}
              renderingContext={memoizedRenderingContext}
              element={element}
              gameState={gameState}
              onAction={handleAction}
              noTransitions={noTransitions}
              editorMode={editorMode}
              globalVolume={globalVolume}
            />
          ))}
        </StageLayer>
      </Stage>
    );
  }
);
