import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";

import { Box, Typography, useTheme } from "@mui/material";

import { Link, Navigate, type Path, useNavigate } from "@app/router";
import { FullScreenLoading, LoginForm, type LoginFormData } from "@app/components";
import { useBackend, useTagManager, useTranslation } from "@app/hooks";

export default function LoginPage() {
  const { t } = useTranslation();
  const location = useLocation();
  const theme = useTheme();
  const { login, isBackendReady, loggedInUser } = useBackend();
  const tm = useTagManager();

  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = useCallback(
    async (data: LoginFormData): Promise<boolean> => {
      setError("");

      try {
        await login(data.email, data.password, data.remember);

        tm("login");

        // Delay to wait for state change before redirect. FIXME: This is a hack.
        // TODO: revisit this, this might be doing NOTHING
        setTimeout(() => {
          const targetUrl = new URLSearchParams(location.search).get("redirectTo") as Path;
          navigate(targetUrl || "/");
        }, 1);

        return true;
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError(t("login.error").toString());
        }

        return false;
      }
    },
    [login, tm, location.search, navigate, t]
  );

  // TODO: use suspense
  if (!isBackendReady) {
    return <FullScreenLoading />;
  }

  if (loggedInUser) {
    return <Navigate to='/dashboard' />;
  }

  return (
    <>
      <Typography variant='h5' sx={{ display: "block", margin: theme.spacing(2, 0, 0, 0) }}>
        {t("home.login")}
      </Typography>

      <LoginForm onSubmit={handleLogin} errors={error ? { root: error } : undefined} />

      <Box display='block' sx={{ marginTop: theme.spacing(1) }}>
        <Typography display='block' align='center'>
          <Link to='/auth/password-recovery'>{t("login.forgotPassword")}</Link>
        </Typography>
      </Box>

      <Box display='block' sx={{ marginTop: theme.spacing(1) }}>
        <Typography display='block' align='center'>
          {t("login.noAccount")} <Link to='/auth/register'>{t("login.createAccount")}</Link>
        </Typography>
      </Box>
    </>
  );
}
