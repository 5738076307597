import { useCallback, useEffect, useState } from "react";
import { Box, Paper, useTheme } from "@mui/material";

import { Button, SectionTitle } from "@app/components";
import { useEditor } from "@app/editor";
import { useTranslation } from "@app/hooks";
import { useNavigate, type Path } from "@app/router";

import { Form } from "@shared/form-builder";
import { DEFAULT_GAMEFLOW, DEFAULT_SCORING, DEFAULT_TIMER, type GameDataInterface } from "@shared/game-engine";
import { GameScoringSchema, GameScoringUISchema } from "@app/schemas/game.scoring.schema";
import { GameFlowUISchema, getGameFlowSchema } from "@app/schemas/game.flow.schema";
import { GameTimerUISchema, getGameTimerSchema } from "@app/schemas/game.timer.schema";

export default function ProjectSettingsGamification({ nextTourPath }: { nextTourPath?: Path }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { gameData, updateGameData, uuid, version, gameDataInfo } = useEditor();
  const navigate = useNavigate();

  const [currentData, setCurrentData] = useState<GameDataInterface | undefined>(gameData);
  const [isDirty, setIsDirty] = useState(false);

  const handleUpdate = useCallback(function <K extends keyof GameDataInterface, T extends GameDataInterface[K]>(
    section: K
  ) {
    return (data: T) => {
      setCurrentData((prevData) => {
        if (!prevData) {
          return prevData;
        }

        return {
          ...prevData,
          [section]: data
        };
      });
      setIsDirty(true);
    };
  }, []);

  useEffect(() => {
    setCurrentData(gameData);
    setIsDirty(false);
  }, [gameData]);

  const discard = useCallback(() => {
    setCurrentData(gameData);
    setIsDirty(false);
  }, [gameData]);

  const submit = useCallback(() => {
    if (currentData) {
      updateGameData(currentData);
      setIsDirty(false);
    }

    if (nextTourPath) {
      navigate(nextTourPath, { params: { uuid, version } as never });
    }
  }, [currentData, navigate, nextTourPath, updateGameData, uuid, version]);

  if (!currentData) {
    return null;
  }

  return (
    <Paper sx={{ padding: theme.spacing(8) }} elevation={0}>
      <SectionTitle title={t("editProject.scoring.title")} />
      <p>{t("editProject.scoring.description")}</p>

      <Form
        data={currentData.scoring || DEFAULT_SCORING}
        schema={GameScoringSchema}
        uiSchema={GameScoringUISchema}
        onChange={handleUpdate("scoring")}
        live
      />

      <SectionTitle title={t("editProject.gameflow.title")} />
      <p>{t("editProject.gameflow.description")}</p>

      <Form
        data={currentData.gameflow || DEFAULT_GAMEFLOW}
        schema={getGameFlowSchema(gameDataInfo)}
        uiSchema={GameFlowUISchema}
        onChange={handleUpdate("gameflow")}
      />

      <SectionTitle title={t("editProject.timer.title")} />
      <p>{t("editProject.timer.description")}</p>

      <Form
        data={currentData.timer || DEFAULT_TIMER}
        schema={getGameTimerSchema(gameDataInfo)}
        uiSchema={GameTimerUISchema}
        onChange={handleUpdate("timer")}
        live
      />

      {!nextTourPath && (
        <Box textAlign='center' width='100%' mt={4}>
          <Button onClick={discard} variant='contained' color='info' sx={{ mr: 1 }}>
            {t("common.discardChanges")}
          </Button>
          <Button onClick={submit} variant='contained' disabled={!isDirty}>
            {t("common.applyChanges")}
          </Button>
        </Box>
      )}

      {nextTourPath && (
        <Box textAlign='center' width='100%' mt={4}>
          <Button onClick={submit} variant='contained' size='large'>
            {t("common.next")}
          </Button>
        </Box>
      )}
    </Paper>
  );
}
