import { useAppContext } from "@app/hooks";
import { Tab, type TabProps } from "@mui/material";
import type { Feature } from "@shared/utils/features";

interface FeatureFlagProps {
  feature: Feature;
}

export const FeatureFlag = ({ feature, children }: FeatureFlagProps & { children: React.ReactNode }) => {
  const { featureFlags } = useAppContext();

  if (!featureFlags[feature]) {
    return null;
  }

  return children;
};

export const TabFeatureFlag = ({ feature, children, ...tabProps }: FeatureFlagProps & TabProps) => {
  const { featureFlags } = useAppContext();

  if (!featureFlags[feature]) {
    return null;
  }

  return <Tab {...tabProps}>{children}</Tab>;
};
