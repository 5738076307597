import isObject from "lodash/isObject";
import isArray from "lodash/isArray";
import isString from "lodash/isString";

import { GameCommand, GameErrorCode } from "./consts";
import { GameError } from "./errors";
import {
  GameElementCategory,
  GameElement,
  GameElementRendered,
  GameLevelRendered,
  GameLog,
  Translation,
  Translations,
  GameOverlayRendered,
} from "./types";

import { DEFAULT_LANGUAGE } from "./consts/language";

import { GameState } from "./GameState";
import { type GameData } from "./GameData";
import { evaluateExpression } from "./helpers/expression";
import { hasActions } from "./helpers";
import { gluePath } from "../../utils/src/paths";

export interface GameRendererOptions {
  keepIds?: boolean;
  libraryBaseUrl?: string;
  assetBaseUrl?: string;
  pluginBaseUrl?: string;
}

/**
 * The game renderer.
 *
 * @export
 * @class GameRenderer
 */
export class GameRenderer {
  protected readonly gameData: GameData;
  protected readonly gameState: GameState;
  protected readonly gameLog?: GameLog;

  protected readonly libraryBaseUrl: string;
  protected readonly assetBaseUrl: string;
  protected readonly pluginBaseUrl: string;
  protected readonly keepIds: boolean;

  /**
   * Creates an instance of GameRenderer.
   *
   * @param gameData The game data
   * @param gameState The game state
   */
  constructor(
    gameData: GameData,
    gameState?: GameState,
    gameLog?: GameLog,
    options: GameRendererOptions = {}
  ) {
    this.gameData = gameData;
    this.gameState = gameState || new GameState(gameData);
    this.gameLog = gameLog;
    this.libraryBaseUrl = options.libraryBaseUrl || "/library";
    this.assetBaseUrl = options.assetBaseUrl || "/assets";
    this.pluginBaseUrl = options.pluginBaseUrl || "/plugins";
    this.keepIds = options.keepIds || false;
  }

  // Note: this seems duplicated from `getAssetUrl` in shared/utils/src/plugins/asset.ts
  getAssetURL(path: string): string {
    return gluePath(this.assetBaseUrl, path);
  }

  getLibraryAssetURL(path: string): string {
    return gluePath(this.libraryBaseUrl, path);
  }

  getPluginAssetURL(path: string, componentName: string): string {
    return gluePath(this.pluginBaseUrl, "game-component", componentName, path);
  }

  private replaceAssetUrls<T>(obj: T, referenceElement?: GameElement): T {
    let currentReferenceElement = referenceElement;

    if (!obj) {
      return obj;
    }

    // If the object is a game element, set it as the current reference element
    if (
      typeof obj === "object" &&
      "type" in obj &&
      "component" in obj &&
      "id" in obj
    ) {
      currentReferenceElement = obj as unknown as GameElement;
    }

    if (isString(obj)) {
      return (
        obj
          // Replace asset:// with the correct URL
          .replace(/\basset:\/\/([^"]+)/, (_full, path) =>
            this.getAssetURL(path)
          )
          // Replace asset:// with the correct URL
          .replace(/\blibrary:\/\/([^"]+)/, (_full, path) =>
            this.getLibraryAssetURL(path)
          )
          // Replace plugin:// with the correct URL
          .replace(/\bplugin:\/\/([^"]+)/, (_full, path) =>
            this.getPluginAssetURL(
              path,
              currentReferenceElement?.component || "unknown"
            )
          ) as unknown as T
      );
    }

    if (isArray(obj)) {
      return obj.map((item) =>
        this.replaceAssetUrls(item, currentReferenceElement)
      ) as T;
    }

    if (isObject(obj)) {
      return Object.entries(obj).reduce(
        (out, [key, value]) => ({
          ...out,
          [key]: this.replaceAssetUrls(value, currentReferenceElement),
        }),
        {} as T
      );
    }

    return obj;
  }

  private replaceCSSAssets<T>(obj: T): T {
    if (!obj) {
      return obj;
    }

    // "rawStyle" case: convert asset(...) or library(...) into url(...)
    if (isString(obj)) {
      return obj
        .replace(
          /\basset\(([^)]+)\)\b/,
          (_full, _type, path) => `url("${this.getAssetURL(path)}")`
        )
        .replace(
          /\blibrary\(([^)]+)\)\b/,
          (_full, _type, path) => `url("${this.getLibraryAssetURL(path)}")`
        ) as unknown as T;
    }

    // "style" case. Only do replacements in strings properties
    if (isObject(obj)) {
      return Object.entries(obj).reduce(
        (out, entry) => ({
          ...out,
          [entry[0]]: isString(entry[1])
            ? this.replaceCSSAssets(entry[1])
            : entry[1],
        }),
        {} as T
      );
    }

    return obj;
  }

  private getTranslation(
    translations: Translations,
    language: string,
    replacements?: Record<string, unknown>
  ): Translation {
    const translation = translations
      ? this.replaceAssetUrls(translations?.[language] || {})
      : {};

    return Object.keys(translation).reduce<Translation>(
      (output, key) => ({
        ...output,
        [key]: (translation[key] as string).replace(
          /\{\{([^}]*)\}\}/g,
          (match: string, expression: string) => {
            if (!expression || !replacements) return match;

            try {
              const result = evaluateExpression(expression, replacements);

              if (result instanceof Error) {
                throw result;
              }

              return String(result) || "";
            } catch (err: unknown) {
              // If the expression is invalid, return the original match
              return match;
            }
          }
        ),
      }),
      {}
    );
  }

  /**
   * Return the solved state of a lock, if the lock has been solved already
   *
   * @param lockId
   * @returns the answer that solved the lock
   */
  private getSolvedState(element: GameElement): string | undefined {
    if (!element.lockId || !this.gameState.hasKey(element.lockId)) {
      // shortcut
      return;
    }

    // Return the answer that solved the lock
    // Or the first solution if no answer is found
    const userAnswer =
      this.gameLog?.find(
        (log) =>
          log.action === GameCommand.RESOLVE_OK && log.target === element.lockId
      )?.payload ||
      element.solutions?.[0] || // pick the first solution
      "ok";

    return userAnswer;
  }

  /**
   * Return the number of failed attempts to solve a lock
   *
   * @param lockId
   * @returns number of failed attempts
   */
  private getFailedCount(lockId: string): number | undefined {
    // Return the answer that solved the lock
    const failedRecords = this.gameLog?.filter(
      (log) => log.action === GameCommand.RESOLVE_FAIL && log.target === lockId
    );

    return failedRecords?.length;
  }

  /**
   * Check if a condition is met. TODO: This is a copy of the same function in GameEngine
   *
   * @param condition The condition
   * @returns If the condition is met or not
   */
  private checkCondition(condition: string): boolean {
    try {
      const vars = this.gameState.toExpressionVars();
      return !!evaluateExpression(condition, vars);
    } catch (error) {
      return false;
    }
  }

  public renderElement(
    element: GameElement,
    language: string = DEFAULT_LANGUAGE,
    textReplacements?: Record<string, unknown>
  ): GameElementRendered {
    const { style, rawStyle, layout, condition, translations, ...rest } =
      element;

    const enabled =
      element.enabled !== false &&
      (!condition || this.checkCondition(condition));

    const actionable =
      hasActions(element) ||
      [
        GameElementCategory.ITEM,
        GameElementCategory.LOCK,
        GameElementCategory.MINIGAME,
      ].includes(element.type);

    const solved =
      element.type === GameElementCategory.LOCK &&
      element.lockId &&
      this.gameState.hasKey(element.lockId)
        ? this.getSolvedState(element)
        : undefined;

    const failed =
      element.type === GameElementCategory.LOCK && element.lockId
        ? this.getFailedCount(element.lockId)
        : undefined;

    const renderedElement: GameElementRendered = {
      ...this.replaceAssetUrls(rest),

      enabled,
      type: element.type,
      actions: element.actions,
      actionable,
      solved,
      failed,
      style: this.replaceCSSAssets(style),
      rawStyle: this.replaceCSSAssets(rawStyle),

      translation: this.getTranslation(
        translations || {},
        language,
        textReplacements
      ),

      layout:
        layout &&
        layout.map((element) =>
          this.renderElement(element, language, textReplacements)
        ),
    };

    // Delete properties that FE should not see
    delete renderedElement.actions;
    delete renderedElement.condition;
    delete renderedElement.solutions;

    return renderedElement;
  }

  /**
   * Render the current level according to the game state
   *
   * @returns
   */
  renderLevel(
    levelId: string,
    language: string,
    customData: Record<string, unknown> = {}
  ): GameLevelRendered {
    const levelData = this.gameData.getRenderedLevelData(
      levelId,
      this.keepIds ? undefined : this.gameState.uuid
    );

    if (!levelData) {
      throw new GameError(
        GameErrorCode.LEVEL_NOT_FOUND,
        "The level does not exist",
        undefined,
        this.gameState
      );
    }

    return {
      ...levelData,

      layout: levelData.layout.map((element) =>
        this.renderElement(element, language, customData)
      ),

      // Extra useful data for the FE
      isGameOverLevel: levelId === this.gameData.gameflow.gameOverLevelId,
      isStartCounterLevel: false,
      isInitialLevel: levelId === this.gameData.gameflow.initialLevelId,
      isFinalLevel: levelId === this.gameData.gameflow.finishLevelId,
      transitionOutDuration: levelData.transitions?.out?.name
        ? (levelData.transitions.out.duration || 0) * 1000 +
          (levelData.transitions.out.delay || 0) * 1000
        : 0,
    };
  }

  /**
   * Render the overlay level according to the game state
   *
   * @returns
   */
  renderOverlay(
    overlayId: string,
    language: string,
    customData: Record<string, unknown> = {}
  ): GameOverlayRendered {
    const overlayData = this.gameData.getRenderedOverlayData(
      overlayId,
      this.keepIds ? undefined : this.gameState.uuid
    );

    if (!overlayData) {
      throw new GameError(
        GameErrorCode.OVERLAY_NOT_FOUND,
        "The overlay does not exist",
        undefined,
        this.gameState
      );
    }

    return {
      ...overlayData,

      layout: overlayData.layout.map((element) =>
        this.renderElement(element, language, customData)
      ),
    };
  }
}
