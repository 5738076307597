import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Divider, Slider, styled, Typography } from "@mui/material";
import { Link as SimpleLink } from "react-router-dom";

import { Button, FullScreenLoading } from "@app/components";
import { useAppContext, useBackend, useTagManager, useTranslation } from "@app/hooks";
import { useModals, useNavigate, useParams } from "@app/router";
import { type GameDefinitionDto } from "@shared/api-client";
import { ModalSection, MultiSectionModal } from "@shared/form-builder/common/MultiSectionModal";
import { type GameDataInterface } from "@shared/game-engine";

import LinkIcon from "@mui/icons-material/Link";
import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";
import LaptopChromebookOutlinedIcon from "@mui/icons-material/LaptopChromebookOutlined";
import PublishIcon from "@mui/icons-material/Publish";

import UfitoScorm from "@shared/branding/imagery/art/ufito-cohete.png";
import UfitoURL from "@shared/branding/imagery/art/ufito-planeta.png";
import UfitoIframe from "@shared/branding/imagery/art/ufito-cientifico-1.png";
import { FeatureFlag } from "@app/components/FeatureFlag";

const iframeSizeMarks = [
  { value: 0.33, label: "33%" },
  { value: 0.66, label: "66%" },
  { value: 1, label: "100%" }
];

const StyledInput = styled("input")(({ theme }) => ({
  width: "100%",
  padding: "0.5rem",
  margin: "1rem 0",
  border: "1px solid #ccc",
  borderRadius: "4px",
  textAlign: "center",
  fontSize: "1.1rem",
  fontFamily: "TheUFOLabFont",
  fontWeight: "bold",
  backgroundColor: "transparent",
  color: theme.palette.primary.main,
  borderColor: theme.palette.divider,
  cursor: "pointer",
  transition: "box-shadow 0.3s, background 0.3s, color 0.3s",

  "&:focus": {
    outline: "none"
  },

  "&:hover": {
    boxShadow: `0 0 10px ${theme.palette.primary.main}`
  },

  "&:active": {
    background: theme.palette.primary.main,
    color: theme.palette.common.white
  }
}));

const StyledTextArea = styled("textarea")(({ theme }) => ({
  width: "100%",
  padding: "0.5rem",
  margin: "1rem 0",
  border: "1px solid #ccc",
  borderRadius: "4px",
  fontSize: "0.9rem",
  fontFamily: "TheUFOLabFont",
  fontWeight: "normal",
  backgroundColor: "transparent",
  color: theme.palette.primary.main,
  borderColor: theme.palette.divider,
  cursor: "pointer",
  transition: "box-shadow 0.3s, background 0.3s, color 0.3s",
  resize: "none",

  "&:focus": {
    outline: "none"
  },

  "&:hover": {
    boxShadow: `0 0 10px ${theme.palette.primary.main}`
  },

  "&:active": {
    background: theme.palette.primary.main,
    color: theme.palette.common.white
  }
}));

const Actions = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  gap: theme.spacing(2),
  padding: theme.spacing(4, 6),
  boxSizing: "border-box"
}));

const Paragraph = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  fontSize: "1.2rem",
  textAlign: "center"
}));

const Body = styled(Box)(({ theme }) => ({
  display: "flex-column",
  justifyContent: "center",
  padding: theme.spacing(12, 6),
  boxSizing: "border-box"
}));

const Email = styled(Box)(({ theme }) => ({
  padding: "0.5rem",
  margin: "1rem 0",
  border: "1px solid #ccc",
  borderRadius: "4px",
  fontSize: "1.1rem",
  fontFamily: "TheUFOLabFont",
  textAlign: "center",
  backgroundColor: "transparent",
  color: theme.palette.grey[400],
  borderColor: theme.palette.divider,
  boxSizing: "border-box"
}));

// TODO. Editor context is not available here.
// Need to check with generouted.
export default function GamePublishModal() {
  const { uuid, version } = useParams("/dashboard/projects/:uuid/v/:version");
  const tm = useTagManager();

  const { t } = useTranslation();
  const { gameManager, loggedInUser } = useBackend();
  const { notify, confirm, featureFlags } = useAppContext();
  const modals = useModals();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<"publishing" | "unpublishing" | "creating" | undefined>();
  const [game, setGame] = useState<GameDefinitionDto>();
  const [gameData, setGameData] = useState<GameDataInterface>();
  const [successVersion, setSuccessVersion] = useState<string | undefined>();

  const [iframeScale, setIframeScale] = useState<number>(1);

  const [scormStatus, setScormStatus] = useState<"processing" | "success" | "fail" | undefined>(undefined);

  useEffect(() => {
    // Fetch game data
    gameManager.get({ path: "/:uuid", params: { uuid } }).then(
      (game) => {
        setGame(game);
        return gameManager
          .get({ path: "/:uuid/rev/:version", params: { uuid, version: game.version.toString() } })
          .then((data) => setGameData(data));
      },
      () => {
        notify(t("common.error"), "error");
        modals.close();
      }
    );
  }, [gameManager, modals, notify, t, uuid]);

  const handleClose = useCallback(() => {
    if (successVersion) {
      if (successVersion === version) {
        // reload
        modals.close();
        navigate(0);
      } else {
        navigate("/dashboard/projects/:uuid/v/:version/editor", {
          params: { uuid, version: successVersion }
        });
      }
    } else {
      modals.close();
    }
  }, [modals, navigate, successVersion, uuid, version]);

  const handleCreateRevision = useCallback(async () => {
    setLoading("creating");
    const revision = await gameManager.post({ path: "/:uuid/rev", params: { uuid } }, { from: version });
    modals.close();

    tm("game_create_revision", {
      game_uuid: uuid,
      from_game_version: version
    });

    navigate("/dashboard/projects/:uuid/v/:version/editor", {
      params: { uuid, version: revision.version.toString() }
    });
  }, [gameManager, modals, navigate, tm, uuid, version]);

  const handleUrlCopy = useCallback(
    async (event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      event.preventDefault();

      tm("game_share_url_copy", {
        game_uuid: uuid,
        game_version: version
      });

      // Copy to clipboard
      try {
        await navigator.clipboard.writeText(event.currentTarget.value);
        notify(t("common.urlCopied"), "success");
      } catch (e) {
        // probably unsupported
        event.currentTarget.select();
      }
    },
    [notify, t, tm, uuid, version]
  );

  const handleIframeCopy = useCallback(
    async (event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      event.preventDefault();

      tm("game_share_iframe_copy", {
        game_uuid: uuid,
        game_version: version
      });

      // Copy to clipboard
      try {
        await navigator.clipboard.writeText(event.currentTarget.value);
        notify(t("common.iframeCopied"), "success");
      } catch (e) {
        // probably unsupported
        event.currentTarget.select();
      }
    },
    [notify, t, tm, uuid, version]
  );

  const handlePublish = useCallback(async () => {
    setLoading("publishing");

    try {
      // Bump up version
      await gameManager.post({ path: "/:uuid/rev/:version/publish", params: { uuid, version } }, {});

      // Set public
      const newGame = await gameManager.patch({ path: "/:uuid", params: { uuid } }, { status: "published" });
      setSuccessVersion(newGame.version.toString());

      tm("game_publish", {
        game_uuid: uuid,
        game_version: version
      });

      notify(t("publish.publishSuccess"), "success");
    } catch (error) {
      notify(error instanceof Error ? error.message : t("common.error"), "error");
    } finally {
      setLoading(undefined);
    }
  }, [gameManager, notify, t, tm, uuid, version]);

  const handleUnpublish = useCallback(() => {
    confirm({ title: t("publish.unpublish"), content: t("publish.unpublishConfirm", { name: game?.name }) }).then(
      async () => {
        setLoading("unpublishing");

        try {
          await gameManager.patch({ path: "/:uuid", params: { uuid } }, { status: "draft" });

          tm("game_unpublish", {
            game_uuid: uuid,
            game_version: version
          });

          notify(t("publish.unpublishSuccess"), "success");
          modals.close();
          navigate(0);
        } catch (error) {
          notify(error instanceof Error ? error.message : t("common.error"), "error");
        } finally {
          setLoading(undefined);
        }
      }
    );
  }, [confirm, game?.name, gameManager, modals, navigate, notify, t, tm, uuid, version]);

  const exportScorm = useCallback(async () => {
    setScormStatus("processing");

    try {
      await gameManager.post({ path: "/:uuid/export/scorm", params: { uuid } }, { from: version });
      setScormStatus("success");

      tm("game_export_scorm", {
        game_uuid: uuid,
        game_version: version
      });
    } catch (error) {
      setScormStatus("fail");
      if (error instanceof Error) notify(error.message, "error");
      else notify(t("common.error"), "error");
      setScormStatus(undefined);
    }
  }, [gameManager, notify, t, tm, uuid, version]);

  const publicUrl = import.meta.env.VITE_PLAYER_URL.replace(":uuid", uuid);
  const iframeUrl = `${publicUrl}?iframe=true`;

  const Iframe = useMemo(
    () => `<iframe
      width="${gameData?.screen?.width ? Math.round(gameData.screen.width * iframeScale) : "100%"}"
      height="${gameData?.screen?.height ? Math.round(gameData.screen.height * iframeScale) : "100%"}"
      src="${iframeUrl}"
      allow="autoplay; fullscreen"
      allowFullScreen
      style={{ border: 'none', max-width: '100%' }}
      title="${game?.name || "UFOLab"}"
  ></iframe>`,
    [game?.name, gameData?.screen?.height, gameData?.screen?.width, iframeScale, iframeUrl]
  );

  if (!game) {
    return <FullScreenLoading />;
  }

  const isPublic = game.status === "published";
  const isCurrent = game.version === Number(version);
  const isArchived = Number(version) < game.version;
  const canBePublished = (!isCurrent || !isPublic) && !isArchived;

  return (
    <MultiSectionModal open onClose={handleClose} sx={{ maxWidth: "600px" }}>
      <ModalSection
        id='link'
        title={isPublic ? t("publish.link") : t("publish.modalTitle")}
        icon={isPublic ? <LinkIcon /> : <PublishIcon />}
        withSideTabs
      >
        {!successVersion && !canBePublished && (
          <>
            <Body>
              <Paragraph mt={2} mb={2}>
                <img src={UfitoURL} alt='Ufito' height={140} />
              </Paragraph>

              <Paragraph>
                <strong>{t("publish.alreadyPublished", { name: game.name })}</strong>
              </Paragraph>
              <Paragraph>{t("publish.shareMessage")}</Paragraph>
              <StyledInput type='text' value={publicUrl} readOnly onClick={handleUrlCopy} />
            </Body>

            <Divider />

            <Actions>
              <Button onClick={handleClose} disabled={!!loading}>
                {t("common.close")}
              </Button>
              {isPublic && (
                <SimpleLink to={publicUrl} target='_blank'>
                  <Button disabled={!!loading}>{t("common.visit")}</Button>
                </SimpleLink>
              )}
              <FeatureFlag feature='editor-settings-revisions'>
                <Button
                  onClick={handleCreateRevision}
                  variant='contained'
                  loading={loading === "creating"}
                  disabled={!!loading}
                >
                  {t("publish.createRevision")}
                </Button>
              </FeatureFlag>
              {isPublic && (
                <Button
                  onClick={handleUnpublish}
                  variant='contained'
                  color='error'
                  loading={loading === "unpublishing"}
                  disabled={!!loading && loading !== "unpublishing"}
                >
                  {t("publish.unpublish")}
                </Button>
              )}
            </Actions>
          </>
        )}

        {!successVersion && canBePublished && (
          <>
            <Body>
              <Paragraph mt={2} mb={2}>
                <img src={UfitoURL} alt='Ufito' height={140} />
              </Paragraph>

              {isPublic && (
                <>
                  <Paragraph>{t("publish.currentlyPublished", { name: game.name })}</Paragraph>
                  <StyledInput type='text' value={publicUrl} readOnly onClick={handleUrlCopy} />
                </>
              )}
              <Paragraph>
                <strong>
                  {t(!isPublic ? "publish.publishFirstText" : "publish.publishText", { name: game.name })}
                </strong>
              </Paragraph>

              {!isPublic && <Paragraph>{t("publish.publishWarning")}</Paragraph>}
            </Body>

            <Divider />
            <Actions>
              <Button onClick={handleClose} disabled={!!loading}>
                {t("common.cancel")}
              </Button>
              <Button
                onClick={handlePublish}
                variant='contained'
                loading={loading === "publishing"}
                disabled={!!loading && loading !== "publishing"}
              >
                {t(!isPublic ? "publish.doPublish" : "publish.updatePublish")}
              </Button>
              {isPublic && (
                <Button
                  onClick={handleUnpublish}
                  variant='contained'
                  color='error'
                  loading={loading === "unpublishing"}
                  disabled={!!loading && loading !== "unpublishing"}
                >
                  {t("publish.unpublish")}
                </Button>
              )}
            </Actions>
          </>
        )}

        {successVersion && (
          <>
            <Body>
              <Paragraph mt={2} mb={2}>
                <img src={UfitoURL} alt='Ufito' height={140} />
              </Paragraph>

              <Paragraph>
                <strong>{t("publish.successMessage", { name: game.name })}</strong>
              </Paragraph>
              <Paragraph>{t("publish.shareMessage")}</Paragraph>
              <StyledInput type='text' value={publicUrl} readOnly onClick={handleUrlCopy} />
            </Body>

            <Divider />
            <Actions>
              <Button onClick={handleClose} disabled={!!loading}>
                {t("common.accept")}
              </Button>
              <SimpleLink to={publicUrl} target='_blank'>
                <Button disabled={!!loading}>{t("common.visit")}</Button>
              </SimpleLink>
              <FeatureFlag feature='editor-settings-revisions'>
                <Button
                  onClick={handleCreateRevision}
                  variant='contained'
                  loading={loading === "creating"}
                  disabled={!!loading && loading !== "creating"}
                >
                  {t("publish.createRevision")}
                </Button>
              </FeatureFlag>
            </Actions>
          </>
        )}
      </ModalSection>

      {isPublic ? (
        <ModalSection
          id='iframe'
          title={"Iframe"}
          icon={<CodeOutlinedIcon />}
          withSideTabs
          hidden={!featureFlags["game-export-iframe"]}
          feature='game-export-iframe'
        >
          <Body>
            <Paragraph mt={2} mb={2}>
              <img src={UfitoIframe} alt='Ufito' height={140} />
            </Paragraph>

            <Paragraph>
              <strong>{t("publish.copyIframeTitle")}</strong>
            </Paragraph>
            <Paragraph>{t("publish.copyIframeText", { name: game.name })}</Paragraph>

            <StyledTextArea rows={Iframe.split("\n").length + 1} value={Iframe} readOnly onClick={handleIframeCopy} />

            <Paragraph>{t("publish.iframeAdjustSize")}</Paragraph>

            <Box sx={{ width: "80%", margin: "0 auto" }}>
              <Slider
                value={iframeScale}
                onChange={(_, value) => setIframeScale(Array.isArray(value) ? value[0] : value)}
                min={0.33}
                max={1}
                step={0.01}
                marks={iframeSizeMarks}
              />
            </Box>
          </Body>

          <Divider />
          <Actions>
            <Button onClick={handleClose} disabled={!!loading}>
              {t("common.close")}
            </Button>
          </Actions>
        </ModalSection>
      ) : null}

      <ModalSection
        id='scorm'
        title={t("publish.scormTitle")}
        icon={<LaptopChromebookOutlinedIcon />}
        withSideTabs
        hidden={!featureFlags["game-export-scorm"]}
        feature='game-export-scorm'
      >
        <Body>
          <Paragraph mt={2} mb={2}>
            <img src={UfitoScorm} alt='Ufito' height={140} />
          </Paragraph>

          {scormStatus === "success" && (
            <>
              <Paragraph>{t("publish.successScorm")}</Paragraph>
            </>
          )}

          {scormStatus === "fail" && (
            <Paragraph>
              <strong>{t("publish.failScorm")}</strong>
            </Paragraph>
          )}

          {scormStatus === "processing" && (
            <Paragraph>
              <strong>{t("publish.generatingScorm")}</strong>
            </Paragraph>
          )}

          {scormStatus === undefined && (
            <>
              <Paragraph>
                <strong>{t("publish.generateScorm", { name: game.name })}</strong>
              </Paragraph>
              <Paragraph>
                <strong>{t("publish.generateScormText")}</strong>
              </Paragraph>
            </>
          )}

          <Email>{loggedInUser?.email}</Email>
        </Body>

        <Divider />
        <Actions>
          {scormStatus !== "success" ? (
            <>
              <Button onClick={handleClose} disabled={!!loading || scormStatus === "processing"}>
                {t("common.cancel")}
              </Button>
              <Button
                variant='contained'
                onClick={exportScorm}
                loading={scormStatus === "processing"}
                feature='game-export-scorm'
              >
                {t("publish.doGenerateScorm")}
              </Button>
            </>
          ) : (
            <Button variant='contained' onClick={handleClose}>
              {t("common.close")}
            </Button>
          )}
        </Actions>
      </ModalSection>
    </MultiSectionModal>
  );
}
