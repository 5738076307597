import { Emitter } from "strict-event-emitter";
import isEqual from "lodash/isEqual";
import dayjs from "dayjs";

import { Logger, createLogger } from "@shared/utils/logging";

import {
  GameElementCategory,
  GameDataInterface,
  GameElement,
  GameElementRendered,
  GameLevelRendered,
  GameLevelUpdates,
  GameLog,
  GameLogEntry,
  GameScoreType,
  GameStateInterface,
  GameOverlayRendered,
  GameStateRendered,
} from "./types";

import { GameEventType } from "./events";
import { GameError } from "./errors";
import { GameCommand, GameErrorCode } from "./consts";
import { getActions, hasLegacyActions } from "./helpers";

import { GameState } from "./GameState";
import { GameData } from "./GameData";
import { GameRenderer } from "./GameRenderer";
import { evaluateExpression } from "./helpers/expression";
import { GameAction, GameActionTrigger } from "./types/GameDataInterface";

export interface GameEngineOptions {
  initialState?: Partial<GameStateInterface>;
  initialLog?: GameLogEntry[];
  libraryBaseUrl?: string;
  assetBaseUrl?: string;
  pluginBaseUrl?: string;

  // optional
  userData?: Record<string, unknown>;
  logger?: Logger;
  renderer?: GameRenderer;
  strictMode?: boolean;

  // callbacks
  // When the action requres to be prepared before from the frontend
  onPrebakeAction?: (action: GameAction) => string | undefined;
  // When an prebakced action is cancelleds
  onCancelAction?: (action: GameAction) => void;
}

/**
 * The game engine. It contains all the logic to run a game
 *
 * @export
 * @class GameEngine
 * @extends {Emitter<GameEventType>}
 * @implements {GameEngineInterface}
 */
export class GameEngine extends Emitter<GameEventType> {
  protected readonly gameData: GameData;
  protected readonly gameLog: GameLog;
  protected readonly gameState: GameState;
  protected readonly gameRenderer: GameRenderer;
  protected readonly pendingActionsQueue: GameAction[] = [];
  protected readonly pendingUpdatesQueue: GameLevelUpdates[] = [];

  protected readonly strictMode: boolean;
  protected readonly logger: Logger;
  protected readonly userData: Record<string, unknown>;

  protected readonly onPrebakeAction?: (action: GameAction) => void;
  protected readonly onCancelAction?: (action: GameAction) => void;

  /**
   * Creates an instance of GameEngine.
   *
   * @param gameData The game data
   * @param options Optional options
   */
  constructor(gameData: GameDataInterface, options: GameEngineOptions = {}) {
    super();

    this.gameData = new GameData(gameData);
    this.gameState = new GameState(this.gameData, {
      ...(options.initialState || {}),
    });
    this.gameLog = options.initialLog || [];
    this.gameRenderer =
      options.renderer ||
      new GameRenderer(this.gameData, this.gameState, this.gameLog, {
        libraryBaseUrl: options.libraryBaseUrl,
        assetBaseUrl: options.assetBaseUrl,
        pluginBaseUrl: options.pluginBaseUrl,
      });

    this.strictMode = options.strictMode || false;
    this.userData = options.userData || {};
    this.onPrebakeAction = options.onPrebakeAction;
    this.onCancelAction = options.onCancelAction;

    this.logger =
      options.logger ||
      createLogger("GameEngine", {
        subtags: [this.gameState.uuid],
      });

    this.logger.info("Game engine created", this.gameState.uuid);

    this.initialize();
  }

  /**
   * Initialize the game engine
   */
  private initialize(): void {
    // If the game is not initialized, set the initial level
    if (!this.gameState.currentLevel) {
      if (this.gameData.gameflow?.initialLevelId) {
        this.changeLevel(this.gameData.gameflow.initialLevelId);
      } else {
        this.logger.error("No initial level found");
      }
    }

    // Initialize clock, run actions, etc.
    this.postLevelChangeChecks(this.gameState.currentLevel);

    // Initial state update
    this.notifyGameStateUpdate();
  }

  /**
   * Check if a condition is met
   *
   * @param condition The condition
   * @returns If the condition is met or not
   */
  private checkCondition(condition: string): boolean {
    try {
      const vars = this.gameState.toExpressionVars();
      this.logger.debug("Checking condition", condition, vars);

      const result = !!evaluateExpression(condition, vars);

      this.logger.debug("Condition result", result);

      return result;
    } catch (error) {
      this.logger.error("Error while checking condition", condition, error);
      return false;
    }
  }

  /**
   * Get the actual current level, considering the game status and the game flow
   *
   * @returns the current level ID
   */
  getActualCurrentLevelId(): string {
    if (this.gameState.isExpired()) {
      return this.gameData.gameflow.gameOverLevelId;
    }

    return this.gameState.currentLevel || this.gameData.gameflow.initialLevelId;
  }

  /**
   * Get the actual current overlay, considering the game status and the game flow
   *
   * @returns
   */
  getActualCurrentOverlayId(): string | undefined {
    const currentLevel = this.getActualCurrentLevelId();
    return this.gameData.getOverlayByLevelId(currentLevel);
  }

  /**
   * Add custom score to the game state
   *
   * @param score The score to add. It must be negative for penalties
   * @param isPenalty If the score should be considered a penalty. Score will be subtracted if true
   * @returns The new score
   */
  private addCustomScore(score: number, isPenalty = false): number {
    const scoreToAdd = score * (isPenalty ? -1 : 1);

    this.gameState.score = Math.max(this.gameState.score + scoreToAdd, 0);
    this.emit("gameScoreUpdated", this.getState(), scoreToAdd);

    if (isPenalty) {
      this.gameState.penalties++;
      this.emit(
        "gamePenaltiesUpdated",
        this.getState(),
        this.gameState.penalties
      );
    }

    return this.gameState.score;
  }

  /**
   * Add a pre-defined score to the game state
   *
   * @param type The score type
   * @param multiplier Tell how many times the score should be added / subtracted
   * @param isPenalty If the score should be considered a penalty. Score will be subtracted if true
   * @returns The new score
   */
  private addScore(
    type: GameScoreType,
    multiplier = 1,
    isPenalty = false
  ): { score: number; total: number } {
    const score = this.gameData.scoring?.[type] || 0;
    const total = this.addCustomScore(score * multiplier, isPenalty);

    return { score, total };
  }

  private setGameVariable(varName: string, expression: string): boolean {
    try {
      const vars = this.gameState.toExpressionVars();
      const newVars: Record<string, string | number | boolean> = {
        [varName]: evaluateExpression(expression, vars),
      };

      this.gameState.variables = {
        ...this.gameState.variables,
        ...newVars,
      };

      this.emit("gameVariablesUpdated", this.getState(), newVars);
      return true;
    } catch (error) {
      this.logger.error(
        "Invalid expression",
        JSON.stringify(expression),
        ":",
        error
      );
      return false;
    }
  }

  /**
   * Execute a user command
   *
   * @param action The GameAction object
   * @param customData Custom data to be used instead of the payload data
   * @returns If a command was executed or not
   */
  private executeAction(action: GameAction, customData?: string): boolean {
    const { command, payload: commandData } = action;
    const data = customData || commandData;

    switch (command) {
      case GameCommand.GO_TO_LEVEL:
        return data ? this.changeLevel(data) : false;

      case GameCommand.ADD_SCORE:
        this.addCustomScore(Number(data));
        return true;

      case GameCommand.ADD_PENALTY:
        this.addCustomScore(Number(data), true);
        return true;

      case GameCommand.COLLECT_KEY:
        return data ? this.collectKey(data) : false;

      case GameCommand.COLLECT_ITEM:
        return data ? this.collectItem(data) : false;

      case GameCommand.CONSUME_CLUE:
        return data ? this.consumeClue(data) : false;

      case GameCommand.SET_VAR: {
        if (!data) {
          return false;
        }

        // parse `foo=expression`
        const expressionParts = data
          .trim()
          .match(/^([a-z0-9_\-.]+)\s*=\s*(.*)/i);

        if (!expressionParts) {
          this.maybeThrow(
            new GameError(
              GameErrorCode.INVALID_EXPRESSION,
              "The expression seems to be not correctly formatted",
              undefined,
              this.gameState
            )
          );
          return false;
        }

        return this.setGameVariable(expressionParts[1], expressionParts[2]);
      }

      case GameCommand.VISIT_URL: {
        const windowId = this.onPrebakeAction?.(action);

        // Modify the payload to include the windowId
        if (windowId) {
          action.payload = `${windowId}|${action.payload}`;
        }

        this.enqueueAction(action);
        break;
      }

      default:
        this.enqueueAction(action);
    }

    return false;
  }

  /**
   * Enqueue an action to be executed by the frontend
   *
   * @param action
   */
  private enqueueAction(action: GameAction): void {
    // Queue the action for later execution (by Frontend)
    this.pendingActionsQueue.push(action);
    this.logger.info("Command queued", action.command);
    this.emit(
      "gameActionEnqueued",
      this.getState(),
      action.command,
      action.payload
    );
  }

  /**
   * Get the pending actions and clear the queue
   *
   * @param commands
   */
  private executeActions(actions: GameAction[]): void {
    actions.forEach((action) => this.executeAction(action));
  }

  /**
   * Compare the user answer with the expected answer
   *
   * @param userAnswer
   * @param expectedAnswer
   * @returns
   */
  private checkSolution(userAnswer: unknown, expectedAnswer: unknown): boolean {
    if (typeof userAnswer !== typeof expectedAnswer) {
      this.maybeThrow(
        new GameError(
          GameErrorCode.UNEXPECTED_ANSWER_TYPE,
          "The expected answer has not the right type",
          undefined,
          this.gameState
        )
      );

      return false;
    }

    // Performs a deep comparison between two values to determine if they are equivalent.
    // This method supports comparing arrays, array buffers, boolean, date objects, maps,
    // numbers, objects, regex, sets, strings, symbols, and typed arrays.
    const result = isEqual(userAnswer, expectedAnswer);

    if (!result) {
      this.logger.debug(
        "Answer mismatch (user/expected): ",
        userAnswer,
        expectedAnswer
      );
    }

    return result;
  }

  /**
   * Try to unlock a lock and apply the score or penalties accordingly
   * to the response of the user. It will emit the corresponding events
   * and update the game state.
   *
   * If the lock is already unlocked, it will return false,
   * zero or more answers are valid, and the first one that matches will unlock the lock.
   *
   * @param element The game element
   * @param solution The user's answer
   * @returns If the lock was unlocked or not
   */
  private unlock(element: GameElement, solution: unknown): boolean {
    if (!element.lockId) {
      this.maybeThrow(
        new GameError(
          GameErrorCode.GAME_BAD_DEFINITION,
          "The lockId is missing in the lock component",
          undefined,
          this.gameState
        )
      );

      return false;
    }

    if (this.gameState.hasKey(element.lockId)) {
      return true;
    }

    // Any answer
    const matchingAnswer = element.solutions?.find((validSolution) =>
      this.checkSolution(solution, validSolution)
    );

    if (!matchingAnswer) {
      this.emit("gameUnlockFailed", this.getState(), element.lockId, solution);
      this.addScore("unlockFailed", 1, true);
      return false;
    }

    this.emit("gameUnlockSuccess", this.getState(), element.lockId, solution);

    this.gameState.pushKey(element.lockId);
    this.emit("gameKeysUpdated", this.getState(), [element.lockId]);

    this.addScore("unlockSuccess");

    return true;
  }

  /**
   * Record a game log entry
   *
   * @param action
   * @param target
   * @param payload
   * @param note
   */
  private logEntry(
    action: GameCommand | GameCommand,
    target?: string,
    payload?: string,
    note?: string
  ): void {
    const log: GameLogEntry = {
      timestamp: Date.now(),
      level: this.getActualCurrentLevelId(),
      score: this.gameState.score,
      action,
      target,
      payload,
      note,
    };

    this.gameLog.push(log);
    this.logger.debug("Log entry", log);
    this.emit("gameLogUpdated", this.getState(), log);
  }

  /**
   * PUBLIC METHODS
   */

  /**
   * Consume a clue and applies the configured score
   *
   * @param clueId The clue id
   * @returns If the clue was consumed or not (already consumed)
   */
  private consumeClue(clueId: string): boolean {
    if (!this.gameState.pushClue(clueId)) {
      return false;
    }

    this.emit("gameCluesUpdated", this.getState(), [clueId]);
    this.addScore("requestClue"); // Not a penalty!
    this.logEntry(GameCommand.REQUEST_CLUE, clueId);

    return true;
  }

  /**
   * Collect a key (unlock)
   *
   * @param key The key ID
   * @returns If the key was collected or not (already collected)
   */
  private collectKey(key: string): boolean {
    if (!this.gameState.pushKey(key)) {
      return false;
    }

    this.emit("gameKeysUpdated", this.getState(), [key]);
    this.logEntry(GameCommand.COLLECT_KEY, key);
    this.logger.debug("Key collected", key);

    return true;
  }

  /**
   * Collect an item and applies the configured score
   *
   * @param item The item ID
   * @returns If the item was collected or not (already collected)
   */
  private collectItem(item: string): boolean {
    if (!this.gameState.pushItem(item)) {
      return false;
    }

    this.emit("gameInventoryUpdated", this.getState(), [item]);
    this.addScore("collectItem");
    this.logEntry(GameCommand.COLLECT_ITEM, item);
    this.logger.debug("Item collected", item);

    return true;
  }

  /**
   * Set the current level
   *
   * @param levelId The new level ID
   * @returns if the level has been updated or not (already in that level)
   */
  private changeLevel(levelId: string): boolean {
    if (this.gameState.currentLevel === levelId) {
      return false;
    }

    // Sanity checks

    if (!this.gameData.getAllLevelIds().includes(levelId)) {
      this.logEntry(GameCommand.ERROR, levelId, "", "Level not found");

      this.maybeThrow(
        new GameError(
          GameErrorCode.LEVEL_NOT_FOUND,
          `The level ${levelId} does not exist`,
          undefined,
          this.gameState
        )
      );

      return false;
    }

    if (
      !this.gameState.currentLevel &&
      this.gameData.gameflow.initialLevelId !== levelId
    ) {
      this.logEntry(GameCommand.ERROR, levelId, "", "Access denied");
      this.logger.error("Level access denied", levelId, "from initial level");

      this.maybeThrow(
        new GameError(
          GameErrorCode.LEVEL_ACCESS_DENIED,
          "Expected the initial level",
          undefined,
          this.gameState
        )
      );

      return false;
    }

    // TODO: Check if the current level is connected to the target level

    // Pre level change actions
    this.preLevelChangeChecks(this.gameState.currentLevel);

    // Actual update.
    this.gameState.currentLevel = levelId;
    this.gameState.pushVisitedLevel(levelId);

    this.emit("gameLevelChanged", this.getState(), levelId);
    this.logEntry(GameCommand.GO_TO_LEVEL, levelId);
    this.logger.debug("Level changed", levelId);

    this.postLevelChangeChecks(levelId);

    return true;
  }

  /**
   * Run triggers pre-level change
   *
   * @returns The pending actions
   */
  private preLevelChangeChecks(levelId: string): void {
    // Clear the pending actions
    this.getPendingActions();

    // Trigger "leave" actions
    const levelData = this.gameData.levels[levelId];
    if (levelData) {
      const actions = getActions(levelData, ["leave"]);
      this.executeActions(actions);
    }
  }

  /**
   * Run triggers post-level change
   *
   * @param levelId
   */
  private postLevelChangeChecks(levelId: string): void {
    // Post level change actions
    if (this.gameData.isStartCounterLevel(levelId)) {
      this.startCounter();
    }

    if (this.gameData.isWinLevel(levelId)) {
      this.winGame();
    } else if (this.gameData.isGameOverLevel(levelId)) {
      this.gameOver();
    }

    // Trigger "enter" actions
    const levelData = this.gameData.levels[levelId];
    if (levelData) {
      const actions = getActions(levelData, ["enter"]);
      this.executeActions(actions);
    }
  }

  /**
   * Start the game clock
   *
   * @param startedAt The date when the clock started (default: now)
   * @returns if the clock has been started or not (already started)
   */
  private startCounter(startedAt: Date = new Date()): boolean {
    if (this.gameState.startedAt) {
      return false;
    }

    const duration = this.gameData.timer?.duration || 0;

    if (!duration) {
      this.logger.warn("No duration set for the game clock");
      return false;
    }

    this.gameState.startedAt = startedAt;
    this.gameState.expiresAt =
      duration > 0
        ? dayjs(startedAt).add(duration, "minute").toDate()
        : undefined;

    this.emit("gameClockStarted", this.getState(), startedAt);
    this.logEntry(GameCommand.INIT_TIMER, startedAt.toISOString());
    this.logger.debug("Game clock started", startedAt);

    return true;
  }

  /**
   * Completes the game, when the user reaches the last level
   *
   * @param completedAt
   * @returns if the game has been completed or not (already completed)
   */
  winGame(completedAt: Date = new Date()): boolean {
    if (!this.gameState.isInProgress()) {
      return false;
    }

    this.gameState.completedAt = completedAt;

    // Scoring
    const expiresAt = this.gameState.expiresAt;
    const secondsLeft = expiresAt
      ? Math.max(0, dayjs(expiresAt).diff(completedAt, "second"))
      : 0;

    this.addScore("endSecondsLeft", secondsLeft);
    const score = this.addScore("completeGame");

    this.emit("gameWin", this.getState(), score.total, secondsLeft);
    this.emit(
      "gameFinished",
      this.getState(),
      completedAt,
      score.total,
      secondsLeft
    );
    this.logEntry(GameCommand.FINISH_GAME);
    this.logger.debug("Game completed", completedAt);

    return true;
  }

  /**
   * Perform time based checks and update the game status
   *
   * @returns
   */
  checkTimeBasedConditions(): boolean {
    // Jump to game over level
    if (
      this.gameState.isExpired() &&
      this.gameData.gameflow.gameOverLevelId &&
      this.gameState.currentLevel !== this.gameData.gameflow.gameOverLevelId
    ) {
      this.logEntry(GameCommand.EXPIRED);
      this.logger.debug("Game expired");
      this.emit("gameExpired", this.getState());

      this.changeLevel(this.gameData.gameflow.gameOverLevelId);
      // Notify the update, to request the level to be re-rendered
      this.notifyGameStateUpdate();

      return true;
    }

    return false;
  }

  /**
   * Completes the game, when the user reaches the game over level
   *
   * @param completedAt
   * @returns if the game has been completed or not (already completed)
   */
  gameOver(completedAt: Date = new Date()): boolean {
    if (!this.gameState.isInProgress()) {
      return false;
    }

    const expiresAt = this.gameState.expiresAt;
    const secondsLeft = expiresAt
      ? Math.max(0, dayjs(expiresAt).diff(completedAt, "second"))
      : 0;
    const score = this.gameState.score;

    this.emit("gameOver", this.getState(), score, secondsLeft);
    this.emit("gameFinished", this.getState(), completedAt, score, secondsLeft);
    this.logEntry(GameCommand.FINISH_GAME);
    this.logger.debug("Game over", completedAt);

    return true;
  }

  private notifyGameStateUpdate(): void {
    this.emit("gameStateUpdated", this.getState());
  }

  /**
   * Render the level and get updatable elements and their statuses
   * TODO: Optimize this method so it actually returns only the updates
   *
   * @returns The level updates
   */
  private getAllUpdates(): GameLevelUpdates {
    // Level updates in the format:
    // { [id: string]: { ...properties } }
    const { layout: levelLayout } = this.renderLevel();
    const { layout: overlayLayout } = this.renderOverlay();

    const fullLayout = [...levelLayout, ...overlayLayout];

    const getUpdates = (layout: GameElementRendered[]): GameLevelUpdates => {
      const updates: GameLevelUpdates = {};

      layout.forEach((element) => {
        // filter only stuff that allows changes. Elements with conditions + some extras
        if (
          element.update !== "always" &&
          !element.condition &&
          ![GameElementCategory.LOCK].includes(element.type)
        ) {
          return;
        }

        const { id, solved, failed, condition, translation, layout } = element;
        const enabled = condition ? this.checkCondition(condition) : true;

        updates[id] = {
          // Only updatable properties here
          enabled,
          solved,
          failed,
          translation,
        };

        if (layout && layout.length > 0) {
          Object.assign(updates, getUpdates(layout));
        }
      });

      return updates;
    };

    return getUpdates(fullLayout);
  }

  /**
   * Custom event emitter to emit all events
   */
  public emit<EventName extends keyof GameEventType>(
    event: EventName,
    ...args: GameEventType[EventName]
  ): boolean {
    const eventData = args.reduce<Record<string, unknown>>(
      (acc, arg, index) => ({ ...acc, [`arg${index}`]: arg }),
      {}
    );

    // event for all events
    super.emit("*", event, {
      game_state_uuid: this.getState().uuid,
      ...eventData,
    });

    return super.emit(event, ...args);
  }

  /**
   * Trigger an element and execute the actions.
   *
   * TODO: This method is too long and should be refactored
   *
   * @param elementId
   * @param payload
   * @returns
   */
  triggerElement(
    elementId: string,
    trigger: GameActionTrigger = "legacy",
    payload?: string
  ): boolean {
    const currentLevel = this.getActualCurrentLevelId();

    const element = this.gameData.findElementById(
      currentLevel,
      elementId,
      this.gameState.uuid
    );

    if (!element) {
      this.logEntry(GameCommand.ERROR, elementId, payload, "Element not found");
      this.logger.error(
        "Element not found",
        elementId,
        "in level",
        currentLevel
      );

      return false;
    }

    const displayElementId = element._id || element.id;
    const isLegacyActions = hasLegacyActions(element);

    // Doesn't allow trigger legacy actions if the element has new actions
    // and vice versa
    if (
      (!isLegacyActions && trigger === "legacy") ||
      (isLegacyActions && trigger !== "legacy")
    ) {
      this.logger.debug(
        "Legacy / non-legacy actions mismatch. Ignoring",
        elementId
      );
      return false;
    }

    // Check if the object is actually accessible. If not, interrupt the function
    if (element.condition) {
      if (!this.checkCondition(element.condition)) {
        this.emit(
          "gameActionTriggered",
          this.getState(),
          GameCommand.TRIGGER_ELEMENT,
          displayElementId,
          payload || "",
          false
        );

        this.logEntry(
          GameCommand.ERROR,
          elementId,
          payload,
          "Element not accessible"
        );

        this.logger.debug(
          "Element can't be triggered because of condition",
          elementId
        );

        return false;
      }
    }

    // record the trigger event
    this.emit(
      "gameActionTriggered",
      this.getState(),
      GameCommand.TRIGGER_ELEMENT,
      displayElementId,
      payload || "",
      true
    );

    this.logEntry(GameCommand.TRIGGER_ELEMENT, elementId, payload);
    this.logger.debug("Element triggered", elementId);

    let overridenTrigger = trigger;
    let commandSuccess = false;

    switch (element.type) {
      // Collect the item.
      case GameElementCategory.ITEM: {
        if (!element.itemId) {
          this.maybeThrow(
            new GameError(
              GameErrorCode.GAME_BAD_DEFINITION,
              "The itemId is missing in the item component",
              undefined,
              this.gameState
            )
          );

          return false;
        }

        const collectResult = this.collectItem(element.itemId);

        if (!collectResult) {
          // already collected? Stop here
          return false;
        }

        break;
      }

      // Solve the lock. We want to trigger the main command only if the answer is correct
      case GameElementCategory.LOCK: {
        // Do not process other triggers
        if (
          !["solve", "fail", "success", "legacy"].includes(overridenTrigger)
        ) {
          break;
        }

        if (!element.lockId) {
          this.maybeThrow(
            new GameError(
              GameErrorCode.GAME_BAD_DEFINITION,
              "The lockId is missing in the lock component",
              undefined,
              this.gameState
            )
          );

          return false;
        }

        // Skip backend validation if no solutions are defined.
        // Useful for puzzles that checks the answer in the frontend
        const frontendValidation =
          element.solutions?.filter(Boolean).length === 0;

        const lockResult = frontendValidation
          ? trigger === "success"
          : this.unlock(element, payload || "");

        // Trigger the fail path.
        if (!lockResult) {
          this.emit(
            "gameUnlockFailed",
            this.getState(),
            element.lockId,
            payload
          );
          this.logEntry(GameCommand.RESOLVE_FAIL, element.lockId, payload);
          this.logger.debug("Lock failed", element.lockId, payload);

          // change the trigger to fail
          overridenTrigger = "fail";
          commandSuccess = true;
        } else {
          this.emit(
            "gameUnlockSuccess",
            this.getState(),
            element.lockId,
            payload
          );
          this.logEntry(GameCommand.RESOLVE_OK, element.lockId, payload);
          this.logger.debug("Lock succeeded", element.lockId, payload);

          // change the trigger to fail. keep legacy as "legacy"
          // because it behaves in a different way
          if (overridenTrigger !== "legacy") {
            overridenTrigger = "success";
          }

          this.collectKey(element.lockId);
        }

        break;
      }

      // Deprecated use case. To be removed
      case GameElementCategory.MINIGAME: {
        // If a a new trigger is set, use it
        if (overridenTrigger !== "legacy") {
          break;
        }

        // Legacy actions
        if (payload?.trim().match(/^true|win\b/i)) {
          // Valid keywords for success in minigames: true, win
          overridenTrigger = isLegacyActions ? "legacy" : "success";
        } else if (payload?.trim().match(/^false|lose|fail\b/i)) {
          overridenTrigger = "fail";
        } else {
          // Unknown game event. Ignore it
        }

        break;
      }
    }

    // Trigger actions
    const actions = getActions(element, [overridenTrigger]);
    this.executeActions(actions);

    // Notify the update, to request the level to be re-rendered
    this.notifyGameStateUpdate();

    return commandSuccess;
  }

  /**
   * Render the current level according to the game state
   *
   * @returns
   */
  renderLevel(): GameLevelRendered {
    const currentLevel = this.getActualCurrentLevelId();

    return this.gameRenderer.renderLevel(
      currentLevel,
      this.gameState.language,
      {
        user: this.userData,
        ...this.gameState.toExpressionVars(),
      }
    );
  }

  renderOverlay(): GameOverlayRendered {
    const currentLevel = this.getActualCurrentLevelId();
    const overlayId = this.gameData.getOverlayByLevelId(currentLevel);

    if (!overlayId) {
      return { layout: [], translations: {} };
    }

    return this.gameRenderer.renderOverlay(overlayId, this.gameState.language, {
      user: this.userData,
      ...this.gameState.toExpressionVars(),
    });
  }

  renderState(): GameStateRendered {
    return {
      ...this.gameState.toJSON(),
      totalKeys: this.gameData.getAllLockIds().length,
      totalItems: this.gameData.getAllItemIds().length,
      totalLevels: this.gameData.getAllLevelIds().length,
      progress: this.gameState.completion,
    };
  }

  /**
   * Get the current game state
   *
   * @returns The game state
   */
  getState(): GameStateInterface {
    return this.gameState.toJSON();
  }

  /**
   * Get the game log
   *
   * @returns
   */
  getLog(): GameLogEntry[] {
    return Array.from(this.gameLog);
  }

  /**
   * Get the pending commands to be executed by the frontend
   *
   * @returns The pending actions with their payloads
   */
  getPendingActions(): GameAction[] {
    // Pop all elements
    return this.pendingActionsQueue.splice(0, this.pendingActionsQueue.length);
  }

  /**
   * Get the pending updates to be applied by the frontend
   *
   * @returns The pending updates
   */
  getPendingUpdates(): GameLevelUpdates[] {
    // TODO optimize this method to return only updates
    return [this.getAllUpdates()];
  }

  /**
   * Destroy the game engine
   */
  destroy(): void {
    this.logger.info("Game engine destroyed", this.gameState.uuid);
    this.removeAllListeners();
  }

  /**
   * Helper method to throw an error if in strict mode
   *
   * @param error
   */
  maybeThrow(error: GameError): void {
    if (this.strictMode) {
      throw error;
    } else {
      this.logger.error(`${error.name} (${error.code}): ${error.message}`);
    }
  }
}
