import { useCallback, useMemo } from "react";
import { IconButton, Tooltip, useTheme } from "@mui/material";
import { withJsonFormsControlProps } from "@jsonforms/react";
import {
  ControlProps,
  RankedTester,
  isStringControl,
  rankWith,
} from "@jsonforms/core";

import { FieldWrapper } from "../common/FieldWrapper";
import { isCustomControl } from "../utils";
import { DropZone } from "../common/DropZone";
import { DesirableConfig } from "../config";
import { Icons } from "../common/Icons";

const stopPropagation = (e: React.MouseEvent | React.TouchEvent) => {
  e.stopPropagation();
};

interface Props extends ControlProps {
  data: string;
  path: string;
  handleChange: (path: string, value: string) => void;
}
export function AssetPickerRenderer({
  path,
  label,
  description,
  required,
  handleChange,
  data,
  visible,
  uischema,
  ...restProps
}: Props) {
  const theme = useTheme();

  const config = restProps.config as DesirableConfig;
  const pluginType = config.context?.manifest?.type;
  const pluginId = config.context?.manifest?.id;
  const mimeType = uischema.options?.mimeType;

  const url =
    data &&
    config.context?.editorContext?.renderAssetUrl(data, {
      pluginType,
      pluginId,
    });

  const handleOpenAssetPicker = useCallback(() => {
    config.context?.modals?.setAssetPickerOpen?.({
      multiple: false,
      onSubmit: (assets) => {
        config.context?.modals?.setAssetPickerOpen?.(undefined);
        assets[0] && handleChange(path, assets[0].path);
      },
      mimeType: { asset: [mimeType] },
    });
  }, [config.context?.modals, handleChange, mimeType, path]);

  const handleRemoveImage = useCallback(
    (
      e:
        | React.MouseEvent<HTMLButtonElement>
        | React.TouchEvent<HTMLButtonElement>
    ) => {
      e.stopPropagation();
      handleChange(path, "");
    },
    [handleChange, path]
  );

  const iconButtonStyles = useMemo(
    () => ({
      position: "absolute",
      top: "5px",
      right: "5px",
      margin: theme.spacing(1, 0),
      background: theme.palette.grey[300],
      "&:hover": {
        background: theme.palette.grey[400],
      },
    }),
    [theme]
  );

  if (!visible) {
    return null;
  }

  const isLibrarySource = data?.startsWith("library://");
  const isPluginSource = data?.startsWith("plugin://");

  const isImage = mimeType.startsWith("image/");
  const isAudio = mimeType.startsWith("audio/");
  const isVideo = mimeType.startsWith("video/");
  const isOther = !isImage && !isAudio && !isVideo;

  return (
    <FieldWrapper label={label} description={description} uiSchema={uischema}>
      <DropZone
        className={url ? "hasImage" : ""}
        onClick={handleOpenAssetPicker}
      >
        {url && (
          <>
            {isImage && <img src={url} />}
            {isAudio && <audio src={url} controls onClick={stopPropagation} />}
            {isVideo && <video src={url} controls onClick={stopPropagation} />}
            {isOther && (
              <>
                {Icons.file}
                <a href={url} target="_blank" rel="noreferrer">
                  Ver archivo {mimeType}
                </a>
              </>
            )}
            {!required && (
              <IconButton
                size="small"
                onClick={handleRemoveImage}
                sx={iconButtonStyles}
              >
                {Icons.trash}
              </IconButton>
            )}
          </>
        )}
        {!url && Icons.add}

        {isLibrarySource && (
          <Tooltip
            title="La imagen actual proviene de una librería y no es parte de los recursos del juego"
            placement="top"
          >
            <IconButton className="badge" size="small">
              {Icons.info}
            </IconButton>
          </Tooltip>
        )}
        {isPluginSource && (
          <Tooltip
            title="La imagen actual proviene de un plugin y no es parte de los recursos del juego"
            placement="top"
          >
            <IconButton className="badge" size="small">
              {Icons.info}
            </IconButton>
          </Tooltip>
        )}
      </DropZone>
    </FieldWrapper>
  );
}

const tester: RankedTester = rankWith(15, (uiSchema, schema, context) => {
  return (
    isCustomControl(uiSchema, "AssetPicker") &&
    isStringControl(uiSchema, schema, context)
  );
});

export default {
  tester,
  renderer: withJsonFormsControlProps(AssetPickerRenderer),
};
