import {
  GameAction,
  HasActions,
  GameActionTrigger,
  GameActionsMap,
  HasLegacyActions,
} from "../types";

import { GameActionPayloadDeprecated, GameCommand } from "../consts";

/**
 * Get all actions from an element.
 *
 * @param element
 * @returns
 */
export function getAllActions(
  element: HasActions | HasLegacyActions
): GameActionsMap {
  if (
    element.actions &&
    typeof element.actions === "object" &&
    !Array.isArray(element.actions)
  ) {
    return element.actions as GameActionsMap;
  }

  return convertLegacyActions(element);
}

/**
 * Extract the actions from an element, legacy and new format.
 *
 * @param element
 * @returns
 */
export function getActions(
  element: HasActions | HasLegacyActions,
  triggers: GameActionTrigger[] = []
): GameAction[] {
  const actions = getAllActions(element);

  return triggers
    .map((trigger) => {
      if (!Array.isArray(actions[trigger])) {
        // wrong format
        return [];
      }

      return actions[trigger] || [];
    })
    .flat();
}

/**
 * Check if an element has legacy actions.
 * Legacy actions are strings or arrays of strings.
 *
 * @param element
 * @returns
 */
export function hasLegacyActions(element: HasLegacyActions): boolean {
  return (
    (!!element.actions && Array.isArray(element.actions)) ||
    typeof element.actions === "string"
  );
}

/**
 * Convert legacy actions to the new format.
 *
 * @param element
 * @returns
 */
export function convertLegacyActions(
  element: HasLegacyActions
): GameActionsMap {
  // Legacy actions
  const actions: GameActionsMap = {};

  if (element.actions) {
    const parsedActions = parseActionDeprecated(
      typeof element.actions === "string"
        ? [element.actions]
        : (element.actions as string[])
    );

    actions.legacy = parsedActions.map((action) => ({
      command: action.action,
      payload: action.data,
    }));
  }

  if (element.actionsFail) {
    const parsedActions = parseActionDeprecated(
      typeof element.actionsFail === "string"
        ? [element.actionsFail]
        : (element.actionsFail as string[])
    );

    actions.fail = parsedActions.map((action) => ({
      command: action.action,
      payload: action.data,
    }));
  }

  return actions;
}

/**
 * Check if an element has a specific action.
 *
 * @param element
 * @param command
 * @returns
 */
export function hasAction(
  element: HasActions | HasLegacyActions,
  command: GameCommand | RegExp
): boolean {
  const allActions: GameAction[] = Object.values(getAllActions(element)).flat();

  // TODO: look in children layout
  return allActions.some((action) =>
    command instanceof RegExp
      ? command.test(action.command)
      : action.command === command
  );
}

/**
 * Check if an element has any actions.
 *
 * @param element
 * @returns
 */
export function hasActions(element: HasActions | HasLegacyActions): boolean {
  const allActions = Object.values(getAllActions(element)).flat();
  return allActions.length > 0;
}

/**
 * Parse the actions from a string or array of strings.
 * This is for legacy actions.
 *
 * @param action
 * @returns
 */
function parseActionDeprecated(
  actions?: string[]
): GameActionPayloadDeprecated[] {
  if (!actions?.length) return [];

  return actions
    .map((actionItem) => {
      const actionParts = actionItem.trim().split(/\s+/);
      const action = actionParts?.[0] as GameCommand;
      const data = actionParts?.[1] || "";

      if (!Object.values(GameCommand).includes(action)) {
        // Invalid action
        return null;
      }

      return {
        action,
        data,
      };
    })
    .filter((action): action is GameActionPayloadDeprecated => action !== null);
}
