import { useMemo, type ReactNode } from "react";
import { type SxProps, styled } from "@mui/material";

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "stretch",
  justifyContent: "stretch",
  gap: theme.spacing(1),
  position: "absolute",
  top: theme.spacing(2),
  left: theme.spacing(2),
  right: theme.spacing(2),
  bottom: theme.spacing(2),
  zIndex: 2,
  pointerEvents: "none",

  ".MuiButton-root": {
    // This may break the layout in some cases
    zoom: 0.825
  },

  "> *": {
    pointerEvents: "auto"
  }
}));

export function ToolbarSpacer({ width }: { width?: number | string }) {
  return <div style={{ width, flex: width ? "0 1 auto" : "1 1 auto", pointerEvents: "none" }} />;
}

interface Props {
  children: ReactNode;
  position: "top" | "bottom" | "left" | "right";
}

export function EditorToolbar({ children, position }: Props) {
  const positioning = useMemo<SxProps>(() => {
    const pos: SxProps = {
      bottom: position === "top" ? "auto" : undefined,
      right: position === "left" ? "auto" : undefined,
      left: position === "right" ? "auto" : undefined,
      top: position === "bottom" ? "auto" : undefined
    };

    if (position === "top" || position === "bottom") {
      pos.flexDirection = "row";
    } else {
      pos.flexDirection = "column";
    }

    return pos;
  }, [position]);

  return <Wrapper sx={positioning}>{children}</Wrapper>;
}
