import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Masonry from "react-responsive-masonry";
import { Box, Paper, styled, Tab, type TabProps, Tabs, Tooltip, useTheme } from "@mui/material";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from "react-dnd-html5-backend";

import { type LibraryCollectionCategoryDto, type LibraryCollectionItemDto } from "@shared/api-client";

import { useAppContext, useDevice, useTranslation } from "@app/hooks";
import { Image, PageLoading, Prompt, SectionTitle } from "@app/components";
import { useLibrary } from "@app/library";
import { AccessLevel } from "@app/types";
import { EditLibraryItemModal } from "@app/library/EditLibraryItemModal";

import LockIcon from "@mui/icons-material/Lock";
import AddIcon from "@mui/icons-material/Add";

const NEW_CATEGORY_ID = -2;
const NO_CATEGORY_ID = -1;
const DND_PROVIDER_OPTIONS = {};

type SortableTabType = {
  id: number;
  index: number;
};

const ContainerBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  gap: theme.spacing(2)
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  transition: theme.transitions.create(["backgroundColor", "opacity", "margin"], {
    duration: theme.transitions.duration.shortest
  })
}));

const StyledItemBox = styled(Box)(({ theme }) => ({
  transition: "transform 0.2s",
  cursor: "pointer",

  img: {
    background: theme.palette.grey[100],
    padding: theme.spacing(1),
    boxSizing: "border-box",
    width: "100%",
    borderRadius: 8,
    overflow: "hidden",
    transition: "box-shadow 0.2s"
  },

  p: {
    marginTop: theme.spacing(0.5),
    fontSize: "0.8rem",
    textAlign: "center",

    "& svg": {
      fontSize: "1rem",
      verticalAlign: "middle"
    }
  },

  ":hover": {
    transform: "scale(1.025)",
    img: {
      boxShadow: theme.shadows[4]
    }
  }
}));

function ItemBox({
  item,
  onSelect
}: {
  item: LibraryCollectionItemDto;
  onSelect: (item: LibraryCollectionItemDto) => void;
}) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "library-item",
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    item: () => item
  }));

  return (
    <StyledItemBox ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }} onClick={() => onSelect(item)}>
      <Image src={item.thumbnail} alt={item.name} draggable={false} />
      <p>
        {item.name} {item.accessLevel === AccessLevel.PRIVATE ? <LockIcon color='error' /> : null}
      </p>
    </StyledItemBox>
  );
}

function DroppableTab({
  onDropItem,
  onDragMove,
  onDragEnd,
  tooltip,
  label,
  index,
  hoverLeft,
  ...props
}: TabProps & {
  index?: number;
  onDropItem?: (item: LibraryCollectionItemDto) => void;
  onDragMove?: (sourceIndex: number, targetIndex: number) => void;
  onDragEnd?: () => void;
  tooltip?: string;
  hoverLeft?: boolean;
}) {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const [droppedItem, setDroppedItem] = useState<LibraryCollectionItemDto | undefined>();
  const [move, setMove] = useState<string>();

  // Dropping items OR categories
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ["library-item", "library-category"],

    drop: (item, monitor) => {
      // Drop of item
      if (monitor.getItemType() === "library-item") {
        setDroppedItem(item as LibraryCollectionItemDto);
        return;
      }
    },

    collect: (monitor) => ({
      // Only show hover when dragging an item
      isOver: monitor.isOver() && monitor.getItemType() === "library-item"
    }),

    hover: (item, monitor) => {
      // Only for library-category
      if (monitor.getItemType() === "library-item") {
        return;
      }

      if (index === undefined) {
        return;
      }

      if (!ref.current) {
        return;
      }

      // Scroll into view
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const clientOffset = monitor.getClientOffset();

      if (!clientOffset) {
        return;
      }

      const hoverClientX = clientOffset.x - hoverBoundingRect.left;
      const isLeft = hoverClientX < hoverMiddleX;
      const dragIndex = (item as SortableTabType).index;

      // Time to actually perform the action
      // Using string to avoid extra useEffect calls
      setMove([dragIndex, index + (isLeft ? 0 : 1)].join("|"));
    }
  }));

  // Sorting categories
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "library-category",
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    item: () => (index !== undefined ? { id: props.value, index } : undefined),
    end: () => setMove(undefined)
  }));

  // Actual drag move
  useEffect(() => {
    if (!onDragMove || !onDragEnd) {
      return;
    }

    if (move) {
      const [sourceIndex, targetIndex] = move.split("|").map(Number);
      onDragMove(sourceIndex, targetIndex);
    } else {
      onDragEnd();
    }
  }, [onDragMove, move, onDragEnd]);

  // Actual drop item
  useEffect(() => {
    if (droppedItem) {
      onDropItem?.(droppedItem);
      setDroppedItem(undefined);
    }
  }, [droppedItem, onDropItem]);

  const labelElement = tooltip ? (
    <Tooltip title={tooltip}>
      <span>{label}</span>
    </Tooltip>
  ) : (
    label
  );

  drag(drop(ref));

  return (
    <StyledTab
      {...props}
      label={labelElement}
      ref={ref}
      style={{
        backgroundColor: isOver ? "rgba(0, 0, 0, 0.1)" : "transparent",
        opacity: isDragging ? 0.5 : 1,
        marginLeft: hoverLeft ? theme.spacing(4) : 0
      }}
    />
  );
}

export default function LibraryCollectionContentPage() {
  const { t, ts } = useTranslation();
  const { getCategories, getCollectionItems, updateCollectionItem, createCategory, updateCategory } = useLibrary();
  const { notify } = useAppContext();
  const theme = useTheme();
  const { isTouch } = useDevice();

  const [items, setItems] = useState<LibraryCollectionItemDto[] | undefined>();
  const [selectedItem, setSelectedItem] = useState<LibraryCollectionItemDto | undefined>();

  const [categories, setCategories] = useState<LibraryCollectionCategoryDto[] | undefined>();
  const [selectedCategory, setSelectedCategory] = useState(NO_CATEGORY_ID);

  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [itemToMoveCategory, setItemToMoveCategory] = useState<LibraryCollectionItemDto | undefined>();

  const [refresh, setRefresh] = useState(0);
  const [categoryMove, setCategoryMove] = useState<[number, number] | undefined>();
  const [isCategoryDragging, setIsCategoryDragging] = useState(false);

  const doRefresh = useCallback(() => setRefresh((prev) => prev + 1), []);

  useEffect(() => {
    // Get categories
    getCategories().then((categories) => {
      setCategories(categories);
    });
  }, [getCategories, refresh]);

  useEffect(() => {
    if (selectedCategory === undefined && categories && categories.length > 0) {
      setSelectedCategory(categories[0].id);
    }
  }, [selectedCategory, categories]);

  useEffect(() => {
    setItems(undefined);

    if (selectedCategory === undefined) {
      return;
    }

    // Get collection items
    getCollectionItems({ category: [selectedCategory] }).then(setItems);
  }, [getCollectionItems, selectedCategory, refresh]);

  const handleMoveItemToCategory = useCallback(
    (item: LibraryCollectionItemDto, categoryId: number | null) => {
      updateCollectionItem(item.uuid, { categoryId }).then(doRefresh);
    },
    [doRefresh, updateCollectionItem]
  );

  const handleAddCategory = useCallback(
    async (newCategoryName: string) => {
      try {
        const newCategory = await createCategory(newCategoryName);

        setCategories((prevCategories) => [...(prevCategories || []), newCategory]);
        setShowAddCategoryModal(false);

        if (itemToMoveCategory) {
          handleMoveItemToCategory(itemToMoveCategory, newCategory.id);
        }

        return true;
      } catch (error: unknown) {
        notify(t("library.addCategoryError", { message: error instanceof Error ? error.message : String(error) }));
      }
    },
    [createCategory, handleMoveItemToCategory, itemToMoveCategory, notify, t]
  );

  const handleCancelAddCategory = useCallback(() => {
    setShowAddCategoryModal(false);
    setItemToMoveCategory(undefined);
  }, []);

  const handleCategoryChange = useCallback((_event: React.SyntheticEvent, newCategoryId: number) => {
    if (newCategoryId === NEW_CATEGORY_ID) {
      setShowAddCategoryModal(true);
      return;
    }

    setSelectedCategory(newCategoryId);
  }, []);

  const handleNewCategoryDrop = useCallback((item: LibraryCollectionItemDto) => {
    setItemToMoveCategory(item);
    setShowAddCategoryModal(true);
  }, []);

  const createCategoryDropHandler = useCallback(
    (targetCategoryId: number) => (item: LibraryCollectionItemDto) => {
      if (targetCategoryId === NO_CATEGORY_ID) {
        handleMoveItemToCategory(item, null);
        return;
      }

      handleMoveItemToCategory(item, targetCategoryId);
    },
    [handleMoveItemToCategory]
  );

  const handleDragMove = useCallback((categoryId: number, targetIndex: number) => {
    setCategoryMove([categoryId, targetIndex]);
    setIsCategoryDragging(true);
  }, []);

  const handleDragEnd = useCallback(() => {
    setIsCategoryDragging(false);
  }, []);

  useEffect(() => {
    if (isCategoryDragging || !categoryMove) {
      return;
    }

    const [sourceIndex, targetIndex] = categoryMove;
    const categoryId = categories?.[sourceIndex].id;
    const targetCategory = categories?.[targetIndex];

    // clean up
    setCategoryMove(undefined);

    if (!categoryId || !targetCategory) {
      return;
    }

    updateCategory(categoryId, { order: targetCategory.order - 1 }).then(doRefresh);
  }, [isCategoryDragging, categoryMove, categories, updateCategory, doRefresh]);

  const sortedCategories = useMemo(() => {
    return categories?.sort((a, b) => a.order - b.order);
  }, [categories]);

  if (!categories) {
    // Not loaded yet
    return <PageLoading />;
  }

  return (
    <Paper sx={{ padding: theme.spacing(8) }} elevation={0}>
      <SectionTitle title={t("library.content.items")} info={t("library.content.itemsHelp")} />

      <DndProvider
        key={isTouch ? "touch" : "mouse"} // Force re-render on touch/mouse change
        backend={isTouch ? TouchBackend : HTML5Backend}
        options={DND_PROVIDER_OPTIONS}
      >
        <ContainerBox>
          <Tabs
            orientation='horizontal'
            value={selectedCategory || NO_CATEGORY_ID}
            onChange={handleCategoryChange}
            style={{ marginBottom: theme.spacing(2) }}
            scrollButtons
          >
            <DroppableTab value={NEW_CATEGORY_ID} icon={<AddIcon />} onDropItem={handleNewCategoryDrop} />
            <DroppableTab value={NO_CATEGORY_ID} label={ts("library.uncategorized")} />
            {sortedCategories?.map((category, index) => (
              <DroppableTab
                key={category.id}
                value={category.id}
                index={index}
                label={category.name}
                onDropItem={createCategoryDropHandler(category.id)}
                onDragMove={handleDragMove}
                onDragEnd={handleDragEnd}
                hoverLeft={
                  // Avoid hovering over the category itself
                  categoryMove?.[1] === index && categoryMove?.[0] !== index && categoryMove?.[0] + 1 !== index
                }
              />
            ))}
          </Tabs>

          {items && (
            <Masonry columnsCount={6} gutter='16px'>
              {items.map((item) => (
                <ItemBox key={item.uuid} item={item} onSelect={setSelectedItem} />
              ))}
            </Masonry>
          )}

          {!items && <PageLoading />}
        </ContainerBox>
      </DndProvider>

      <EditLibraryItemModal
        item={selectedItem}
        categories={categories}
        onClose={(refresh) => {
          setSelectedItem(undefined);
          if (refresh) {
            doRefresh();
          }
        }}
      />

      <Prompt
        open={showAddCategoryModal}
        title={ts("library.addCategory")}
        message={t("library.addCategoryMessage")}
        onSubmit={handleAddCategory}
        onCancel={handleCancelAddCategory}
        confirmText={ts("common.add")}
      />
    </Paper>
  );
}
