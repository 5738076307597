import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { Box, styled, useMediaQuery, useTheme } from "@mui/material";

import { useAppContext, useBackend } from "@app/hooks";

import { Navigate } from "@app/router";
import { DashboardDrawer, DashboardHeader, PageError, PageLoading, WelcomeTour } from "@app/components";

const PageLayout = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "stretch",
  alignItems: "stretch"
}));

const DashboardContent = styled(Box)(() => ({
  flex: "1 0 0px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "stretch",

  // Hack alert!
  // The browser extends the container over the limits sometimes
  // so I'm enforcing a max-width
  maxWidth: "calc(100vw - 202px)",
  "&.menu-collapsed": {
    maxWidth: "calc(100vw - 88px)"
  }
}));

export default function DashboardLayout() {
  const { isBackendReady, loggedInUser, offline } = useBackend();
  const { state } = useAppContext();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const menuCollapsed = isSmallScreen || state.drawerMode === "collapsed";

  if (offline) {
    return <Navigate to={{ pathname: "/offline", search: `?redirectTo=${window.location.pathname}` }} />;
  }

  if (!isBackendReady) {
    return null;
  }

  if (!loggedInUser) {
    // Require a user
    return (
      <Navigate
        to={{
          pathname: "/auth/login",
          search: `?redirectTo=${window.location.pathname}`
        }}
        replace={true}
      />
    );
  }

  return (
    <PageLayout>
      <DashboardDrawer />
      <DashboardContent className={menuCollapsed ? "menu-collapsed" : ""}>
        <DashboardHeader title={state.pageTitle}>{state.headerContent}</DashboardHeader>
        <Box
          sx={{
            padding: state.canvasMode ? undefined : theme.spacing(4),
            flex: "1 0 0px",
            overflowY: "auto",
            scrollbarColor: `${theme.palette.primary.dark} transparent`,
            transition: "padding 0.15s"
          }}
        >
          <ErrorBoundary FallbackComponent={PageError}>
            <Suspense fallback={<PageLoading />}>
              <Outlet />
            </Suspense>
          </ErrorBoundary>
        </Box>
      </DashboardContent>
      <WelcomeTour />
    </PageLayout>
  );
}
