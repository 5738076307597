import React, { type ReactNode, createContext, useReducer } from "react";
import { ConfirmProvider } from "material-ui-confirm";

import { type DrawerMode, type MenuItem } from "@app/types";
import { getDrawerMainMenuItems } from "@app/config";
import { useQuickMemo } from "@app/hooks";
import { INITIAL_FEATURE_FLAGS } from "@app/contexts/FeatureFlagProvider";

import { type UfoFeatureDefinitions } from "@shared/utils/features";

// A proxy that always returns true for all feature flags
const ALWAYS_TRUE = new Proxy(
  {},
  {
    get() {
      return true;
    }
  }
);

// Define the shape of your state
type State = {
  ready: boolean;
  featureFlags: UfoFeatureDefinitions;
  drawerMode: DrawerMode;
  canvasMode: boolean;
  menus: MenuItem[][];
  menuParams: Record<string, string>[];
  pageTitle: ReactNode;
  headerContent: ReactNode;
  superPowers: boolean;
};

// Define the actions
type Action =
  | { type: "setDrawerMode"; payload: DrawerMode }
  | { type: "setCanvasMode"; payload: boolean }
  | { type: "pushMenu"; payload: { level: number; menu: MenuItem[]; params: Record<string, string> } }
  | { type: "popMenu"; payload: number }
  | { type: "setPageTitle"; payload: ReactNode }
  | { type: "setHeaderContent"; payload: ReactNode }
  | { type: "enableSuperPowers"; payload: boolean }
  | { type: "updateFeatureFlags"; payload: UfoFeatureDefinitions };

// Define the initial state
const initialState: State = {
  ready: false,
  featureFlags: INITIAL_FEATURE_FLAGS,
  drawerMode: "expanded",
  canvasMode: false,
  menus: [getDrawerMainMenuItems()],
  menuParams: [{}],
  pageTitle: null,
  headerContent: null,
  superPowers: false
};

// Define the reducer
function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "pushMenu": {
      // Replace the menu if it already exists
      const newMenus = [...state.menus];
      const newMenuParams = [...state.menuParams];

      newMenus[action.payload.level] = action.payload.menu;
      newMenuParams[action.payload.level] = action.payload.params || {};

      return {
        ...state,
        menus: newMenus,
        menuParams: newMenuParams
      };
    }

    case "popMenu": {
      const newMenus = [...state.menus];
      const newMenuParams = [...state.menuParams];

      delete newMenus[action.payload];
      delete newMenuParams[action.payload];

      return {
        ...state,
        menus: newMenus,
        menuParams: newMenuParams
      };
    }

    case "setDrawerMode":
      return { ...state, drawerMode: action.payload };

    case "setPageTitle":
      return { ...state, pageTitle: action.payload };

    case "setHeaderContent":
      return { ...state, headerContent: action.payload };

    case "setCanvasMode":
      return { ...state, canvasMode: action.payload };

    case "updateFeatureFlags": {
      if (state.superPowers) {
        // Skip feature flag updates when super powers are enabled
        return state;
      }

      const isNewFeatureFlags = JSON.stringify(action.payload) !== JSON.stringify(state.featureFlags);

      if (!isNewFeatureFlags) {
        // Prevent re-render
        return state;
      }

      return {
        ...state,
        featureFlags: action.payload,
        menus: [getDrawerMainMenuItems({ featureFlags: action.payload }), ...state.menus.slice(1)]
      };
    }

    case "enableSuperPowers":
      return {
        ...state,
        superPowers: action.payload,
        featureFlags: ALWAYS_TRUE,
        menus: [getDrawerMainMenuItems({ featureFlags: ALWAYS_TRUE }), ...state.menus.slice(1)]
      };

    default:
      return state;
  }
}

// Create the context
export const AppContext = createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => null
});

interface Props {
  children: ReactNode;
}

// Create the provider component
export const AppContextProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = useQuickMemo({ state, dispatch });

  return (
    <AppContext.Provider value={value}>
      <ConfirmProvider>{children}</ConfirmProvider>
    </AppContext.Provider>
  );
};
