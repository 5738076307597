import { useCallback, useEffect, useState } from "react";
import { Box, Paper, styled, useTheme } from "@mui/material";

import { useBackend, useTranslation } from "@app/hooks";
import { useEditor } from "@app/editor";
import { type Path, useNavigate } from "@app/router";

import { Form, type JsonUISchema, type JsonSchema } from "@shared/form-builder";
import { type GameDefinitionDto, type GameCategoryDto } from "@shared/api-client";
import { Button, ComposedImage, PageLoading, SectionTitle } from "@app/components";
import { getGameDefinitionSchema, getGameDefinitionUISchema } from "@app/schemas/game.definition.schema";

const Banner = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "90%",
  margin: theme.spacing(8, "auto"),
  height: 250,
  backgroundColor: theme.palette.grey[200],
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  boxShadow: theme.shadows[4],
  textAlign: "center"
}));

export default function ProjectSettingsGeneral({ nextTourPath }: { nextTourPath?: Path }) {
  const { gameDefinition, updateMainData, uuid, version } = useEditor();
  const { dashboard } = useBackend();
  const theme = useTheme();
  const { t, ts } = useTranslation();
  const navigate = useNavigate();

  const [categories, setCategories] = useState<GameCategoryDto[]>([]);
  const [currentData, setCurrentData] = useState(gameDefinition);
  const [isDirty, setIsDirty] = useState(false);
  const [schema, setSchema] = useState<JsonSchema | null>(null);
  const [uiSchema, setUiSchema] = useState<JsonUISchema | null>(null);

  useEffect(() => {
    dashboard.getList("/categories").then((response) => {
      setCategories(response.items);
    });
  }, [dashboard]);

  useEffect(() => {
    setSchema(getGameDefinitionSchema(categories, ts));
    setUiSchema(getGameDefinitionUISchema(ts));
  }, [categories, ts]);

  const handleUpdate = useCallback((data: GameDefinitionDto) => {
    setCurrentData((prevData) => {
      if (!prevData) {
        return prevData;
      }

      return {
        ...prevData,
        ...data
      };
    });

    setIsDirty(true);
  }, []);

  useEffect(() => {
    setCurrentData(gameDefinition);
    setIsDirty(false);
  }, [gameDefinition]);

  const discard = useCallback(() => {
    setCurrentData(gameDefinition);
    setIsDirty(false);
  }, [gameDefinition]);

  const submit = useCallback(() => {
    if (currentData) {
      updateMainData(currentData);
      setIsDirty(false);

      if (nextTourPath) {
        // Go to the next step
        navigate(nextTourPath, { params: { uuid, version } as never });
      }
    }
  }, [currentData, navigate, nextTourPath, updateMainData, uuid, version]);

  return (
    <Paper sx={{ padding: theme.spacing(8) }} elevation={0}>
      <SectionTitle title={t("editProject.general.title")} />
      <p>{t("editProject.general.description")}</p>

      <Banner>
        {currentData?.fgImage || currentData?.bgImage ? (
          <ComposedImage fg={currentData?.fgImage} bg={currentData?.bgImage} sx={{ height: "100%", width: "100%" }} />
        ) : null}
        {!currentData?.fgImage && !currentData?.bgImage ? <p>{t("editProject.general.bannerInfo")}</p> : null}
      </Banner>

      {!schema || !uiSchema || !currentData ? <PageLoading /> : null}

      {schema && uiSchema && currentData && (
        <Form schema={schema} uiSchema={uiSchema} data={currentData} onChange={handleUpdate} live />
      )}

      {!nextTourPath && (
        <Box textAlign='center' width='100%' mt={4}>
          <Button onClick={discard} variant='contained' color='info' sx={{ mr: 1 }}>
            {t("common.discardChanges")}
          </Button>
          <Button onClick={submit} variant='contained' disabled={!isDirty}>
            {t("common.applyChanges")}
          </Button>
        </Box>
      )}

      {nextTourPath && (
        <Box textAlign='center' width='100%' mt={4}>
          <Button onClick={submit} variant='contained' size='large'>
            {t("common.next")}
          </Button>
        </Box>
      )}
    </Paper>
  );
}
