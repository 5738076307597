import { useEffect, useState } from "react";
import { Box, IconButton, styled, Tooltip, Typography } from "@mui/material";

import { Empty, SearchBox } from "@app/components";
import { useTranslation } from "@app/hooks";
import { useBackend } from "@app/hooks/useBackend";

import { type LibraryCollectionCategoryDto } from "@shared/api-client";

import { LibraryPickerList } from "./LibraryPickerList";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface Props {
  libraryUuid: string;
  allowSearch?: boolean;
}

// Hide empty state if the library picker list is not empty
const StyledBox = styled(Box)({
  ".empty-library-picker-categories": {
    display: "none"
  },

  "&:not(:has(.library-picker-list)) .empty-library-picker-categories": {
    display: "block"
  }
});

const StyledSectionBox = styled(Box)({
  // Hide section if it contains an empty library picker list
  "&:has(.empty-library-picker-list)": {
    display: "none"
  }
});

function SectionBox({
  title,
  children,
  onClick,
  expanded
}: {
  title: string;
  children: React.ReactNode;
  onClick?: () => void;
  expanded?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <StyledSectionBox>
      <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
        {expanded && (
          <Tooltip title={t("library.backToCategories")}>
            <IconButton onClick={onClick}>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        )}
        <Typography variant='h6' flexGrow={1}>
          {title}
        </Typography>
        {!expanded && (
          <Tooltip title={t("library.expandCategory")}>
            <IconButton onClick={onClick}>
              <ArrowForwardIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {children}
    </StyledSectionBox>
  );
}

export function LibraryPickerCategories(props: Props) {
  const { libraryUuid, allowSearch } = props;
  const { library } = useBackend();

  const [search, setSearch] = useState<string>("");
  const [categories, setCategories] = useState<LibraryCollectionCategoryDto[]>();
  const [expandedCategory, setExpandedCategory] = useState<LibraryCollectionCategoryDto>();

  useEffect(() => {
    library
      .getList({ path: "/:uuid/categories", params: { uuid: libraryUuid } })
      .then((categories) => setCategories(categories.items));
  }, [library, libraryUuid]);

  return (
    <StyledBox>
      {allowSearch && <SearchBox onSearch={setSearch} persistingKey='library-search' />}

      {!expandedCategory &&
        categories &&
        categories.map((category) => (
          <SectionBox key={category.id} title={category.name} onClick={() => setExpandedCategory(category)}>
            <LibraryPickerList
              libraryUuid={libraryUuid}
              categoryId={category.id}
              disposition='horizontal'
              searchTerm={search}
              limit={10}
            />
          </SectionBox>
        ))}

      {!expandedCategory && <Empty className='empty-library-picker-categories' />}

      {expandedCategory && (
        <SectionBox title={expandedCategory.name} onClick={() => setExpandedCategory(undefined)} expanded>
          <LibraryPickerList
            libraryUuid={libraryUuid}
            categoryId={expandedCategory.id}
            disposition='masonry'
            searchTerm={search}
          />
        </SectionBox>
      )}
    </StyledBox>
  );
}
