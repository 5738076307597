import { useCallback, useEffect, useState } from "react";
import { executeAction } from "./utils/actions";

import GameEngine, {
  type GameLevelRendered,
  type GameDataInterface,
  type GameStateInterface,
  type EmmitedGameEventData,
  type GameOverlayRendered,
  type GameActionTrigger,
  DEFAULT_SCREEN,
  DEFAULT_THEME,
} from "@shared/game-engine";
import { GameCanvas } from "./GameCanvas";
import { ComponentProvider } from "./ComponentProvider";
import { useTimer } from "./hooks/useTimer";
import { type RenderingContext } from "./types/canvas";
import { type Logger } from "@shared/utils/logging";

interface GamePlayerOfflineProps {
  gameData: GameDataInterface;
  initialState?: Partial<GameStateInterface>;
  settings: {
    libraryBaseUrl: string;
    assetBaseUrl: string;
    pluginBaseUrl: string;
    builtinPluginBaseUrl: string;
    pluginIndexFile: string;
    strictMode?: boolean;
  };
  onEvent?: (
    event: string,
    data: EmmitedGameEventData,
    engine: GameEngine
  ) => void;
  renderingContext?: Partial<RenderingContext>;
  logger?: Logger;
  globalVolume?: number;
}

export function GamePlayerOffline(props: GamePlayerOfflineProps) {
  const {
    gameData,
    settings,
    onEvent,
    initialState,
    renderingContext,
    logger,
    globalVolume,
  } = props;
  const [engine, setEngine] = useState<GameEngine | null>(null);
  const { isExpired } = useTimer(engine?.renderState());
  const [renderedLevel, setRenderedLevel] = useState<GameLevelRendered | null>(
    null
  );
  const [renderedOverlay, setRenderedOverlay] =
    useState<GameOverlayRendered | null>(null);

  const [currentLevel, setCurrentLevel] = useState<string>();
  const [currentOverlay, setCurrentOverlay] = useState<string>();
  const [transitioning, setTransitioning] = useState(false);

  // Initialize the game engine
  useEffect(() => {
    if (!gameData) {
      return;
    }

    const engine = new GameEngine(gameData, {
      libraryBaseUrl: settings.libraryBaseUrl,
      assetBaseUrl: settings.assetBaseUrl,
      pluginBaseUrl: settings.pluginBaseUrl,
      strictMode: settings.strictMode,
      initialState,
      logger,
    });

    setEngine(engine);

    // Initial render
    setCurrentLevel(engine.getActualCurrentLevelId());
    setCurrentOverlay(engine.getActualCurrentOverlayId());
    setRenderedLevel(engine.renderLevel());
    setRenderedOverlay(engine.renderOverlay());

    // Notify the initial state if onEvent is provided
    const gameState = engine.getState();
    const gameLog = engine.getLog();
    onEvent?.(
      "gameStateUpdated",
      {
        game_state_uuid: gameState.uuid,
        arg0: gameState,
        arg1: gameLog,
      },
      engine
    );

    return () => {
      engine.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gameData,
    initialState,
    settings.libraryBaseUrl,
    settings.assetBaseUrl,
    settings.pluginBaseUrl,
    settings.strictMode,
  ]);

  // Listen to game events
  useEffect(() => {
    if (!engine) {
      return;
    }

    const handleStateUpdated = () => {
      if (transitioning) {
        // Ignore updates while transitioning
        return;
      }

      // TODO: Optimize this and only render the changed elements
      const newLevelData = engine.renderLevel();
      const newOverlayData = engine.renderOverlay();
      const newCurrentLevel = engine.getActualCurrentLevelId();

      // Level change and has a transition out. Wait for it to finish
      if (
        newCurrentLevel !== currentLevel &&
        renderedLevel?.transitionOutDuration
      ) {
        setTransitioning(true);

        setTimeout(() => {
          setRenderedLevel(() => newLevelData);
          setRenderedOverlay(() => newOverlayData);
          setCurrentLevel(newCurrentLevel);
          setTransitioning(false);
        }, renderedLevel.transitionOutDuration);
      } else {
        // No transition, just update the level
        setCurrentLevel(newCurrentLevel);
        setRenderedLevel(newLevelData);
        setRenderedOverlay(newOverlayData);
      }
    };

    const handleActionEnqueued = () => {
      const actions = engine.getPendingActions();
      actions.forEach(executeAction);
    };

    engine.addListener("gameStateUpdated", handleStateUpdated);
    engine.addListener("gameActionEnqueued", handleActionEnqueued);

    return () => {
      engine.removeListener("gameStateUpdated", handleStateUpdated);
      engine.removeListener("gameActionEnqueued", handleActionEnqueued);
    };
  }, [
    currentLevel,
    engine,
    renderedLevel?.transitionOutDuration,
    transitioning,
  ]);

  // Trigger time-based conditions
  useEffect(() => {
    if (isExpired) {
      engine?.checkTimeBasedConditions();
    }
  }, [engine, isExpired]);

  // Listen to all events to pass them to the parent component
  useEffect(() => {
    if (!engine) {
      return;
    }

    const handleAllEvents = (event: string, data: EmmitedGameEventData) => {
      if (onEvent) {
        onEvent(event, data, engine);
      }
    };

    engine.addListener("*", handleAllEvents);

    return () => {
      engine.removeListener("*", handleAllEvents);
    };
  }, [engine, onEvent]);

  const handleAction = useCallback(
    (
      elementId: string,
      trigger?: GameActionTrigger,
      payload?: string
    ): void => {
      if (!engine) {
        return;
      }

      engine.triggerElement(elementId, trigger, payload);
    },
    [engine]
  );

  if (!renderedLevel) {
    return null;
  }

  return (
    <ComponentProvider
      builtinPluginsBaseUrl={settings.builtinPluginBaseUrl}
      pluginsBaseUrl={settings.pluginBaseUrl}
      indexFile={settings.pluginIndexFile}
    >
      <GameCanvas
        screen={gameData.screen || DEFAULT_SCREEN}
        theme={gameData.theme || DEFAULT_THEME}
        levelId={currentLevel || ""}
        levelData={renderedLevel}
        overlayId={currentOverlay || ""}
        overlayData={renderedOverlay || undefined}
        onAction={handleAction}
        gameState={engine?.renderState()}
        show={!transitioning}
        renderingContext={renderingContext}
        globalVolume={globalVolume}
      />
    </ComponentProvider>
  );
}
