import { useCallback, useEffect, useRef, useState } from "react";
import { Stack, Chip, useTheme, Box, CircularProgress, styled } from "@mui/material";

import { type GameDefinitionDto, type GameCategoryDto } from "@shared/api-client";

import { useAppContext, useBackend, useTagManager, useTranslation } from "@app/hooks";
import { TemplateList } from "@app/components";
import { TemplateModal } from "@app/components/projects/TemplateModal";

import { Empty } from "@app/components";

const ScrollerBox = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: "auto",
  overflowX: "hidden",
  padding: theme.spacing(2, 2, 20, 2),
  margin: theme.spacing(-1, -2, -4, -2)
}));

export default function CreatePage() {
  const theme = useTheme();
  const { setPageTitle } = useAppContext();
  const { t } = useTranslation();
  const { gameManager, dashboard } = useBackend();
  const tm = useTagManager();

  const [category, setCategory] = useState<string>("");
  const [categories, setCategories] = useState<GameCategoryDto[]>([]);
  const [templates, setTemplates] = useState<GameDefinitionDto[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const limit = 16;
  const firstFetchLimit = 19;

  const [selection, setSelection] = useState<GameDefinitionDto | undefined>();
  const [selectionModalOpen, setSelectionModalOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const loadingRef = useRef(false);

  useEffect(() => {
    setPageTitle(t("create.title"));
    return () => setPageTitle("");
  }, [setPageTitle, t]);

  const fetchCategories = useCallback(async () => {
    try {
      const { items } = await dashboard.getList({
        path: "/categories"
      });
      setCategories(items || []);
    } catch (error) {
      console.error(error);
    }
  }, [dashboard]);

  const fetchTemplates = useCallback(
    async (offset: number, limit: number, append = false, firstFetch = false) => {
      loadingRef.current = true;
      setLoading(true);

      try {
        const { items } = await dashboard.getList({
          path: "/templates",
          search: { offset, limit: firstFetch ? firstFetchLimit : limit }
        });

        if (items.length > 0) {
          setTemplates((prev) => (append ? [...prev, ...items] : items));
          setHasMore(items.length === (firstFetch ? firstFetchLimit : limit));
          setOffset((prevOffset) => prevOffset + (firstFetch ? firstFetchLimit : limit));
        } else {
          setHasMore(false);
        }
      } catch (error) {
        console.error(error);
        setHasMore(false);
      } finally {
        loadingRef.current = false;
        setLoading(false);
      }
    },
    [dashboard]
  );

  useEffect(() => {
    fetchCategories();
    fetchTemplates(0, limit, false, true);
  }, [fetchCategories, fetchTemplates]);

  const handleScroll = useCallback(() => {
    if (!containerRef.current || loadingRef.current || !hasMore) return;

    const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      fetchTemplates(offset, limit, true);
    }
  }, [hasMore, limit, fetchTemplates, offset]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const create = useCallback(
    async (template?: GameDefinitionDto) => {
      const game = await gameManager.post("/", { templateUuid: template?.uuid });

      tm("game_create", {
        from_template: !!template,
        source_template_uuid: template?.uuid,
        source_template_name: template?.name,
        game_uuid: game.uuid
      });

      return game;
    },
    [gameManager, tm]
  );

  return (
    <Box display='flex' flexDirection='column' height='100%'>
      {categories?.length > 0 && (
        <Stack
          direction='row'
          spacing={2}
          height={50}
          marginBottom={theme.spacing(4)}
          sx={{ overflowX: "auto", flexShrink: 0 }}
        >
          <Chip
            label={t("create.allCategories")}
            variant={!category ? "filled" : "outlined"}
            onClick={() => setCategory("")}
          />
          {categories.map((item) => (
            <Chip
              key={item.name}
              label={item.name}
              variant={category === item.name ? "filled" : "outlined"}
              onClick={() => setCategory(item.name)}
            />
          ))}
        </Stack>
      )}

      <ScrollerBox ref={containerRef}>
        {templates.filter((item) => !category || item.category === category).length > 0 ? (
          <TemplateList
            onSelect={(template) => {
              setSelection(template);
              setSelectionModalOpen(true);
            }}
            templates={templates.filter((item) => !category || item.category === category)}
          />
        ) : (
          <Empty size='large' sx={{ marginTop: 10 }} />
        )}

        {loading && (
          <Box display='flex' justifyContent='center' padding={2}>
            <CircularProgress />
          </Box>
        )}
      </ScrollerBox>

      {selection && (
        <TemplateModal
          open={selectionModalOpen}
          onClose={() => setSelectionModalOpen(false)}
          onConfirm={create}
          template={selection}
        />
      )}
    </Box>
  );
}
