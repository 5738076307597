import type { JsonSchema, JsonUISchema } from "@shared/form-builder";

export const GameScreenSchema: JsonSchema = {
  type: "object",
  properties: {
    width: {
      type: "number",
      title: "Ancho",
      description: "Ancho de la pantalla en pixeles."
    },
    height: {
      type: "number",
      title: "Alto",
      description: "Alto de la pantalla en pixeles."
    },
    orientation: {
      type: "string",
      title: "Orientación",
      description: "Forzar la orientación de la pantalla en dipositivos que lo permitan.",
      oneOf: [
        { const: "landscape", title: "Horizontal" },
        { const: "portrait", title: "Vertical" }
      ]
    },
    fullScreen: {
      type: "boolean",
      description: "Solicitar pantalla completa al cargar el juego.",
      title: "Forzar pantalla completa"
    },
    drawOutside: {
      type: "boolean",
      description:
        "Permitir mostrar elementos en las áreas laterales que se generan cuando la pantalla del dispositivo tiene proporciones diferentes a las del juego. Si está desactivado, estas áreas se rellenarán con el color de fondo.",
      title: "Dibujar fuera del área visible"
    }
  }
};

export const GameScreenUISchema: JsonUISchema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/width",
          options: { icon: "settings", flatStyle: true }
        },
        {
          type: "Control",
          scope: "#/properties/height",
          options: { icon: "settings", flatStyle: true }
        }
      ]
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/orientation",
          options: { icon: "orientation", flatStyle: true }
        },
        {
          type: "Control",
          scope: "#/properties/fullScreen",
          options: { icon: "fullscreen", flatStyle: true }
        }
      ]
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/drawOutside",
          options: { icon: "drawOutside", flatStyle: true }
        }
      ]
    }
  ]
};
