import { Box, Input, Slider } from "@mui/material";

import { withJsonFormsControlProps } from "@jsonforms/react";
import {
  ControlProps,
  RankedTester,
  isNumberControl,
  rankWith,
} from "@jsonforms/core";
import { FieldWrapper } from "../common/FieldWrapper";
import { useCallback, useEffect, useState } from "react";
import { isCustomControl } from "../utils";

interface Props extends ControlProps {
  data: number;
  path: string;
  handleChange: (path: string, value: number) => void;
}

export function NumberFieldRenderer({
  label,
  description,
  data,
  path,
  visible,
  uischema,
  schema,
  enabled,
  errors,
  handleChange,
}: Props) {
  const [currentValue, setCurrentValue] = useState<number>(0);
  const [hasFocus, setHasFocus] = useState(false);

  useEffect(() => {
    if (!hasFocus) {
      setCurrentValue(data !== undefined ? data : 0);
    }
  }, [data, hasFocus]);

  const handleSliderChange = useCallback(
    (_ev: Event, value: number | number[]) => {
      const singleVal = Array.isArray(value) ? value[0] : value;
      setCurrentValue(singleVal);
      handleChange(path, singleVal);
    },
    [handleChange, path]
  );

  const handleInputChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(ev.target.value) || 0;

      setCurrentValue(value);
      handleChange(path, value);
    },
    [handleChange, path]
  );

  const handleFocus = useCallback(() => {
    setHasFocus(true);
  }, []);

  const handleBlur = useCallback(() => {
    handleChange(path, Number(currentValue) || 0);
    setHasFocus(false);
  }, [handleChange, path, currentValue]);

  if (!visible) {
    return null;
  }

  return (
    <FieldWrapper
      label={label}
      description={description}
      uiSchema={uischema}
      errors={errors}
    >
      <Box display="flex" flexDirection="row" gap={2}>
        <Box flex="1 0 auto">
          <Slider
            value={currentValue || 0}
            onChange={handleSliderChange}
            min={schema.minimum}
            max={schema.maximum}
            step={schema.multipleOf}
            disabled={!enabled}
            valueLabelDisplay="auto"
          />
        </Box>
        <Box flex="0 1 60px">
          <Input
            value={currentValue}
            onChange={handleInputChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            type="number"
            disabled={!enabled}
          />
        </Box>
      </Box>
    </FieldWrapper>
  );
}

const tester: RankedTester = rankWith(15, (uiSchema, schema, context) => {
  return (
    isCustomControl(uiSchema, "Slider") &&
    isNumberControl(uiSchema, schema, context)
  );
});

export default {
  tester,
  renderer: withJsonFormsControlProps(NumberFieldRenderer),
};
