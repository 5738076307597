import { useContext, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { useAppContext, useBackend, useShortcuts, useTagManager, useTranslation } from "@app/hooks";
import { FeatureFlagContext } from "@app/contexts";

// PSA: Do not put context providers here. Because Modals won't get them.
// Need to double check with generouted.
export default function AppLayout() {
  const { superPowers, enableSuperPowers, notify } = useAppContext();
  const { isAdmin, loggedInUser } = useBackend();
  const featureFlagsContext = useContext(FeatureFlagContext);
  const { t } = useTranslation();
  const location = useLocation();

  const tm = useTagManager();

  // Listen keyboard shortcuts
  useShortcuts({
    "cmd+shift+e": () => isAdmin && enableSuperPowers(true)
  });

  // Notify user when super powers are enabled
  useEffect(() => {
    if (superPowers) {
      notify(t("common.superPowersEnabled"), "🦸‍♂️");
    }
  }, [superPowers, notify, t]);

  // Track page views
  useEffect(() => {
    tm("page_view", {
      page_location: document.location.protocol + "//" + document.location.host + location.pathname,
      page_title: document.title,
      page_path: location.pathname
    });
  }, [location.pathname, tm]);

  useEffect(() => {
    featureFlagsContext.setTrackingCallback((experiment, result) => {
      tm("feature_experiment_viewed", {
        experimentId: experiment.key,
        variationId: result.key
      });
    });
  }, [featureFlagsContext, tm]);

  // Set user attributes for feature flags context
  useEffect(() => {
    const deviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      ? "mobile"
      : "desktop";
    const browser = navigator.userAgent.split(" ")[0];

    featureFlagsContext.setAttributes({
      id: loggedInUser ? loggedInUser.id : "anonymous",
      role: loggedInUser ? loggedInUser.role : "anonymous",
      host: window.location.host,
      path: location.pathname,
      query: new URLSearchParams(location.search).toString(),
      browser,
      deviceType,
      superPowers
    });
  }, [featureFlagsContext, location.pathname, location.search, loggedInUser, superPowers]);

  return (
    <main>
      <Outlet />
    </main>
  );
}
