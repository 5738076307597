import {
  GroupLayout,
  LayoutProps,
  RankedTester,
  rankWith,
} from "@jsonforms/core";
import { renderLayoutElements } from "@jsonforms/material-renderers";
import { withJsonFormsLayoutProps } from "@jsonforms/react";

import isEmpty from "lodash/isEmpty";

import { FieldWrapper } from "../common/FieldWrapper";

export const GroupLayoutElement = ({
  uischema,
  renderers,
  cells,
  schema,
  path,
  enabled,
  visible,
}: LayoutProps) => {
  const layout = uischema as GroupLayout;

  if (isEmpty(layout.elements) || !visible) {
    return null;
  }

  return (
    <FieldWrapper label={layout.label} uiSchema={uischema}>
      {renderLayoutElements(
        layout.elements,
        schema,
        path,
        enabled,
        renderers,
        cells
      )}
    </FieldWrapper>
  );
};

const tester: RankedTester = rankWith(20, (uiSchema) => {
  return uiSchema.type === "Group";
});

export default {
  tester,
  renderer: withJsonFormsLayoutProps(GroupLayoutElement),
};
