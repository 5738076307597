import { useAppContext } from "@app/hooks";
import { Navigate } from "@app/router";

export default function DashboardHome() {
  const { featureFlags } = useAppContext();

  // Redirect to overview page
  if (featureFlags?.["menu-metrics"]) {
    return <Navigate to='/dashboard/overview' replace />;
  }

  return <Navigate to='/dashboard/projects' replace />;
}
