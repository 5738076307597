import { useEffect, useRef } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useTraceUpdates(props: any, id?: string) {
  const prev = useRef(props);

  useEffect(() => {
    const changedProps = Object.entries(props).reduce<Record<string, unknown[]>>((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});

    if (Object.keys(changedProps).length > 0) {
      console.debug(
        `${id ? `[${id}] ` : ""}Changed props:\n`,
        Object.keys(changedProps)
          .map((k) => `${k}: ${changedProps[k].map((v) => String(v)).join(" > ")}`)
          .join("\n")
      );
    } else {
      console.debug(`${id ? `[${id}] ` : ""}No changes`);
    }
    prev.current = props;
  });
}
