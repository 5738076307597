import { useMemo } from "react";

import { useTranslation } from "@app/hooks";
import { Box, styled, type SxProps, Typography } from "@mui/material";

import UfitoImg from "@shared/branding/imagery/art/ufito-dubitativo-1.png";

interface Props {
  className?: string;
  title?: string;
  size?: "small" | "medium" | "large";
  sx?: SxProps;
}

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  color: theme.palette.text.secondary,
  textAlign: "center"
}));

export function Empty({ className, size = "small", sx, title }: Props) {
  const { t } = useTranslation();

  const sizePx = useMemo(() => {
    if (size === "small") return 60;
    if (size === "medium") return 100;
    return 180;
  }, [size]);

  return (
    <StyledBox className={className} sx={sx}>
      <img src={UfitoImg} width={sizePx} />
      <Typography align='center' mt={2}>
        {title || t("common.noResults")}
      </Typography>
    </StyledBox>
  );
}
