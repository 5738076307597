import React, { useCallback, useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";

import { createLogger } from "@shared/utils/logging";

import { type UpdateOptions, type HasHistory } from "./types";

const { info: log } = createLogger("EditorHistory", { canLog: () => window.__debug__ });

const MAX_UNDO_LEVELS = 50;

interface EditorHistory<T extends HasHistory = HasHistory> {
  [level: string]: T[];
}

interface HistoryIndex {
  [level: string]: number;
}

export function useEditorHistory<T extends HasHistory>(
  id: string | undefined,
  data: T | undefined,
  updateData: (data: T, options: UpdateOptions) => void
) {
  const [currentId, setCurrentId] = useState<string | undefined>();
  const [history, setHistory] = useState<EditorHistory<T>>({});
  const [index, setIndex] = useState<HistoryIndex>({});
  const [enabled] = useState(true);
  const [nextUpdateFromHistory, setNextUpdateFromHistory] = useState(false);

  const undo = useCallback(() => {
    if (!currentId || !history[currentId]) return;

    const levelHistory = history[currentId];
    const targetData = levelHistory[index[currentId] - 1];

    if (targetData) {
      log(`(${currentId}) Undo`);
      setNextUpdateFromHistory(true);
      updateData(cloneDeep(targetData), { replace: true, historyUpdatedAt: targetData.historyUpdatedAt });
      setIndex({ ...index, [currentId]: index[currentId] - 1 });
    }
  }, [currentId, history, index, updateData]);

  const redo = useCallback(() => {
    if (!currentId || !history[currentId]) return;

    const levelHistory = history[currentId];
    const next = levelHistory[index[currentId] + 1];

    if (next) {
      log(`(${currentId}) Redo`);
      setNextUpdateFromHistory(true);
      updateData(cloneDeep(next), { replace: true, historyUpdatedAt: next.historyUpdatedAt });
      setIndex({ ...index, [currentId]: index[currentId] + 1 });
    }
  }, [history, index, currentId, updateData]);

  const canUndo = useCallback(() => {
    return currentId && index[currentId] > 0;
  }, [currentId, index]);

  const canRedo = useCallback(() => {
    return currentId && index[currentId] < history[currentId]?.length - 1;
  }, [currentId, index, history]);

  // Update history on change level data
  useEffect(() => {
    if (enabled && currentId && data) {
      const historyUpdatedAt = history[currentId]?.[index[currentId]]?.historyUpdatedAt;
      const levelUpdatedAt = data.historyUpdatedAt;

      if (!levelUpdatedAt) {
        log(`(${currentId}) No history update timestamp. Likely a problem?.`);
        return;
      }

      if (nextUpdateFromHistory || (historyUpdatedAt && historyUpdatedAt > levelUpdatedAt)) {
        log(`(${currentId}) Update is likely from history. Skipping history update.`);
        setNextUpdateFromHistory(false);
        return;
      }

      if (historyUpdatedAt === levelUpdatedAt) {
        log(`(${currentId}) Silent update. Skipping history update.`);
        return;
      }

      const newLevelHistory = (history[currentId] || [])
        .slice(0, index[currentId] + 1)
        .concat(cloneDeep(data))
        .slice(-MAX_UNDO_LEVELS);

      setHistory({ ...history, [currentId]: newLevelHistory });
      setIndex({ ...index, [currentId]: newLevelHistory.length - 1 });

      log(`(${currentId}) Entry added to history`);
    }
  }, [enabled, history, index, currentId, data, nextUpdateFromHistory]);

  // Log history index
  useEffect(() => {
    if (currentId && index[currentId] !== undefined && history[currentId] !== undefined) {
      log(`(${currentId}) History index:`, 1 + index[currentId], "of", history[currentId]?.length);
    }
  }, [history, index, currentId]);

  // Listen for layer change
  useEffect(() => {
    setCurrentId(id);
  }, [id]);

  return React.useMemo(
    () => ({
      undo,
      redo,
      canUndo,
      canRedo
    }),
    [undo, redo, canUndo, canRedo]
  );
}
