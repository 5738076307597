import { useMemo } from "react";
import { Box, IconButton, styled, Tooltip } from "@mui/material";
import { PieChart } from "@mui/x-charts";

import { InfoOutlined } from "@mui/icons-material";

interface Data {
  value: number;
  formattedValue?: string;
  label: string;
}

interface Props {
  data: Data[];
  colors?: string[];
  info?: string;
}

const ChartContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  maxWidth: "500px",
  minHeight: "200px",
  height: "100%",
  margin: theme.spacing(-1, "auto"), // Compensate for the margin of the parent container

  path: {
    filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2))",
    stroke: "transparent"
  }
}));

const InfoContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(2),
  right: theme.spacing(2)
}));

export function ArcChart({ data, colors, info }: Props) {
  const series = useMemo(
    () => [
      {
        data: data.map((item) => ({
          ...item,
          id: item.label,
          arcLabel: (item: Data) => `${item.value}%`,
          arcLabelMinAngle: 35,
          arcLabelRadius: "60%"
        })),
        innerRadius: "50%",
        outerRadius: "160%",
        startAngle: -90,
        endAngle: 90,
        cornerRadius: 2,
        cx: "40%",
        cy: "90%"
      }
    ],
    [data]
  );

  return (
    <ChartContainer>
      <PieChart series={series} colors={colors} />
      <InfoContainer>
        {info && (
          <Tooltip title={info}>
            <IconButton>
              <InfoOutlined color='info' />
            </IconButton>
          </Tooltip>
        )}
      </InfoContainer>
    </ChartContainer>
  );
}
