import { useCallback, useEffect, useMemo } from "react";
import { Box, Chip, IconButton, styled } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { usePersistedState, useTranslation } from "@app/hooks";
import { TextField } from "@app/components";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "stretch",
  marginBottom: theme.spacing(4)
}));

const StyledFilters = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: theme.spacing(0.5)
}));

interface Item<FilterType> {
  label: React.ReactNode;
  value: FilterType;
}

interface Props<FilterType> {
  persistingKey?: string;
  onSearch: (value: string) => void;
  onFilter?: (value: FilterType[]) => void;
  filters?: Item<FilterType>[];
  debounceTime?: number;
}

export function SearchBox<FilterType = string>(props: Props<FilterType>) {
  const { persistingKey, onSearch, filters, onFilter, debounceTime = 300 } = props;
  const { ts } = useTranslation();

  const [search, setSearch] = usePersistedState<string>(
    `${persistingKey || "none"}-search`,
    "",
    persistingKey ? "session" : "none"
  );

  const [filter, setFilter] = usePersistedState<FilterType[]>(
    `${persistingKey || "none"}-filter`,
    [],
    persistingKey ? "session" : "none"
  );

  const handleClearSearch = useCallback(() => {
    setSearch("");
  }, [setSearch]);

  const inputProps = useMemo(
    () => ({
      endAdornment: (
        <IconButton onClick={handleClearSearch} size='small'>
          {<ClearIcon />}
        </IconButton>
      )
    }),
    [handleClearSearch]
  );

  useEffect(() => {
    if (!search.trim()) {
      // Cleanup not debounced
      onSearch("");
    }

    const timeout = setTimeout(() => {
      onSearch(search.trim());
    }, debounceTime);

    return () => clearTimeout(timeout);
  }, [debounceTime, onSearch, search]);

  useEffect(() => {
    if (filter && filters) {
      onFilter?.(filter);
    }
  }, [filter, filters, onFilter]);

  return (
    <StyledBox>
      <TextField
        variant='outlined'
        size='small'
        placeholder={ts("common.search")}
        value={search}
        inputProps={inputProps}
        onChange={useCallback(
          (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.currentTarget.value),
          [setSearch]
        )}
        sx={{ marginBottom: -1 }}
      />
      {filters && (
        <StyledFilters>
          {filters.map((item) => (
            <Chip
              key={String(item.value)}
              label={item.label}
              color={filter.includes(item.value) ? "default" : "info"}
              sx={{ opacity: filter.includes(item.value) ? 1 : 0.5 }}
              size='small'
              onClick={() =>
                setFilter((prev) =>
                  filter.includes(item.value) ? prev.filter((i) => i !== item.value) : [...prev, item.value]
                )
              }
            />
          ))}
        </StyledFilters>
      )}
    </StyledBox>
  );
}
