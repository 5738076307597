import { Box, styled, Typography } from "@mui/material";

interface Props {
  value: number;
  total: number;
  valueLabel: string;
  totalLabel: string;
}

const ChartContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(2),
  padding: theme.spacing(4),
  height: "100%"
}));

const BigNumber = styled(Typography)(({ theme }) => ({
  strong: {
    display: "block",
    fontSize: theme.typography.pxToRem(184),
    fontWeight: 500,
    lineHeight: 1
  },
  color: theme.palette.primary.dark,
  textAlign: "center"
}));

const Footer = styled(Typography)(({ theme }) => ({
  width: "100%",
  borderTop: `1px solid ${theme.palette.divider}`,
  paddingTop: theme.spacing(2),
  fontSize: theme.typography.pxToRem(16),
  textAlign: "center",
  textTransform: "uppercase"
}));

export function BigCountChart({ value, total, valueLabel, totalLabel }: Props) {
  return (
    <ChartContainer>
      <BigNumber>
        <strong>{value}</strong> {valueLabel}
      </BigNumber>
      <Footer>
        <strong>{total}</strong> {totalLabel}
      </Footer>
    </ChartContainer>
  );
}
