import { useCallback, useMemo, useState } from "react";
import { type Accept, useDropzone } from "react-dropzone";
import { Box, Button, CircularProgress, Grid, styled, Typography } from "@mui/material";

import { type GameAssetDto } from "@shared/api-client";
import { AssetType } from "@shared/game-engine";
import { ModalSection, MultiSectionModal } from "@shared/form-builder/common/MultiSectionModal";

import { Empty, Image, Audio, Video, UploadBox } from "@app/components";
import { useEditor } from "@app/editor/useEditor";
import { useAppContext, useTranslation } from "@app/hooks";

import AddIcon from "@mui/icons-material/AddCircleOutline";
import FileIcon from "@mui/icons-material/FilePresent";

import { getAssetTypeFromMimeType } from "@app/utils";

const StyledModal = styled(MultiSectionModal)(() => ({
  width: 800
}));

const AssetList = styled(Grid)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
  gap: theme.spacing(2)
}));

const AssetItemBox = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: 100,
  overflow: "hidden",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[300],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",

  img: {
    width: "100%",
    height: "100%",
    objectFit: "content"
  },

  "&.selected": {
    outline: `2px solid ${theme.palette.primary.main}`
  }
}));

const AssetItemLabel = styled(Typography)(() => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  fontSize: "0.8em",
  fontWeight: "bold",
  textShadow: "0 0 2px rgba(255, 255, 255, 1)"
}));

export interface AssetPickerModalProps {
  open?: boolean;
  mimeType?: Accept;
  multiple?: boolean;
  onUpload?: (file: File[]) => void;
  onSubmit?: (assets: GameAssetDto[]) => void;
  onClose?: () => void;
}

interface UploadProps {
  accept?: Accept;
  onDrop?: (files: File[]) => void;
}

interface AssetItemProps {
  asset: GameAssetDto;
  onSelect?: (asset: GameAssetDto) => void;
  onQuickSelect?: (asset: GameAssetDto) => void;
  selected?: boolean;
}

function ComponentUpload(props: UploadProps) {
  const { t } = useTranslation();
  const { notify } = useAppContext();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: props.accept,
    onDrop: function (files: File[]) {
      if (files.length === 0) {
        // No valid files were dropped
        notify(t("common.uploadInvalidFile"), "error");
        return;
      }

      props.onDrop?.(files);
    }
  });

  return (
    <UploadBox {...getRootProps()} className={isDragActive ? "dragging" : ""}>
      <AddIcon fontSize='large' />
      {t("common.uploadFromComputer")}
      <input {...getInputProps()} />
    </UploadBox>
  );
}

function AssetItem(props: AssetItemProps) {
  const { asset, onSelect, onQuickSelect, selected } = props;

  const handleOnSelect = useCallback(() => {
    onSelect?.(asset);
  }, [asset, onSelect]);

  const handleQuickSelect = useCallback(() => {
    onQuickSelect?.(asset);
  }, [asset, onQuickSelect]);

  return (
    <AssetItemBox onClick={handleOnSelect} onDoubleClick={handleQuickSelect} className={selected ? "selected" : ""}>
      {asset.type === AssetType.IMAGE && <Image src={asset.url} />}
      {asset.type === AssetType.AUDIO && <Audio src={asset.url} />}
      {asset.type === AssetType.VIDEO && <Video src={asset.url} />}
      {asset.type === AssetType.OTHER && <FileIcon />}
      <AssetItemLabel textAlign='center'>{asset.name}</AssetItemLabel>
    </AssetItemBox>
  );
}

export function AssetPickerModal(props: AssetPickerModalProps) {
  const { open = true, multiple, onClose } = props;
  const { t, ts } = useTranslation();
  const { gameAssets } = useEditor();

  const [selection, setSelection] = useState<GameAssetDto[]>([]);

  const handleSelect = useCallback(
    (asset: GameAssetDto) => {
      if (selection.includes(asset)) {
        setSelection((prev) => prev.filter((a) => a !== asset));
        return;
      }

      setSelection((prev) => (multiple ? [...prev, asset] : [asset]));
    },
    [multiple, selection]
  );

  const handleUpload = useCallback(
    (files: File[]) => {
      props.onUpload?.(files);
    },
    [props]
  );

  const handleCancel = useCallback(() => {
    setSelection([]);
    onClose?.();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    props.onSubmit?.(selection);
    setSelection([]);
    onClose?.();
  }, [onClose, props, selection]);

  const handleSelectAndSubmit = useCallback(
    (asset: GameAssetDto) => {
      props.onSubmit?.([asset]);
      setSelection([]);
      onClose?.();
    },
    [onClose, props]
  );

  // TODO: Switch to MultiSectionModal

  const types = useMemo(() => {
    return Object.values(props.mimeType || {})
      .flat()
      .map(getAssetTypeFromMimeType);
  }, [props.mimeType]);

  const getTitle = useCallback(
    (multiple = false) => {
      if (types.length !== 1) {
        return t(multiple ? "editor.selectAsset" : "editor.selectAssets");
      }

      switch (types[0]) {
        case AssetType.IMAGE:
          return t(multiple ? "editor.selectImage" : "editor.selectImage");
        case AssetType.AUDIO:
          return t(multiple ? "editor.selectAudio" : "editor.selectAudio");
        case AssetType.VIDEO:
          return t(multiple ? "editor.selectVideo" : "editor.selectVideos");
        default:
          return t(multiple ? "editor.selectAsset" : "editor.selectAssets");
      }
    },
    [t, types]
  );

  const footer = useMemo(
    () => (
      <>
        <Button onClick={handleCancel} variant='contained' color='info'>
          {selection?.length ? t("common.cancel") : t("common.close")}
        </Button>
        <Button onClick={handleSubmit} variant='contained' disabled={!selection?.length}>
          {t("common.apply")}
        </Button>
      </>
    ),
    [handleCancel, handleSubmit, selection?.length, t]
  );

  return (
    <StyledModal open={open} onClose={onClose} footer={footer}>
      <ModalSection id='game-assets' title={getTitle(multiple)}>
        <ComponentUpload onDrop={handleUpload} accept={props.mimeType} />
        {gameAssets && (
          <AssetList>
            {gameAssets
              .filter(({ type }) => !types.length || types.includes(type || ""))
              .map((asset) => (
                <AssetItem
                  key={asset.path}
                  asset={asset}
                  onSelect={handleSelect}
                  onQuickSelect={handleSelectAndSubmit}
                  selected={selection.includes(asset)}
                />
              ))}
          </AssetList>
        )}
        {gameAssets && !gameAssets.length && <Empty title={ts("editor.noAssets")} />}
        {!gameAssets && <CircularProgress />}
      </ModalSection>
    </StyledModal>
  );
}
