import type { JsonSchema, ModalTabsLayout, TabElement } from "@shared/form-builder";
import type { PluginManifest } from "@shared/utils/plugins";
import { type UfoFeatureDefinitions } from "@shared/utils/features";

export const getLevelSupportedActions = () => ({
  enter: { title: "Entrar en la escena" },
  leave: { title: "Salir de la escena" }
});

export function getActionsSchema(actionsMap: PluginManifest["actions"] = {}): JsonSchema {
  return {
    type: "object",
    properties: {
      condition: {
        type: "string",
        title: "Condición",
        description: `Las condiciones te permiten decidir cuándo este elemento estará activo o visible en el juego.

          Puedes usar estas variables para crear tus condiciones:
          - \`score\`: Los puntos que tiene el jugador
          - \`time\`: Cuántos segundos han pasado desde que empezó el juego
          - \`timeLeft\`: Cuántos segundos quedan de juego
          - \`keys\`: Lista de puzzles que el jugador ha resuelto
          - \`visitedLevels\`: Lista de niveles que el jugador ha visitado
          - \`progress\`: Qué porcentaje del juego se ha completado

          También puedes usar cualquier variable que hayas creado en las acciones.

          Algunos ejemplos:
          - \`score > 100\` (el elemento se activará cuando el jugador tenga más de 100 puntos)
          - \`"puzzle" in keys\` (el elemento se activará cuando el jugador haya resuelto el puzzle)
          - \`"level1" in visitedLevels\` (el elemento se activará cuando el jugador haya visitado el nivel 1)
          - \`"puzzle" in keys and score < 100\` (el elemento se activará cuando tenga el puzzle Y menos de 100 puntos)

          Para más detalles, consulta la documentación en **UFOAcademy**.
        `
      },

      actions: {
        type: "object",
        title: "Acciones",
        description: "Acciones a realizar con este elemento",
        default: {},
        properties: Object.keys(actionsMap).reduce(
          (acc, actionId) => {
            acc[actionId] = {
              type: "array",
              title: actionsMap[actionId].title,
              description: actionsMap[actionId].description,
              default: [],
              items: {
                type: "object",
                title: "Acción",
                description: "Acción a realizar",
                properties: {
                  command: {
                    type: "string",
                    title: "Comando",
                    description: "Comando a ejecutar"
                  },
                  payload: {
                    type: "string",
                    title: "Datos",
                    description: "Datos adicionales para la acción"
                  }
                }
              }
            };
            return acc;
          },
          {} as Record<string, JsonSchema>
        )
      }
    }
  };
}

export function getActionsUISchema(
  actionsMap: PluginManifest["actions"] = {},
  forbiddenActions: string[],
  featureFlags: UfoFeatureDefinitions
): ModalTabsLayout {
  return {
    type: "ModalTabsLayout",
    tabs: [
      ...(Object.keys(actionsMap).map((actionId) => ({
        title: actionsMap[actionId].title,
        elements: [
          {
            type: "Control",
            scope: `#/properties/actions/properties/${actionId}`,
            options: {
              customControl: "ActionPicker2",
              forbiddenActions,
              advanced: featureFlags["editor-actions-variables-conditions"]
            }
          }
        ]
      })) as TabElement[]),
      ...(featureFlags["editor-actions-variables-conditions"]
        ? ([
            {
              title: "Condiciones",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/condition",
                  options: {
                    multi: true,
                    placeholder: "Escribe una condición"
                  }
                }
              ]
            }
          ] as TabElement[])
        : [])
    ]
  };
}
