import { useState, useContext, useEffect, useMemo } from "react";
import { useDrop } from "react-dnd";
import { Box, CircularProgress } from "@mui/material";

import { type GamePluginDto } from "@shared/api-client";
import { ComponentContext } from "@shared/game-player";
import { AssetType, type Asset } from "@shared/game-engine";
import { GamePluginType } from "@shared/utils/plugins";

import { mergeDeep } from "@app/utils";
import { useEditor } from "@app/editor/useEditor";
import type { EditorDropItem } from "@app/types";

import { ComponentPickerList } from "./ComponentPickerList";
import { extendTemplatesFromAssets } from "./utils";
import { ComponentUpload } from "./ComponentUpload";
import { ComponentTrash } from "./ComponentTrash";

import type { ComponentPickerItem } from "./types";
import { SearchBox } from "@app/components/common/SearchBox";
import { useTranslation } from "@app/hooks";

// Supported media types are limited to the ones that are supported by
// built-in components.
const MediaTypes = {
  image: ["image/*"],
  audio: ["audio/*"],
  video: ["video/*"]
};

interface Props {
  components: GamePluginDto[];
  onSelect?: (id: string, data: ComponentPickerItem) => void;
  onUpload?: (files: File[]) => void;
  onDelete?: (asset: Asset, mustConfirm?: boolean) => void;
  allowSearch?: boolean;
  allowFilters?: boolean;
}

function normalize(str: string) {
  return str
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .trim()
    .toLowerCase();
}

export function ComponetPickerFlatten(props: Props) {
  const { components, onSelect, onUpload, onDelete, allowSearch, allowFilters } = props;
  const [search, setSearch] = useState<string>("");
  const [filter, setFilter] = useState<AssetType[]>([]);
  const [items, setItems] = useState<ComponentPickerItem[] | null>();
  const [filteredItems, setFilteredItems] = useState<ComponentPickerItem[] | null>();
  const [loading, setLoading] = useState(0);
  const [dragging, setDragging] = useState(false);
  const { gameAssets, gameDataInfo } = useEditor();

  const { t } = useTranslation();

  const {
    plugins: { getPluginManifest }
  } = useContext(ComponentContext);

  // Drop component (trash)
  const [, drop] = useDrop<EditorDropItem>(() => ({
    accept: GamePluginType.GAME_COMPONENT,
    drop: (item, monitor) => {
      if (item.asset && monitor.isOver({ shallow: true })) {
        // Delete the item
        onDelete?.(item.asset, item.inUse);
      }
    }
  }));

  // Load component manifests and expand templates
  useEffect(() => {
    setItems(null);
    setLoading(0);

    let cancelled = false;

    components.forEach((component) => {
      setLoading((prevLoading) => prevLoading + 1);
      getPluginManifest(component.uuid, component.version.toString()).then((manifest) => {
        if (cancelled) {
          return;
        }

        if (!manifest) {
          setLoading((prevLoading) => prevLoading - 1);
          return;
        }

        const templates = manifest?.templates || [];
        const templateDefaults = manifest?.templateDefaults || {};

        const items: ComponentPickerItem[] = templates.map((template) => ({
          name: template.name || component.name || manifest.name,
          thumbnail: template.thumbnail || manifest.thumbnail || component.thumbnail,
          component,
          manifest,
          template: mergeDeep(templateDefaults, template)
        }));

        // Append assets as templates
        if (gameAssets) {
          items.push(...extendTemplatesFromAssets(component, manifest, gameAssets, gameDataInfo?.assets));
        }

        setItems((prevItems) => [...(prevItems || []), ...items]);
        setLoading((prevLoading) => prevLoading - 1);
      });
    });

    return () => {
      cancelled = true;
      setLoading(0);
    };
  }, [components, gameAssets, gameDataInfo?.assets, getPluginManifest]);

  useEffect(() => {
    if (allowSearch && (search || filter?.length) && items) {
      setFilteredItems(
        items.filter((item) => {
          const haystack = `${item.component.name} ${item.component.description} ${item.name}`;
          const nameMatch = search ? normalize(haystack).includes(normalize(search)) : true;
          const typeMatch = filter?.length && item.asset ? filter.includes(item.asset.type) : true;

          return nameMatch && typeMatch;
        })
      );
    } else {
      setFilteredItems(items);
    }
  }, [search, items, allowSearch, filter]);

  const filters = useMemo(
    () =>
      allowFilters
        ? [
            { label: t("fields.assetType.image"), value: AssetType.IMAGE },
            { label: t("fields.assetType.audio"), value: AssetType.AUDIO },
            { label: t("fields.assetType.video"), value: AssetType.VIDEO }
          ]
        : [],
    [allowFilters, t]
  );

  return (
    <Box display='flex' flexDirection='column' alignContent='stretch' alignItems='stretch' height='100%'>
      {allowSearch && <SearchBox onSearch={setSearch} onFilter={setFilter} filters={filters} />}
      {onUpload && !dragging && <ComponentUpload onDrop={onUpload} accept={MediaTypes} />}
      {onDelete && dragging && <ComponentTrash ref={drop} />}
      <Box flex={1} overflow='auto'>
        {filteredItems ? (
          <ComponentPickerList
            items={filteredItems}
            onSelect={onSelect}
            onDragStart={() => setDragging(true)}
            onDragEnd={() => setDragging(false)}
          />
        ) : null}
        {loading > 0 ? (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
