import { CardContent, Chip, Typography, useTheme } from "@mui/material";

import { ComposedImage, ProjectCardWrapper } from "@app/components";
import { type LibraryCollectionDto } from "@shared/api-client";
import { AccessLevel } from "@app/types";
import { useTranslation } from "@app/hooks";

interface Props {
  collection: LibraryCollectionDto;
  onClick: () => void;
}

export function CollectionCard({ collection, onClick }: Props) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ProjectCardWrapper onClick={onClick}>
      <ComposedImage bg={collection.thumbnail} title={collection.name} />
      {collection.accessLevel === AccessLevel.PUBLIC && (
        <Chip className='badge' label={t("library.public")} variant='filled' color='success' size='small' />
      )}
      <div className='CardFooter'>
        <CardContent>
          <Typography variant='h6' mb={theme.spacing(1)}>
            {collection.name}
          </Typography>
          <Typography variant='subtitle1'>{collection.description}</Typography>
        </CardContent>
      </div>
    </ProjectCardWrapper>
  );
}
