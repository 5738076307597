/* eslint-disable react-perf/jsx-no-new-array-as-prop */
import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  styled,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";

import range from "lodash/range";

import dayjs, { type ManipulateType } from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

import { useAppContext, useBackend, useTagManager, useTranslation } from "@app/hooks";

import { BarChartComponent } from "@app/components/charts/BarChart";
import { BigCountChart } from "@app/components/charts/BigCountChart";
import { CountChart } from "@app/components/charts/CountChart";
import { type GameDefinitionDto, type MetricsOverviewDto } from "@shared/api-client";
import { ProjectCard, type ProjectCardProps } from "@app/components/projects/ProjectCard";

import UfitoImg from "@shared/branding/imagery/art/ufito-cohete.png";
import { ProjectModal } from "@app/components/projects/ProjectModal";
import { GameStatus } from "@app/types";
import { SectionTitle } from "@app/components";
import { ArcChart } from "@app/components/charts/ArcChart";

import { InfoOutlined } from "@mui/icons-material";
import { useNavigate } from "@app/router";

const ALL = "all";
const START_DATE = dayjs("2025-03-01");

const ChartPaper = styled(Paper)(({ theme }) => ({
  boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
  borderRadius: theme.shape.borderRadius,
  height: "100%",
  overflow: "hidden"
}));

const FiltersForm = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
  justifyContent: "flex-start",
  gap: theme.spacing(2),
  padding: theme.spacing(2)
}));

const FilterItem = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "stretch",
  padding: theme.spacing(2),

  "& .MuiInputLabel-root": {
    textTransform: "uppercase",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: "bold",
    color: theme.palette.text.secondary
  },

  "& .MuiInputBase-root": {
    backgroundColor: "transparent",
    "&:active": {
      backgroundColor: "transparent"
    }
  }
}));

const timeOptions = ["7d", "30d", "3m", "6m", "1y", ALL];

const Placeholder = ({ height = "100%" }: { height?: number | string }) => (
  <Skeleton variant='rounded' height={height} animation='pulse' />
);

const GameGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

export function ProjectItem(props: ProjectCardProps) {
  return (
    <Grid item xs={12} sm={12} md={6} lg={4}>
      <ProjectCard {...props} />
    </Grid>
  );
}

type FilterForm = {
  gameUuid?: string[];
  period?: string;
};

function formatSeconds(seconds: number) {
  const time = dayjs.duration(seconds, "seconds");

  if (time.asDays() > 1) {
    return time.format("D[d], H[h] m[m]");
  }

  if (time.asHours() > 1) {
    return time.format("H[h] m[m]");
  }

  return time.format("m[m] s[s]");
}

export default function Dashboard() {
  const { setPageTitle } = useAppContext();
  const { dashboard } = useBackend();
  const { t, ts } = useTranslation();
  const theme = useTheme();
  const tm = useTagManager();
  const navigate = useNavigate();

  const [filter, setFilter] = useState<FilterForm>({ period: "7d" });
  const [overview, setOverview] = useState<MetricsOverviewDto | null>(null);
  const [publishedGames, setPublishedGames] = useState<GameDefinitionDto[] | null>(null);
  const [showPeriodInfo, setShowPeriodInfo] = useState(false);

  const [selection, setSelection] = useState<GameDefinitionDto | null>(null);

  useEffect(() => {
    const endDate = dayjs();

    const search = {
      gameUuid: !filter.gameUuid?.includes(ALL) ? filter.gameUuid : undefined,
      endDate: endDate.toISOString(),
      startDate: "",
      topGames: 5
    };

    if (filter.period && filter.period !== ALL) {
      const period = parseInt(filter.period);
      let unit: ManipulateType = "days";

      if (filter.period.endsWith("d")) {
        unit = "days";
      } else if (filter.period.endsWith("m")) {
        unit = "months";
      } else if (filter.period.endsWith("y")) {
        unit = "years";
      }

      search.startDate = endDate.subtract(period, unit).toISOString();

      // Show period info if the period is before the start date
      setShowPeriodInfo(dayjs(search.startDate).isBefore(START_DATE));
    }

    dashboard
      .get({
        path: "/metrics/overview",
        search
      })
      .then((overview) => {
        setOverview(overview);
      });
  }, [dashboard, filter]);

  useEffect(() => {
    dashboard
      .getList({
        path: "/games",
        search: {
          status: GameStatus.PUBLISHED
        }
      })
      .then((games) => {
        const sortedGames = Array.from(games.items).sort((a, b) => a.name?.localeCompare(b.name ?? "") ?? 0);
        setPublishedGames(sortedGames);
      });
  }, [dashboard]);

  useEffect(() => {
    setPageTitle(t("overview.title"));
    return () => setPageTitle("");
  }, [setPageTitle, t]);

  const playGame = useCallback(
    (game: GameDefinitionDto) => {
      tm("game_play", {
        game_uuid: game.uuid,
        game_name: game.name
      });

      window.open(import.meta.env.VITE_PLAYER_URL.replace(":uuid", game.uuid), "player");
    },
    [tm]
  );

  const editGame = useCallback(
    (game: GameDefinitionDto) => {
      tm("game_edit", {
        game_uuid: game.uuid
      });

      navigate("/dashboard/projects/:uuid", {
        params: { uuid: game.uuid }
      });
    },
    [navigate, tm]
  );

  return (
    <Container>
      <Grid container spacing={8} columns={12}>
        <Grid item xs={12}>
          <ChartPaper>
            <FiltersForm>
              <FilterItem flexBasis={200}>
                <InputLabel>{t("overview.filters.game")}</InputLabel>
                <Select
                  fullWidth
                  variant='standard'
                  value={filter.gameUuid?.[0] || ALL}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      gameUuid: e.target.value ? [String(e.target.value)] : undefined
                    })
                  }
                >
                  <MenuItem value={ALL}>{t("overview.filters.all")}</MenuItem>
                  {publishedGames &&
                    publishedGames.map((game) => (
                      <MenuItem key={game.uuid} value={game.uuid}>
                        {game.name}
                      </MenuItem>
                    ))}
                </Select>
              </FilterItem>
              <FilterItem flexBasis={200}>
                <InputLabel>
                  {t("overview.filters.period")}
                  {showPeriodInfo && (
                    <Tooltip title={t("overview.filters.periodInfo")} placement='top'>
                      <InfoOutlined color='disabled' sx={{ fontSize: "16px", marginLeft: "4px", verticalAlign: -3 }} />
                    </Tooltip>
                  )}
                </InputLabel>
                <Select
                  fullWidth
                  variant='standard'
                  value={filter.period || ""}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      period: e.target.value
                    })
                  }
                >
                  {timeOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {t(`overview.periods.${option}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FilterItem>
            </FiltersForm>
          </ChartPaper>
        </Grid>
        <Grid item md={3} xs={12}>
          {overview ? (
            <ChartPaper>
              <BigCountChart
                value={overview.publishedGames}
                total={overview.totalGames}
                valueLabel={ts("overview.metrics.publishedGames")}
                totalLabel={ts("overview.metrics.totalGames")}
              />
            </ChartPaper>
          ) : (
            <Placeholder height={270} />
          )}
        </Grid>
        <Grid item md={9} xs={12}>
          <ChartPaper>
            {overview ? (
              <BarChartComponent
                title={ts("overview.metrics.topGames")}
                data={overview?.topGames
                  .slice(0, 5)
                  .map((topGame) => ({ label: topGame.name, value: topGame.playCount }))}
                height={270}
              />
            ) : (
              <Placeholder height={270} />
            )}
          </ChartPaper>
        </Grid>
        <Grid item lg={3} md={6} xs={12}>
          {overview ? (
            <ChartPaper>
              <CountChart
                value={overview.uniqueUsers}
                label={ts("overview.metrics.uniqueUsers")}
                info={t("overview.metrics.uniqueUsersInfo")}
              />
            </ChartPaper>
          ) : (
            <Placeholder height={100} />
          )}
        </Grid>
        <Grid item lg={3} md={6} xs={12}>
          {overview ? (
            <ChartPaper>
              <CountChart
                value={overview.playCount}
                label={ts("overview.metrics.playCount")}
                info={t("overview.metrics.playCountInfo")}
              />
            </ChartPaper>
          ) : (
            <Placeholder height={100} />
          )}
        </Grid>
        <Grid item lg={3} md={6} xs={12}>
          {overview ? (
            <ChartPaper>
              <CountChart
                value={formatSeconds(overview.totalTime)}
                label={ts("overview.metrics.totalTime")}
                info={t("overview.metrics.totalTimeInfo")}
              />
            </ChartPaper>
          ) : (
            <Placeholder height={100} />
          )}
        </Grid>
        <Grid item lg={3} md={6} xs={12}>
          {overview ? (
            <ChartPaper>
              <CountChart
                value={formatSeconds(overview.averageTime)}
                label={ts("overview.metrics.averageTime")}
                info={t("overview.metrics.averageTimeInfo")}
              />
            </ChartPaper>
          ) : (
            <Placeholder height={100} />
          )}
        </Grid>
        <Grid item xs={12}>
          {overview ? (
            <ChartPaper>
              <ArcChart
                data={[
                  {
                    value: overview.completionCount,
                    label: ts("overview.metrics.completionCount")
                  },
                  {
                    value: overview.playCount - overview.completionCount - overview.inProgressCount,
                    label: ts("overview.metrics.failedCount")
                  }
                ]}
                colors={["#009900", "#CCCCCC"]}
                info={ts("overview.metrics.completionVsFailedInfo")}
              />
            </ChartPaper>
          ) : (
            <Placeholder height={200} />
          )}
        </Grid>
      </Grid>

      <SectionTitle title={t("overview.publishedGames")} />

      <GameGrid container spacing={2}>
        {!publishedGames &&
          range(0, 3).map((index) => (
            <Grid item xs={12} sm={12} md={6} lg={4} key={"publishedGame-" + index}>
              <Placeholder key={index} height={240} />
            </Grid>
          ))}

        {publishedGames?.map((game) => (
          <ProjectItem key={game.uuid} game={game} onClick={() => setSelection?.(game)} />
        ))}

        {publishedGames && !publishedGames.length && (
          <Grid item xs={12} textAlign='center' marginTop={theme.spacing(8)}>
            <img src={UfitoImg} height='200px' />
            <Typography variant='body2' mt={8} mb={4}>
              {t("overview.noGames")}
            </Typography>
          </Grid>
        )}
      </GameGrid>

      {selection && (
        <ProjectModal
          open={!!selection}
          onClose={() => setSelection(null)}
          game={selection}
          onEdit={() => editGame(selection)}
          onPlay={() => playGame(selection)}
        />
      )}
    </Container>
  );
}
