import type { GameDataInterface, GameLayerContent } from "@shared/game-engine";
import { ApiRestClient } from "../ApiRest";
import type {
  GameAssetDto,
  GameDefinitionDto,
  GameDataInfoDto,
  GameRevisionDto,
} from "../dtos";

type Routes = {
  "/": GameDefinitionDto;
  "/:uuid": GameDefinitionDto;
  "/:uuid/rev": GameRevisionDto;

  // Raw data
  "/:uuid/rev/:version": GameDataInterface;
  "/:uuid/rev/:version/levels/:levelId": GameLayerContent;
  "/:uuid/rev/:version/overlays/:overlayId": GameLayerContent;
  "/:uuid/rev/:version/info": GameDataInfoDto;
  "/:uuid/rev/:version/publish": void;

  "/:uuid/assets": GameAssetDto;
  "/:uuid/assets/:type/:name": GameAssetDto;

  "/:uuid/export/scorm": void;
};

export type GameManagerApiPath = keyof Routes;

export class GameManagerApiClient extends ApiRestClient<Routes> {
  constructor(baseUrl: string) {
    super(baseUrl.replace(/\/$/, "") + "/games");
  }
}
