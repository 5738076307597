import React, { useMemo, useRef, useState, useEffect } from "react";
import { Container, styled, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";

interface Props {
  title: string;
  data: { label: string; value: number }[];
  height: number;
}

const Title = styled(Typography)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(4),
  left: theme.spacing(4),
  right: theme.spacing(4),
  color: theme.palette.text.primary,
  textAlign: "center"
}));

export const BarChartComponent: React.FC<Props> = ({ title, data, height }) => {
  const formattedData = useMemo(
    () =>
      data.map((d) => {
        let label = d.label;

        if (label.length > 20) {
          const spaceIndexes = [...label.slice(0, 20).matchAll(/\s/g)].map((match) => match.index);

          if (spaceIndexes.length > 0) {
            const lastSpaceIndex = spaceIndexes[spaceIndexes.length - 1];
            label = label.slice(0, lastSpaceIndex) + "\n" + label.slice(lastSpaceIndex + 1);
          }

          return `${label}\n${d.value}`;
        }

        return `${d.label}\n \n${d.value}`;
      }),
    [data]
  );

  const componentRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    if (componentRef.current) {
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          setWidth(entry.contentRect.width);
        }
      });
      observer.observe(componentRef.current);

      return () => observer.disconnect();
    }
  }, []);

  const series = useMemo(
    () => [
      // Transparent bar to fill space
      { data: data.map(() => 0), color: "transparent" },
      { data: data.map((d) => d.value), color: "#81c8a5" },
      { data: data.map(() => 0), color: "transparent" }
    ],
    [data]
  );

  const xAxis = useMemo(
    () => [
      {
        data: formattedData,
        scaleType: "band" as const,
        tickLabelStyle: {
          fontSize: "12px",
          fontWeight: "normal",
          textAnchor: "middle" as const,
          whiteSpace: "pre"
        }
      }
    ],
    [formattedData]
  );

  const containerStyles = useMemo(
    () => ({
      position: "relative",
      width: "100%",
      height: height,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      pt: 5,
      pb: 5
    }),
    [height]
  );
  const gridConfig = useMemo(() => ({ vertical: true, horizontal: true }), []);
  const slotProps = useMemo(() => ({ bar: { rx: 7.5 } }), []);
  const tooltip = useMemo(() => ({ trigger: "none" as const }), []);

  const sxStyles = useMemo(
    () => ({
      "& .MuiChartsAxis-tickLabel": {
        "& tspan": {
          "&:nth-of-type(3)": {
            fontSize: "18px",
            fontWeight: "900"
          }
        }
      }
    }),
    []
  );

  return (
    <Container sx={containerStyles} ref={componentRef}>
      <Title variant='h6'>{title}</Title>
      <BarChart
        series={series}
        xAxis={xAxis}
        width={width}
        height={height}
        grid={gridConfig}
        slotProps={slotProps}
        sx={sxStyles}
        tooltip={tooltip}
        margin={{ top: 30, right: -20, bottom: 60, left: -20 }}
      />
    </Container>
  );
};
