import { DEFAULT_LANGUAGE } from "./consts";
import { GameData } from "./GameData";
import { GameStateInterface, GameVariableValue } from "./types";
import { v4 as uuidv4 } from "uuid";

export class GameState implements GameStateInterface {
  timeOffset: number = 0;
  gameData: GameData;
  uuid: string = uuidv4();
  gameUuid: string = "";
  gameVersion: number = 1;
  language: string = DEFAULT_LANGUAGE;
  currentLevel: string = "";
  inventory: string[] = [];
  keys: string[] = [];
  clues: string[] = [];
  visitedLevels: string[] = [];
  variables: Record<string, GameVariableValue> = {};
  score: number = 0;
  penalties: number = 0;
  startedAt?: Date;
  expiresAt?: Date;
  completedAt?: Date;

  constructor(gameData: GameData, initialState?: Partial<GameStateInterface>) {
    this.gameData = gameData;

    if (initialState) {
      this.uuid = initialState.uuid || this.uuid;
      this.gameUuid = initialState.gameUuid || this.gameUuid;
      this.gameVersion = initialState.gameVersion || this.gameVersion;
      this.language = initialState.language || this.language;
      this.currentLevel = initialState.currentLevel || this.currentLevel;
      this.inventory = initialState.inventory || this.inventory;
      this.keys = initialState.keys || this.keys;
      this.clues = initialState.clues || this.clues;
      this.visitedLevels = initialState.visitedLevels || this.visitedLevels;
      this.variables = initialState.variables || this.variables;
      this.score = initialState.score || this.score;
      this.penalties = initialState.penalties || this.penalties;
      this.startedAt = initialState.startedAt || this.startedAt;
      this.expiresAt = initialState.expiresAt || this.expiresAt;
      this.completedAt = initialState.completedAt || this.completedAt;
    }
  }

  /**
   * Is the game in progress?
   *
   * @returns
   */
  isInProgress(): boolean {
    return !this.isCompleted() && !this.isExpired();
  }

  /**
   * Is the game completed?
   *
   * @returns
   */
  isCompleted(): boolean {
    return !!this.completedAt;
  }

  /**
   * Is the game expired?
   *
   * @param now
   * @returns
   */
  isExpired(now = new Date()): boolean {
    return (
      !this.isCompleted() && (this.expiresAt ? now >= this.expiresAt : false)
    );
  }

  /**
   * Calculate the time spent in the game while the clock was running.
   * If the game is completed, it returns the time spent to complete the game
   *
   * @param at
   * @returns
   */
  getSpentTime(at = new Date()): number {
    // current time in seconds
    return this.startedAt
      ? Math.floor(
          ((this.completedAt ? this.completedAt.valueOf() : at.valueOf()) -
            this.startedAt.valueOf()) /
            1000
        )
      : 0;
  }

  get spentTime(): number {
    return this.getSpentTime();
  }

  /**
   * Calculate the time left in the game.
   * If the game is completed and has expiration, it returns the remaining time.
   * If the game is expired, it returns 0.
   *
   * @param at
   * @returns
   */
  getTimeLeft(at = new Date()): number {
    if (this.completedAt) {
      return this.expiresAt
        ? (this.expiresAt.valueOf() - this.completedAt.valueOf()) / 1000
        : 0;
    }

    // time left in seconds
    return this.startedAt && this.expiresAt
      ? Math.floor(
          (this.expiresAt.valueOf() - at.valueOf() - this.timeOffset) / 1000
        )
      : 0;
  }

  get timeLeft(): number {
    return this.getTimeLeft();
  }

  /**
   * Calculate the completion percentage (0-100) of the game.
   * It is based on the keys, items collected and visited levels.
   */
  get completion(): number {
    const totalLocks = this.gameData.getAllLockIds().length;
    const totalItems = this.gameData.getAllItemIds().length;
    const totalLevels = this.gameData.getAllLevelIds().length;

    const keysCompletion = totalLocks ? this.keys.length / totalLocks : 0;
    const itemsCompletion = totalItems ? this.inventory.length / totalItems : 0;
    const levelsCompletion = totalLevels
      ? this.visitedLevels.length / totalLevels
      : 0;

    return Math.floor(
      ((keysCompletion + itemsCompletion + levelsCompletion) / 3) * 100
    );
  }

  /**
   * Has the key been collected?
   *
   * @param key
   * @returns
   */
  hasKey(key: string): boolean {
    return this.keys.some((k) => k === key);
  }

  /**
   * Has the item been collected?
   *
   * @param item
   * @returns
   */
  hasItem(item: string): boolean {
    return this.inventory.some((i) => i === item);
  }

  /**
   * Has the clue been consumed?
   *
   * @param clueId
   * @returns
   */
  hasClue(clueId: string): boolean {
    return this.clues.some((i) => i === clueId);
  }

  /**
   * Has the level been visited?
   *
   * @param levelId
   * @returns
   */
  hasVisitedLevel(levelId: string): boolean {
    return this.visitedLevels.some((i) => i === levelId);
  }

  /**
   * Push a key to the inventory
   *
   * @param key
   * @returns
   */
  pushKey(key: string): boolean {
    if (!this.hasKey(key)) {
      this.keys.push(key);
      return true;
    }

    return false;
  }

  /**
   * Push an item to the inventory
   *
   * @param item
   * @returns
   */
  pushItem(item: string): boolean {
    if (!this.hasItem(item)) {
      this.inventory.push(item);
      return true;
    }

    return false;
  }

  /**
   * Push a clue to the inventory
   *
   * @param clueId
   * @returns
   */
  pushClue(clueId: string): boolean {
    if (!this.hasClue(clueId)) {
      this.clues.push(clueId);
      return true;
    }

    return false;
  }

  /**
   * Push a level to the visited levels
   *
   * @param levelId
   * @returns
   */
  pushVisitedLevel(levelId: string): boolean {
    if (!this.hasVisitedLevel(levelId)) {
      this.visitedLevels.push(levelId);
      return true;
    }

    return false;
  }

  /**
   * Get the state as a JSON object
   *
   * @returns
   */
  toJSON(): GameStateInterface {
    return {
      uuid: this.uuid,
      gameUuid: this.gameUuid,
      gameVersion: this.gameVersion,
      language: this.language,
      currentLevel: this.currentLevel,
      inventory: this.inventory,
      keys: this.keys,
      clues: this.clues,
      visitedLevels: this.visitedLevels,
      variables: this.variables,
      score: this.score,
      penalties: this.penalties,
      startedAt: this.startedAt,
      expiresAt: this.expiresAt,
      completedAt: this.completedAt,
    };
  }

  setServerTime(serverTime: number): number {
    this.timeOffset = Date.now() - serverTime;
    return this.timeOffset;
  }

  /**
   * Return an expression-ready object for condition evaluation
   *
   * @returns
   */
  toExpressionVars(): Record<string, string | string[] | number | boolean> {
    // current/remaining time in seconds
    const time = this.getSpentTime();
    const timeLeft = this.getTimeLeft();

    return {
      ...this.variables,
      score: this.score,
      penalties: this.penalties,
      keys: this.keys,
      inventory: this.inventory,
      visitedLevels: this.visitedLevels,
      clues: this.clues,
      time,
      timeLeft,
      formattedTime: `${("0" + Math.floor(time / 60)).slice(-3)}:${("0" + (time % 60)).slice(-2)}`,
      formattedTimeLeft: `${("0" + Math.floor(timeLeft / 60)).slice(-3)}:${("0" + (timeLeft % 60)).slice(-2)}`,
      progress: this.completion,
    };
  }
}
