import { ApiRestClient } from "../ApiRest";
import type { JwtTokenDto } from "../dtos/auth";
import type { UserDto } from "../dtos/user/UserDto";

type Routes = {
  "/login": JwtTokenDto;
  "/oauth": JwtTokenDto;
  "/register": UserDto;
  "/logout": void;
  "/reset-password": void;
  "/update-password": void;
  "/verify-email": void;
};

export class AuthApiClient extends ApiRestClient<Routes> {
  constructor(baseUrl: string) {
    super(baseUrl.replace(/\/$/, "") + "/auth");
  }
}
