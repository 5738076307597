import { useCallback, useState, type MouseEvent } from "react";
import { Button as MuiButton, type ButtonProps, styled, CircularProgress } from "@mui/material";
import { useAppContext } from "@app/hooks";

import { PremiumBadge } from "@shared/branding/theufolab";
import type { Feature } from "@shared/utils/features";

const StyledButton = styled(MuiButton)(({ color = "primary", variant = "contained", theme }) => {
  const palleteColor = color !== "inherit" ? theme.palette[color] : undefined;

  return {
    textTransform: "none",
    fontSize: "1.14rem",
    fontWeight: 600,
    padding: theme.spacing(1, 3),
    boxShadow: "none",
    boxSizing: "border-box",
    whiteSpace: "nowrap",

    ...(variant !== "text"
      ? {
          borderColor: palleteColor?.main,
          borderStyle: "solid",
          borderWidth: "2px",
          borderRadius: "8px",

          "&:hover": {
            borderWidth: "2px",
            borderColor: palleteColor?.main,
            boxShadow: "none"
          },

          "&:disabled": {
            borderColor: theme.palette.action.disabledBackground,
            color: theme.palette.action.disabled
          }
        }
      : {}),

    // Add loading styles
    "&.ButtonLoading": {
      position: "relative",
      pointerEvents: "none",
      opacity: 0.6,

      "& .MuiCircularProgress-root": {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -14,
        marginLeft: -14
      }
    }
  };
});

interface Props<T> extends ButtonProps {
  loading?: boolean;
  feature?: Feature;
  onClick?: (e: MouseEvent) => Promise<T> | void;
  onClickSuccess?: (result: T) => void;
  onClickError?: (error: unknown) => void;
}

export function Button<T>({ loading, children, onClick, onClickSuccess, onClickError, feature, ...props }: Props<T>) {
  const [clickLoading, setClickLoading] = useState(false);
  const { notify } = useAppContext();

  const doClick = useCallback(
    async (event: MouseEvent) => {
      if (!onClick) return;

      const result = onClick(event);

      if (result instanceof Promise) {
        setClickLoading(true);

        try {
          onClickSuccess?.(await result);
        } catch (error) {
          if (onClickError) {
            onClickError(error);
          } else {
            notify(String(error), "error");
            throw error;
          }
        } finally {
          setClickLoading(false);
        }
      }
    },
    [onClick, onClickSuccess, onClickError, notify]
  );

  return (
    <StyledButton
      {...props}
      disabled={clickLoading || loading || props.disabled}
      onClick={doClick}
      className={clickLoading || loading ? "ButtonLoading" : ""}
      endIcon={
        feature ? <img src={PremiumBadge} height={20} style={{ marginLeft: 4 }} alt='Premium feature' /> : undefined
      }
    >
      {children}
      {(loading || clickLoading) && <CircularProgress size={28} />}
    </StyledButton>
  );
}
