import { createContext, useCallback, useContext, useEffect, useMemo, useState, type PropsWithChildren } from "react";
import { type Experiment, GrowthBook, type Result } from "@growthbook/growthbook";

import { type Feature, type UfoFeatureDefinitions, type FeatureUserContext } from "@shared/utils/features";
import { AppContext } from "./AppContextProvider";
import { createLogger } from "@shared/utils/logging";
import { FullScreenLoading } from "@app/components";

// Not using React Context for GrowthBook because
// a conflict with generouted: https://github.com/oedotme/generouted/issues/187#issuecomment-2644743638

// Setup GrowthBook
const growthbook = new GrowthBook({
  apiHost: import.meta.env.VITE_GROWTHBOOK_API_HOST,
  clientKey: import.meta.env.VITE_GROWTHBOOK_API_KEY,
  enableDevMode: true
});

const { info: log } = createLogger("FeaatureFlagProvider", { canLog: () => window.__debug__ });

const setAttributes = async (attributes: FeatureUserContext) => {
  log("setAttributes", attributes);
  await growthbook.setAttributes(attributes);
  await growthbook.refreshFeatures();
};

const setTrackingCallback = function <T>(callback: (experiment: Experiment<T>, result: Result<T>) => void) {
  log("setTrackingCallback", callback);
  return growthbook.setTrackingCallback(callback);
};

const getAllFeatureFlags = (defaults?: boolean) => {
  const features = growthbook.getFeatures();
  log("getAllFeatureFlags", features);
  return Object.keys(features).reduce<UfoFeatureDefinitions>(
    (acc, feature) => ({ ...acc, [feature]: defaults ? features[feature].defaultValue : growthbook.isOn(feature) }),
    {}
  );
};

interface FeatureFlagContextType {
  growthbook: GrowthBook;
  featureFlags?: UfoFeatureDefinitions;
  isOn: (feature: Feature) => boolean;
  getValue: (feature: Feature) => unknown;
  setAttributes: (attributes: FeatureUserContext) => void;
  setTrackingCallback: <T>(callback: (experiment: Experiment<T>, result: Result<T>) => void) => void;
  isReady: boolean;
}

// This also represents the initial state of feature flags
// which means that the feature flags are not yet loaded
export const INITIAL_FEATURE_FLAGS = {};

const initialValue: FeatureFlagContextType = {
  growthbook,
  setAttributes,
  setTrackingCallback,
  isReady: false,
  featureFlags: INITIAL_FEATURE_FLAGS,
  isOn: () => false,
  getValue: () => null
};

export const FeatureFlagContext = createContext<FeatureFlagContextType>(initialValue);

export const FeatureFlagProvider = ({ children }: PropsWithChildren) => {
  const {
    state: { featureFlags },
    dispatch
  } = useContext(AppContext);

  const [isReady, setIsReady] = useState(false);

  const setFeatureFlags = useCallback(
    (featureFlags: UfoFeatureDefinitions) => {
      dispatch({ type: "updateFeatureFlags", payload: featureFlags });
    },
    [dispatch]
  );

  const isOn = useCallback(
    (feature: Feature) => {
      return !!featureFlags[feature] || false;
    },
    [featureFlags]
  );

  const getValue = useCallback(
    (feature: Feature) => {
      return featureFlags[feature];
    },
    [featureFlags]
  );

  useEffect(() => {
    growthbook
      .init({ streaming: true, timeout: 5000 })
      .catch(() => {
        // Error initializing GrowthBook, so we'll use an empty object
        // different from INITIAL_FEATURE_FLAGS because that's a symbol
        setFeatureFlags({ ...INITIAL_FEATURE_FLAGS });
      })
      .finally(() => {
        setIsReady(true);
      });
  }, [setFeatureFlags]);

  useEffect(() => {
    if (isReady) {
      growthbook.setRenderer(() => {
        const features = getAllFeatureFlags();
        setFeatureFlags(features);
        log("setFeatureFlags", features);
      });

      // initial load
      setFeatureFlags(getAllFeatureFlags());
    }
  }, [featureFlags, isReady, setFeatureFlags]);

  const value = useMemo<FeatureFlagContextType>(
    () => ({
      ...initialValue,
      featureFlags,
      isOn,
      getValue,
      isReady
    }),
    [featureFlags, getValue, isOn, isReady]
  );

  if (!isReady || featureFlags === INITIAL_FEATURE_FLAGS) {
    // Wait for GrowthBook to initialize
    return <FullScreenLoading />;
  }

  return <FeatureFlagContext.Provider value={value}>{children}</FeatureFlagContext.Provider>;
};
