import { useEffect, type DetailedHTMLProps, useState, useRef, useCallback, type VideoHTMLAttributes } from "react";
import { Box, type SxProps } from "@mui/material";

import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import PlayIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { useViewportTools } from "@app/hooks";

interface CustomProps {
  boxSx?: SxProps;
}

const center = { position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };

type Props = Pick<DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>, "src"> & CustomProps;

export function Video(props: Props) {
  const { boxSx } = props;

  const { ref, isVisible } = useViewportTools<HTMLVideoElement>();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [playing, setPlaying] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!props.src) {
      setError(true);
      return;
    }
  }, [props.src, isVisible]);

  const play = useCallback(() => {
    videoRef.current?.play();
    setPlaying(true);
  }, []);

  const pause = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.pause();
      setPlaying(false);
    }
  }, []);

  return (
    <Box ref={ref} sx={{ ...boxSx, position: "relative" }}>
      {!error && (
        <video
          ref={videoRef}
          src={props.src}
          style={{ width: "100%", height: "100%" }}
          controlsList='nodownload'
          preload='none'
        />
      )}
      {error && <BrokenImageIcon fontSize='large' sx={{ opacity: 0.3 }} />}
      {playing && <PauseIcon fontSize='large' onClick={pause} sx={center} />}
      {!playing && <PlayIcon fontSize='large' onClick={play} sx={center} />}
    </Box>
  );
}
