import { type ReactNode } from "react";
import { alpha, Box, IconButton, styled, Tooltip, Typography } from "@mui/material";

import { InfoOutlined } from "@mui/icons-material";

interface Props {
  value: number | string;
  label: string;
  info?: ReactNode;
}

const ChartContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "stretch",
  height: "100%"
}));

const DataContainer = styled(Box)(({ theme }) => ({
  flex: "1 1 auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  gap: theme.spacing(2),
  padding: theme.spacing(4)
}));

const InfoContainer = styled(Box)(({ theme }) => ({
  flex: "0 0 40px",
  backgroundColor: alpha(theme.palette.divider, 0.05)
}));

const Label = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 600,
  color: theme.palette.secondary.dark
}));

const Value = styled(Typography)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  paddingTop: theme.spacing(2),
  fontSize: theme.typography.pxToRem(40),
  fontWeight: 600,
  lineHeight: 1,
  color: theme.palette.text.primary
}));

export function CountChart({ value, label, info }: Props) {
  return (
    <ChartContainer>
      <DataContainer>
        <Label>{label}</Label>
        <Value>{value}</Value>
      </DataContainer>
      <InfoContainer>
        {info && (
          <Tooltip title={info}>
            <IconButton>
              <InfoOutlined color='info' />
            </IconButton>
          </Tooltip>
        )}
      </InfoContainer>
    </ChartContainer>
  );
}
