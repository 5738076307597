import { ForwardedRef, forwardRef } from "react";
import {
  Alert,
  Box,
  Card,
  InputLabel,
  Tooltip,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import { Icons } from "./Icons";
import { computeLabel, type UISchemaElement } from "@jsonforms/core";
import { MDViewer } from "@shared/markdown/MDViewer";

type Placement = "default" | "header";

interface Props {
  children?: React.ReactNode;
  placement?: Placement;
  label?: string;
  required?: boolean;
  actions?: React.ReactNode;
  uiSchema?: UISchemaElement;
  description?: string;
  onClick?: (e: React.MouseEvent) => void;
  errors?: string;
}

const StyledBoxWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1, 0, 4, 0),
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(2),
  alignItems: "flex-start",
  justifyContent: "flex-start",

  "&.error": {
    outline: `1px solid ${theme.palette.error.main}`,
  },
}));

const StyledCardWrapper = styled(Card)(({ theme }) => ({
  margin: theme.spacing(0, 0, 2, 0),
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(2),
  alignItems: "flex-start",
  justifyContent: "flex-start",
  overflow: "visible",

  "&.error": {
    outline: `1px solid ${theme.palette.error.main}`,
    backgroundColor: alpha(theme.palette.error.light, 0.1),
  },
}));

const FieldPadder = styled(Box)(() => ({
  flex: 1,
}));

const FieldHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: theme.spacing(1),
  gap: theme.spacing(2),
}));

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: "bold",
  fontSize: "1rem",
}));

function FieldWrapperElement(props: Props, ref: ForwardedRef<HTMLDivElement>) {
  const placement = props.placement || "default";
  const { flatStyle, info, icon, hideRequiredAsterisk } =
    props.uiSchema?.options || {};

  const Wrapper = flatStyle ? StyledBoxWrapper : StyledCardWrapper;

  const computedLabel = computeLabel(
    props.label,
    props.required || false,
    hideRequiredAsterisk
  );

  const translatedError = props.errors
    ? props.uiSchema?.options?.errors?.[props.errors] || props.errors
    : "";

  return (
    <Wrapper
      elevation={0}
      ref={ref}
      onClick={props.onClick}
      className={props.errors ? "error" : ""}
    >
      {icon ? Icons[icon] : undefined}
      <FieldPadder>
        <FieldHeader>
          {computedLabel && (
            <StyledInputLabel>{computedLabel}</StyledInputLabel>
          )}
          {info && (
            <Tooltip title={info} placement="top">
              {Icons.info}
            </Tooltip>
          )}
          <Box flex="1" />
          {placement === "header" && props.children}
          {props.actions}
        </FieldHeader>
        {props.description && (
          <Typography variant="body1" mb={1}>
            <MDViewer content={props.description} component="span" />
          </Typography>
        )}
        {placement === "default" && props.children}
        {translatedError && <Alert severity="error">{translatedError}</Alert>}
      </FieldPadder>
    </Wrapper>
  );
}

export const FieldWrapper = forwardRef(FieldWrapperElement);
