import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Masonry from "react-responsive-masonry";
import { Box, CircularProgress, styled } from "@mui/material";

import { type LibraryCollectionItemDto } from "@shared/api-client";
import { type Asset } from "@shared/game-engine";

import { useBackend } from "@app/hooks";
import { SearchBox } from "@app/components/common/SearchBox";
import { Empty } from "@app/components/Empty";

import { LibraryItem } from "./LibraryItem";

const HorizontalList = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "nowrap",
  overflowX: "auto",
  overflowY: "hidden",
  padding: theme.spacing(2),
  position: "relative",

  "> *": {
    flex: "0 0 auto",
    height: 100,
    maxWidth: "min(75%, 180px)"
  }
}));

const Scroller = styled(Box)(({ theme }) => ({
  position: "absolute",
  zIndex: 1,
  top: 0,
  left: 0,
  width: "40px",
  height: "100%",
  overflow: "hidden",
  pointerEvents: "none",

  opacity: 0,
  transition: theme.transitions.create(["opacity"], {
    duration: theme.transitions.duration.standard
  }),

  "&.left": {
    left: 0,
    right: "auto",
    background: "linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))"
  },

  "&.right": {
    left: "auto",
    right: 0,
    background: "linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))"
  },

  "&.enabled": {
    opacity: 1
  }
}));

interface Props {
  title?: string;
  libraryUuid: string;
  categoryId?: number;
  components?: string[];
  uuids?: string[];
  onSelect?: (id: string, item: LibraryCollectionItemDto) => void;
  onUpload?: (files: File[]) => void;
  onDelete?: (asset: Asset, mustConfirm?: boolean) => void;
  allowSearch?: boolean;
  searchTerm?: string;
  limit?: number;
  disposition?: "horizontal" | "masonry";
  hideIfEmpty?: boolean;
}

export function LibraryPickerList(props: Props) {
  const {
    libraryUuid,
    components,
    allowSearch,
    categoryId,
    disposition = "masonry",
    searchTerm = "",
    limit,
    hideIfEmpty = false
  } = props;
  const { library } = useBackend();
  const containerRef = useRef<HTMLDivElement>(null);
  const [items, setItems] = useState<LibraryCollectionItemDto[] | null>(null);
  const [columns, setColumns] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [scroller, setScroller] = useState<"left" | "right" | "both" | "none">("right");

  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const container = event.currentTarget;
    const isAtLeftEdge = container.scrollLeft === 0;
    const isAtRightEdge = container.scrollLeft + container.clientWidth === container.scrollWidth;

    setScroller(isAtLeftEdge ? "right" : isAtRightEdge ? "left" : "both");
  }, []);

  useEffect(() => {
    if (!allowSearch) {
      setSearch(searchTerm);
    }
  }, [allowSearch, searchTerm]);

  useEffect(() => {
    if (!items?.length) {
      return;
    }

    // listen width changes
    const observer = new ResizeObserver(() => {
      const width = containerRef.current?.clientWidth || 0;
      const newColumns = Math.floor(width / 120);
      setColumns(newColumns);
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [items?.length]);

  // Load library items
  useEffect(() => {
    const fetchItems = async () => {
      const response = await library.getList({
        path: "/:uuid/items",
        params: { uuid: libraryUuid },
        search: {
          component: components,
          category: categoryId ? [categoryId] : undefined,
          search,
          limit
        }
      });

      setItems(response.items);
    };

    fetchItems();
  }, [categoryId, components, library, libraryUuid, limit, search]);

  const content = useMemo(() => items?.map((item) => <LibraryItem key={item.uuid} item={item} tooltip />), [items]);

  if (!items) {
    return (
      <Box display='flex' alignContent='center' justifyContent='center' p={10}>
        <CircularProgress />
      </Box>
    );
  }

  if (items.length === 0) {
    if (hideIfEmpty) {
      return null;
    }

    return <Empty className='empty-library-picker-list' />;
  }

  return (
    <Box
      className='library-picker-list'
      display='flex'
      flexDirection='column'
      alignContent='stretch'
      alignItems='stretch'
      height='100%'
      ref={containerRef}
    >
      {allowSearch && <SearchBox onSearch={setSearch} persistingKey='library-search' />}
      {disposition === "masonry" && items.length > 0 ? <Masonry columnsCount={columns}>{content}</Masonry> : null}
      {disposition === "horizontal" && items.length > 0 ? (
        <Box position='relative'>
          <Scroller className={`left ${["left", "both"].includes(scroller) ? "enabled" : ""}`} />
          <HorizontalList onScroll={handleScroll}>{content}</HorizontalList>
          <Scroller className={`right ${["right", "both"].includes(scroller) ? "enabled" : ""}`} />
        </Box>
      ) : null}
      {items.length === 0 ? <Empty /> : null}
    </Box>
  );
}
