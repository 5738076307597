import { ApiRestClient } from "../ApiRest";
import {
  LibraryCollectionCategoryDto,
  LibraryCollectionDto,
  LibraryCollectionItemDto,
} from "../dtos";

type Routes = {
  "/": LibraryCollectionDto;
  "/:uuid": LibraryCollectionDto;
  "/:uuid/items": LibraryCollectionItemDto;
  "/:uuid/items/:itemuuid": LibraryCollectionItemDto;
  "/:uuid/categories": LibraryCollectionCategoryDto;
  "/:uuid/categories/:id": LibraryCollectionCategoryDto;
};

export class LibraryApiClient extends ApiRestClient<Routes> {
  constructor(baseUrl: string) {
    super(baseUrl.replace(/\/$/, "") + "/library");
  }
}
